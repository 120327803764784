const data = [
    0,
    0.00006,
    0.00027,
    0.00061,
    0.0011,
    0.00171,
    0.00247,
    0.00336,
    0.0044,
    0.00556,
    0.00687,
    0.00831,
    0.0099,
    0.01161,
    0.01347,
    0.01546,
    0.0176,
    0.01986,
    0.02227,
    0.02481,
    0.0275,
    0.03031,
    0.03327,
    0.03636,
    0.0396,
    0.04296,
    0.04647,
    0.05011,
    0.0539,
    0.05781,
    0.06187,
    0.06606,
    0.0704,
    0.07486,
    0.07947,
    0.08421,
    0.0891,
    0.09411,
    0.09927,
    0.10456,
    0.11,
    0.11556,
    0.12127,
    0.12711,
    0.1331,
    0.13921,
    0.14547,
    0.15186,
    0.1584,
    0.16506,
    0.17187,
    0.17881,
    0.1859,
    0.19311,
    0.20047,
    0.20796,
    0.2156,
    0.22336,
    0.23127,
    0.23931,
    0.2475,
    0.25581,
    0.26427,
    0.27286,
    0.2816,
    0.29046,
    0.29947,
    0.30861,
    0.3179,
    0.32731,
    0.33687,
    0.34656,
    0.3564,
    0.36636,
    0.37647,
    0.38671,
    0.3971,
    0.40761,
    0.41827,
    0.42906,
    0.44,
    0.45106,
    0.46227,
    0.47361,
    0.4851,
    0.49671,
    0.50847,
    0.52036,
    0.5324,
    0.54456,
    0.55687,
    0.56931,
    0.5819,
    0.59461,
    0.60747,
    0.62046,
    0.6336,
    0.64686,
    0.66027,
    0.67381,
    0.6875,
    0.70131,
    0.71527,
    0.72936,
    0.7436,
    0.75796,
    0.77247,
    0.78711,
    0.8019,
    0.81681,
    0.83187,
    0.84706,
    0.8624,
    0.87786,
    0.89347,
    0.90921,
    0.9251,
    0.94111,
    0.95727,
    0.97356,
    0.99,
    1.00656,
    1.02327,
    1.04011,
    1.0571,
    1.07421,
    1.09147,
    1.10886,
    1.1264,
    1.14406,
    1.16187,
    1.17981,
    1.1979,
    1.21611,
    1.23447,
    1.25296,
    1.2716,
    1.29036,
    1.30927,
    1.32831,
    1.3475,
    1.36681,
    1.38627,
    1.40586,
    1.4256,
    1.44546,
    1.46547,
    1.48561,
    1.5059,
    1.52631,
    1.54687,
    1.56756,
    1.5884,
    1.60936,
    1.63047,
    1.65171,
    1.6731,
    1.69461,
    1.71627,
    1.73806,
    1.76,
    1.78206,
    1.80427,
    1.82661,
    1.8491,
    1.87171,
    1.89447,
    1.91736,
    1.9404,
    1.96356,
    1.98687,
    2.01031,
    2.0339,
    2.05761,
    2.08147,
    2.10546,
    2.1296,
    2.15386,
    2.17827,
    2.20281,
    2.2275,
    2.25231,
    2.27727,
    2.30236,
    2.3276,
    2.35296,
    2.37847,
    2.40411,
    2.4299,
    2.45581,
    2.48187,
    2.50806,
    2.5344,
    2.56086,
    2.58747,
    2.61421,
    2.6411,
    2.66811,
    2.69527,
    2.72256,
    2.75,
    2.77756,
    2.80527,
    2.83311,
    2.8611,
    2.88921,
    2.91747,
    2.94586,
    2.9744,
    3.00306,
    3.03187,
    3.06081,
    3.0899,
    3.11911,
    3.14847,
    3.17796,
    3.2076,
    3.23736,
    3.26727,
    3.29731,
    3.3275,
    3.35781,
    3.38827,
    3.41886,
    3.4496,
    3.48046,
    3.51147,
    3.54261,
    3.5739,
    3.60531,
    3.63687,
    3.66856,
    3.7004,
    3.73236,
    3.76447,
    3.79671,
    3.8291,
    3.86161,
    3.89427,
    3.92706,
    3.96,
    3.99306,
    4.02627,
    4.05961,
    4.0931,
    4.12671,
    4.16047,
    4.19436,
    4.2284,
    4.26256,
    4.29687,
    4.33131,
    4.3659,
    4.40061,
    4.43547,
    4.47046,
    4.5056,
    4.54086,
    4.57627,
    4.61181,
    4.6475,
    4.68331,
    4.71927,
    4.75536,
    4.7916,
    4.82796,
    4.86447,
    4.90111,
    4.9379,
    4.97481,
    5.01187,
    5.04906,
    5.0864,
    5.12386,
    5.16147,
    5.19921,
    5.2371,
    5.27511,
    5.31327,
    5.35156,
    5.39,
    5.42856,
    5.46727,
    5.50611,
    5.5451,
    5.58421,
    5.62347,
    5.66286,
    5.7024,
    5.74206,
    5.78187,
    5.82181,
    5.8619,
    5.90211,
    5.94247,
    5.98296,
    6.0236,
    6.06436,
    6.10527,
    6.14631,
    6.1875,
    6.22881,
    6.27027,
    6.31186,
    6.3536,
    6.39546,
    6.43747,
    6.47961,
    6.5219,
    6.56431,
    6.60687,
    6.64956,
    6.6924,
    6.73536,
    6.77847,
    6.82171,
    6.8651,
    6.90861,
    6.95227,
    6.99606,
    7.04,
    7.08406,
    7.12827,
    7.17261,
    7.2171,
    7.26171,
    7.30647,
    7.35136,
    7.3964,
    7.44156,
    7.48687,
    7.53231,
    7.5779,
    7.62361,
    7.66947,
    7.71546,
    7.7616,
    7.80786,
    7.85427,
    7.90081,
    7.9475,
    7.99431,
    8.04127,
    8.08836,
    8.1356,
    8.18296,
    8.23047,
    8.27811,
    8.3259,
    8.37381,
    8.42187,
    8.47006,
    8.5184,
    8.56686,
    8.61547,
    8.66421,
    8.7131,
    8.76211,
    8.81127,
    8.86056,
    8.91,
    8.95956,
    9.00927,
    9.05911,
    9.1091,
    9.15921,
    9.20947,
    9.25986,
    9.3104,
    9.36106,
    9.41187,
    9.46281,
    9.5139,
    9.56511,
    9.61647,
    9.66796,
    9.7196,
    9.77136,
    9.82327,
    9.87531,
    9.9275,
    9.97981,
    10.03227,
    10.08486,
    10.1376,
    10.19046,
    10.24347,
    10.29661,
    10.3499,
    10.40331,
    10.45687,
    10.51056,
    10.5644,
    10.61836,
    10.67247,
    10.72671,
    10.7811,
    10.83561,
    10.89027,
    10.94506,
    11,
    11.05506,
    11.11027,
    11.16561,
    11.2211,
    11.27671,
    11.33247,
    11.38836,
    11.4444,
    11.50056,
    11.55687,
    11.61331,
    11.6699,
    11.72661,
    11.78347,
    11.84046,
    11.8976,
    11.95486,
    12.01227,
    12.06981,
    12.1275,
    12.18531,
    12.24327,
    12.30136,
    12.3596,
    12.41796,
    12.47647,
    12.53511,
    12.5939,
    12.65281,
    12.71187,
    12.77106,
    12.8304,
    12.88986,
    12.94947,
    13.00921,
    13.0691,
    13.12911,
    13.18927,
    13.24956,
    13.31,
    13.37056,
    13.43127,
    13.49211,
    13.5531,
    13.61421,
    13.67547,
    13.73686,
    13.7984,
    13.86006,
    13.92187,
    13.98381,
    14.0459,
    14.10811,
    14.17047,
    14.23296,
    14.2956,
    14.35836,
    14.42127,
    14.48431,
    14.5475,
    14.61081,
    14.67427,
    14.73786,
    14.8016,
    14.86546,
    14.92947,
    14.99361,
    15.0579,
    15.12231,
    15.18687,
    15.25156,
    15.3164,
    15.38136,
    15.44647,
    15.51171,
    15.5771,
    15.64261,
    15.70827,
    15.77406,
    15.84,
    15.90606,
    15.97227,
    16.03861,
    16.1051,
    16.17171,
    16.23847,
    16.30536,
    16.3724,
    16.43956,
    16.50687,
    16.57431,
    16.6419,
    16.70961,
    16.77747,
    16.84546,
    16.9136,
    16.98186,
    17.05027,
    17.11881,
    17.1875,
    17.25631,
    17.32527,
    17.39436,
    17.4636,
    17.53296,
    17.60247,
    17.67211,
    17.7419,
    17.81181,
    17.88187,
    17.95206,
    18.0224,
    18.09286,
    18.16347,
    18.23421,
    18.3051,
    18.37611,
    18.44727,
    18.51856,
    18.59,
    18.66156,
    18.73327,
    18.80511,
    18.8771,
    18.94921,
    19.02147,
    19.09386,
    19.1664,
    19.23906,
    19.31187,
    19.38481,
    19.4579,
    19.53111,
    19.60447,
    19.67796,
    19.7516,
    19.82536,
    19.89927,
    19.97331,
    20.0475,
    20.12181,
    20.19627,
    20.27086,
    20.3456,
    20.42046,
    20.49547,
    20.57061,
    20.6459,
    20.72131,
    20.79687,
    20.87256,
    20.9484,
    21.02436,
    21.10047,
    21.17671,
    21.2531,
    21.32961,
    21.40627,
    21.48306,
    21.56,
    21.63706,
    21.71427,
    21.79161,
    21.8691,
    21.94671,
    22.02447,
    22.10236,
    22.1804,
    22.25856,
    22.33687,
    22.41531,
    22.4939,
    22.57261,
    22.65147,
    22.73046,
    22.8096,
    22.88886,
    22.96827,
    23.04781,
    23.1275,
    23.20731,
    23.28727,
    23.36736,
    23.4476,
    23.52796,
    23.60847,
    23.68911,
    23.7699,
    23.85081,
    23.93187,
    24.01306,
    24.0944,
    24.17586,
    24.25747,
    24.33921,
    24.4211,
    24.50311,
    24.58527,
    24.66756,
    24.75,
    24.83256,
    24.91527,
    24.99811,
    25.0811,
    25.16421,
    25.24747,
    25.33086,
    25.4144,
    25.49806,
    25.58187,
    25.66581,
    25.7499,
    25.83411,
    25.91847,
    26.00296,
    26.0876,
    26.17236,
    26.25727,
    26.34231,
    26.4275,
    26.51281,
    26.59827,
    26.68386,
    26.7696,
    26.85546,
    26.94147,
    27.02761,
    27.1139,
    27.20031,
    27.28687,
    27.37356,
    27.4604,
    27.54736,
    27.63447,
    27.72171,
    27.8091,
    27.89661,
    27.98427,
    28.07206,
    28.16,
    28.24806,
    28.33627,
    28.42461,
    28.5131,
    28.60171,
    28.69047,
    28.77936,
    28.8684,
    28.95756,
    29.04687,
    29.13631,
    29.2259,
    29.31561,
    29.40547,
    29.49546,
    29.5856,
    29.67586,
    29.76627,
    29.85681,
    29.9475,
    30.03831,
    30.12927,
    30.22036,
    30.3116,
    30.40296,
    30.49447,
    30.58611,
    30.6779,
    30.76981,
    30.86187,
    30.95406,
    31.0464,
    31.13886,
    31.23147,
    31.32421,
    31.4171,
    31.51011,
    31.60327,
    31.69656,
    31.79,
    31.88356,
    31.97727,
    32.07111,
    32.1651,
    32.25921,
    32.35347,
    32.44786,
    32.5424,
    32.63706,
    32.73187,
    32.82681,
    32.9219,
    33.01711,
    33.11247,
    33.20796,
    33.3036,
    33.39936,
    33.49527,
    33.59131,
    33.6875,
    33.78381,
    33.88027,
    33.97686,
    34.0736,
    34.17046,
    34.26747,
    34.36461,
    34.4619,
    34.55931,
    34.65687,
    34.75456,
    34.8524,
    34.95036,
    35.04847,
    35.14671,
    35.2451,
    35.34361,
    35.44227,
    35.54106,
    35.64,
    35.73906,
    35.83827,
    35.93761,
    36.0371,
    36.13671,
    36.23647,
    36.33636,
    36.4364,
    36.53656,
    36.63687,
    36.73731,
    36.8379,
    36.93861,
    37.03947,
    37.14046,
    37.2416,
    37.34286,
    37.44427,
    37.54581,
    37.6475,
    37.74931,
    37.85127,
    37.95336,
    38.0556,
    38.15796,
    38.26047,
    38.36311,
    38.4659,
    38.56881,
    38.67187,
    38.77506,
    38.8784,
    38.98186,
    39.08547,
    39.18921,
    39.2931,
    39.39711,
    39.50127,
    39.60556,
    39.71,
    39.81456,
    39.91927,
    40.02411,
    40.1291,
    40.23421,
    40.33947,
    40.44486,
    40.5504,
    40.65606,
    40.76187,
    40.86781,
    40.9739,
    41.08011,
    41.18647,
    41.29296,
    41.3996,
    41.50636,
    41.61327,
    41.72031,
    41.8275,
    41.93481,
    42.04227,
    42.14986,
    42.2576,
    42.36546,
    42.47347,
    42.58161,
    42.6899,
    42.79831,
    42.90687,
    43.01556,
    43.1244,
    43.23336,
    43.34247,
    43.45171,
    43.5611,
    43.67061,
    43.78027,
    43.89006,
    44,
    44.11006,
    44.22027,
    44.33061,
    44.4411,
    44.55171,
    44.66247,
    44.77336,
    44.8844,
    44.99556,
    45.10687,
    45.21831,
    45.3299,
    45.44161,
    45.55347,
    45.66546,
    45.7776,
    45.88986,
    46.00227,
    46.11481,
    46.2275,
    46.34031,
    46.45327,
    46.56636,
    46.6796,
    46.79296,
    46.90647,
    47.02011,
    47.1339,
    47.24781,
    47.36187,
    47.47606,
    47.5904,
    47.70486,
    47.81947,
    47.93421,
    48.0491,
    48.16411,
    48.27927,
    48.39456,
    48.51,
    48.62556,
    48.74127,
    48.85711,
    48.9731,
    49.08921,
    49.20547,
    49.32186,
    49.4384,
    49.55506,
    49.67187,
    49.78881,
    49.9059,
    50.02311,
    50.14047,
    50.25796,
    50.3756,
    50.49336,
    50.61127,
    50.72931,
    50.8475,
    50.96581,
    51.08427,
    51.20286,
    51.3216,
    51.44046,
    51.55947,
    51.67861,
    51.7979,
    51.91731,
    52.03687,
    52.15656,
    52.2764,
    52.39636,
    52.51647,
    52.63671,
    52.7571,
    52.87761,
    52.99827,
    53.11906,
    53.24,
    53.36106,
    53.48227,
    53.60361,
    53.7251,
    53.84671,
    53.96847,
    54.09036,
    54.2124,
    54.33456,
    54.45687,
    54.57931,
    54.7019,
    54.82461,
    54.94747,
    55.07046,
    55.1936,
    55.31686,
    55.44027,
    55.56381,
    55.6875,
    55.81131,
    55.93527,
    56.05936,
    56.1836,
    56.30796,
    56.43247,
    56.55711,
    56.6819,
    56.80681,
    56.93187,
    57.05706,
    57.1824,
    57.30786,
    57.43347,
    57.55921,
    57.6851,
    57.81111,
    57.93727,
    58.06356,
    58.19,
    58.31656,
    58.44327,
    58.57011,
    58.6971,
    58.82421,
    58.95147,
    59.07886,
    59.2064,
    59.33406,
    59.46187,
    59.58981,
    59.7179,
    59.84611,
    59.97447,
    60.10296,
    60.2316,
    60.36036,
    60.48927,
    60.61831,
    60.7475,
    60.87681,
    61.00627,
    61.13586,
    61.2656,
    61.39546,
    61.52547,
    61.65561,
    61.7859,
    61.91631,
    62.04687,
    62.17756,
    62.3084,
    62.43936,
    62.57047,
    62.70171,
    62.8331,
    62.96461,
    63.09627,
    63.22806,
    63.36,
    63.49206,
    63.62427,
    63.75661,
    63.8891,
    64.02171,
    64.15447,
    64.28736,
    64.4204,
    64.55356,
    64.68687,
    64.82031,
    64.9539,
    65.08761,
    65.22147,
    65.35546,
    65.4896,
    65.62386,
    65.75827,
    65.89281,
    66.0275,
    66.16231,
    66.29727,
    66.43236,
    66.5676,
    66.70296,
    66.83847,
    66.97411,
    67.1099,
    67.24581,
    67.38187,
    67.51806,
    67.6544,
    67.79086,
    67.92747,
    68.06421,
    68.2011,
    68.33811,
    68.47527,
    68.61256,
    68.75,
    68.88756,
    69.02527,
    69.16311,
    69.3011,
    69.43921,
    69.57747,
    69.71586,
    69.8544,
    69.99306,
    70.13187,
    70.27081,
    70.4099,
    70.54911,
    70.68847,
    70.82796,
    70.9676,
    71.10736,
    71.24727,
    71.38731,
    71.5275,
    71.66781,
    71.80827,
    71.94886,
    72.0896,
    72.23046,
    72.37147,
    72.51261,
    72.6539,
    72.79531,
    72.93687,
    73.07856,
    73.2204,
    73.36236,
    73.50447,
    73.64671,
    73.7891,
    73.93161,
    74.07427,
    74.21706,
    74.36,
    74.50306,
    74.64627,
    74.78961,
    74.9331,
    75.07671,
    75.22047,
    75.36436,
    75.5084,
    75.65256,
    75.79687,
    75.94131,
    76.0859,
    76.23061,
    76.37547,
    76.52046,
    76.6656,
    76.81086,
    76.95627,
    77.10181,
    77.2475,
    77.39331,
    77.53927,
    77.68536,
    77.8316,
    77.97796,
    78.12447,
    78.27111,
    78.4179,
    78.56481,
    78.71187,
    78.85906,
    79.0064,
    79.15386,
    79.30147,
    79.44921,
    79.5971,
    79.74511,
    79.89327,
    80.04156,
    80.19,
    80.33856,
    80.48727,
    80.63611,
    80.7851,
    80.93421,
    81.08347,
    81.23286,
    81.3824,
    81.53206,
    81.68187,
    81.83181,
    81.9819,
    82.13211,
    82.28247,
    82.43296,
    82.5836,
    82.73436,
    82.88527,
    83.03631,
    83.1875,
    83.33881,
    83.49027,
    83.64186,
    83.7936,
    83.94546,
    84.09747,
    84.24961,
    84.4019,
    84.55431,
    84.70687,
    84.85956,
    85.0124,
    85.16536,
    85.31847,
    85.47171,
    85.6251,
    85.77861,
    85.93227,
    86.08606,
    86.24,
    86.39406,
    86.54827,
    86.70261,
    86.8571,
    87.01171,
    87.16647,
    87.32136,
    87.4764,
    87.63156,
    87.78687,
    87.94231,
    88.0979,
    88.25361,
    88.40947,
    88.56546,
    88.7216,
    88.87786,
    89.03427,
    89.19081,
    89.3475,
    89.50431,
    89.66127,
    89.81836,
    89.9756,
    90.13296,
    90.29047,
    90.44811,
    90.6059,
    90.76381,
    90.92187,
    91.08006,
    91.2384,
    91.39686,
    91.55547,
    91.71421,
    91.8731,
    92.03211,
    92.19127,
    92.35056,
    92.51,
    92.66956,
    92.82927,
    92.98911,
    93.1491,
    93.30921,
    93.46947,
    93.62986,
    93.7904,
    93.95106,
    94.11187,
    94.27281,
    94.4339,
    94.59511,
    94.75647,
    94.91796,
    95.0796,
    95.24136,
    95.40327,
    95.56531,
    95.7275,
    95.88981,
    96.05227,
    96.21486,
    96.3776,
    96.54046,
    96.70347,
    96.86661,
    97.0299,
    97.19331,
    97.35687,
    97.52056,
    97.6844,
    97.84836,
    98.01247,
    98.17671,
    98.3411,
    98.50561,
    98.67027,
    98.83506,
    99,
    99.16506,
    99.33027,
    99.49561,
    99.6611,
    99.82671,
    99.99247,
    100.15836,
    100.3244,
    100.49056,
    100.65687,
    100.82331,
    100.9899,
    101.15661,
    101.32347,
    101.49046,
    101.6576,
    101.82486,
    101.99227,
    102.15981,
    102.3275,
    102.49531,
    102.66327,
    102.83136,
    102.9996,
    103.16796,
    103.33647,
    103.50511,
    103.6739,
    103.84281,
    104.01187,
    104.18106,
    104.3504,
    104.51986,
    104.68947,
    104.85921,
    105.0291,
    105.19911,
    105.36927,
    105.53956,
    105.71,
    105.88056,
    106.05127,
    106.22211,
    106.3931,
    106.56421,
    106.73547,
    106.90686,
    107.0784,
    107.25006,
    107.42187,
    107.59381,
    107.7659,
    107.93811,
    108.11047,
    108.28296,
    108.4556,
    108.62836,
    108.80127,
    108.97431,
    109.1475,
    109.32081,
    109.49427,
    109.66786,
    109.8416,
    110.01546,
    110.18947,
    110.36361,
    110.5379,
    110.71231,
    110.88687,
    111.06156,
    111.2364,
    111.41136,
    111.58647,
    111.76171,
    111.9371,
    112.11261,
    112.28827,
    112.46406,
    112.64,
    112.81606,
    112.99227,
    113.16861,
    113.3451,
    113.52171,
    113.69847,
    113.87536,
    114.0524,
    114.22956,
    114.40687,
    114.58431,
    114.7619,
    114.93961,
    115.11747,
    115.29546,
    115.4736,
    115.65186,
    115.83027,
    116.00881,
    116.1875,
    116.36631,
    116.54527,
    116.72436,
    116.9036,
    117.08296,
    117.26247,
    117.44211,
    117.6219,
    117.80181,
    117.98187,
    118.16206,
    118.3424,
    118.52286,
    118.70347,
    118.88421,
    119.0651,
    119.24611,
    119.42727,
    119.60856,
    119.79,
    119.97156,
    120.15327,
    120.33511,
    120.5171,
    120.69921,
    120.88147,
    121.06386,
    121.2464,
    121.42906,
    121.61187,
    121.79481,
    121.9779,
    122.16111,
    122.34447,
    122.52796,
    122.7116,
    122.89536,
    123.07927,
    123.26331,
    123.4475,
    123.63181,
    123.81627,
    124.00086,
    124.1856,
    124.37046,
    124.55547,
    124.74061,
    124.9259,
    125.11131,
    125.29687,
    125.48256,
    125.6684,
    125.85436,
    126.04047,
    126.22671,
    126.4131,
    126.59961,
    126.78627,
    126.97306,
    127.16,
    127.34706,
    127.53427,
    127.72161,
    127.9091,
    128.09671,
    128.28447,
    128.47236,
    128.6604,
    128.84856,
    129.03687,
    129.22531,
    129.4139,
    129.60261,
    129.79147,
    129.98046,
    130.1696,
    130.35886,
    130.54827,
    130.73781,
    130.9275,
    131.11731,
    131.30727,
    131.49736,
    131.6876,
    131.87796,
    132.06847,
    132.25911,
    132.4499,
    132.64081,
    132.83187,
    133.02306,
    133.2144,
    133.40586,
    133.59747,
    133.78921,
    133.9811,
    134.17311,
    134.36527,
    134.55756,
    134.75,
    134.94256,
    135.13527,
    135.32811,
    135.5211,
    135.71421,
    135.90747,
    136.10086,
    136.2944,
    136.48806,
    136.68187,
    136.87581,
    137.0699,
    137.26411,
    137.45847,
    137.65296,
    137.8476,
    138.04236,
    138.23727,
    138.43231,
    138.6275,
    138.82281,
    139.01827,
    139.21386,
    139.4096,
    139.60546,
    139.80147,
    139.99761,
    140.1939,
    140.39031,
    140.58687,
    140.78356,
    140.9804,
    141.17736,
    141.37447,
    141.57171,
    141.7691,
    141.96661,
    142.16427,
    142.36206,
    142.56,
    142.75806,
    142.95627,
    143.15461,
    143.3531,
    143.55171,
    143.75047,
    143.94936,
    144.1484,
    144.34756,
    144.54687,
    144.74631,
    144.9459,
    145.14561,
    145.34547,
    145.54546,
    145.7456,
    145.94586,
    146.14627,
    146.34681,
    146.5475,
    146.74831,
    146.94927,
    147.15036,
    147.3516,
    147.55296,
    147.75447,
    147.95611,
    148.1579,
    148.35981,
    148.56187,
    148.76406,
    148.9664,
    149.16886,
    149.37147,
    149.57421,
    149.7771,
    149.98011,
    150.18327,
    150.38656,
    150.59,
    150.79356,
    150.99727,
    151.20111,
    151.4051,
    151.60921,
    151.81347,
    152.01786,
    152.2224,
    152.42706,
    152.63187,
    152.83681,
    153.0419,
    153.24711,
    153.45247,
    153.65796,
    153.8636,
    154.06936,
    154.27527,
    154.48131,
    154.6875,
    154.89381,
    155.10027,
    155.30686,
    155.5136,
    155.72046,
    155.92747,
    156.13461,
    156.3419,
    156.54931,
    156.75687,
    156.96456,
    157.1724,
    157.38036,
    157.58847,
    157.79671,
    158.0051,
    158.21361,
    158.42227,
    158.63106,
    158.84,
    159.04906,
    159.25827,
    159.46761,
    159.6771,
    159.88671,
    160.09647,
    160.30636,
    160.5164,
    160.72656,
    160.93687,
    161.14731,
    161.3579,
    161.56861,
    161.77947,
    161.99046,
    162.2016,
    162.41286,
    162.62427,
    162.83581,
    163.0475,
    163.25931,
    163.47127,
    163.68336,
    163.8956,
    164.10796,
    164.32047,
    164.53311,
    164.7459,
    164.95881,
    165.17187,
    165.38506,
    165.5984,
    165.81186,
    166.02547,
    166.23921,
    166.4531,
    166.66711,
    166.88127,
    167.09556,
    167.31,
    167.52456,
    167.73927,
    167.95411,
    168.1691,
    168.38421,
    168.59947,
    168.81486,
    169.0304,
    169.24606,
    169.46187,
    169.67781,
    169.8939,
    170.11011,
    170.32647,
    170.54296,
    170.7596,
    170.97636,
    171.19327,
    171.41031,
    171.6275,
    171.84481,
    172.06227,
    172.27986,
    172.4976,
    172.71546,
    172.93347,
    173.15161,
    173.3699,
    173.58831,
    173.80687,
    174.02556,
    174.2444,
    174.46336,
    174.68247,
    174.90171,
    175.1211,
    175.34061,
    175.56027,
    175.78006,
    176,
    176.22006,
    176.44027,
    176.66061,
    176.8811,
    177.10171,
    177.32247,
    177.54336,
    177.7644,
    177.98556,
    178.20687,
    178.42831,
    178.6499,
    178.87161,
    179.09347,
    179.31546,
    179.5376,
    179.75986,
    179.98227,
    180.20481,
    180.4275,
    180.65031,
    180.87327,
    181.09636,
    181.3196,
    181.54296,
    181.76647,
    181.99011,
    182.2139,
    182.43781,
    182.66187,
    182.88606,
    183.1104,
    183.33486,
    183.55947,
    183.78421,
    184.0091,
    184.23411,
    184.45927,
    184.68456,
    184.91,
    185.13556,
    185.36127,
    185.58711,
    185.8131,
    186.03921,
    186.26547,
    186.49186,
    186.7184,
    186.94506,
    187.17187,
    187.39881,
    187.6259,
    187.85311,
    188.08047,
    188.30796,
    188.5356,
    188.76336,
    188.99127,
    189.21931,
    189.4475,
    189.67581,
    189.90427,
    190.13286,
    190.3616,
    190.59046,
    190.81947,
    191.04861,
    191.2779,
    191.50731,
    191.73687,
    191.96656,
    192.1964,
    192.42636,
    192.65647,
    192.88671,
    193.1171,
    193.34761,
    193.57827,
    193.80906,
    194.04,
    194.27106,
    194.50227,
    194.73361,
    194.9651,
    195.19671,
    195.42847,
    195.66036,
    195.8924,
    196.12456,
    196.35687,
    196.58931,
    196.8219,
    197.05461,
    197.28747,
    197.52046,
    197.7536,
    197.98686,
    198.22027,
    198.45381,
    198.6875,
    198.92131,
    199.15527,
    199.38936,
    199.6236,
    199.85796,
    200.09247,
    200.32711,
    200.5619,
    200.79681,
    201.03187,
    201.26706,
    201.5024,
    201.73786,
    201.97347,
    202.20921,
    202.4451,
    202.68111,
    202.91727,
    203.15356,
    203.39,
    203.62656,
    203.86327,
    204.10011,
    204.3371,
    204.57421,
    204.81147,
    205.04886,
    205.2864,
    205.52406,
    205.76187,
    205.99981,
    206.2379,
    206.47611,
    206.71447,
    206.95296,
    207.1916,
    207.43036,
    207.66927,
    207.90831,
    208.1475,
    208.38681,
    208.62627,
    208.86586,
    209.1056,
    209.34546,
    209.58547,
    209.82561,
    210.0659,
    210.30631,
    210.54687,
    210.78756,
    211.0284,
    211.26936,
    211.51047,
    211.75171,
    211.9931,
    212.23461,
    212.47627,
    212.71806,
    212.96,
    213.20206,
    213.44427,
    213.68661,
    213.9291,
    214.17171,
    214.41447,
    214.65736,
    214.9004,
    215.14356,
    215.38687,
    215.63031,
    215.8739,
    216.11761,
    216.36147,
    216.60546,
    216.8496,
    217.09386,
    217.33827,
    217.58281,
    217.8275,
    218.07231,
    218.31727,
    218.56236,
    218.8076,
    219.05296,
    219.29847,
    219.54411,
    219.7899,
    220.03581,
    220.28187,
    220.52806,
    220.7744,
    221.02086,
    221.26747,
    221.51421,
    221.7611,
    222.00811,
    222.25527,
    222.50256,
    222.75,
    222.99756,
    223.24527,
    223.49311,
    223.7411,
    223.98921,
    224.23747,
    224.48586,
    224.7344,
    224.98306,
    225.23187,
    225.48081,
    225.7299,
    225.97911,
    226.22847,
    226.47796,
    226.7276,
    226.97736,
    227.22727,
    227.47731,
    227.7275,
    227.97781,
    228.22827,
    228.47886,
    228.7296,
    228.98046,
    229.23147,
    229.48261,
    229.7339,
    229.98531,
    230.23687,
    230.48856,
    230.7404,
    230.99236,
    231.24447,
    231.49671,
    231.7491,
    232.00161,
    232.25427,
    232.50706,
    232.76,
    233.01306,
    233.26627,
    233.51961,
    233.7731,
    234.02671,
    234.28047,
    234.53436,
    234.7884,
    235.04256,
    235.29687,
    235.55131,
    235.8059,
    236.06061,
    236.31547,
    236.57046,
    236.8256,
    237.08086,
    237.33627,
    237.59181,
    237.8475,
    238.10331,
    238.35927,
    238.61536,
    238.8716,
    239.12796,
    239.38447,
    239.64111,
    239.8979,
    240.15481,
    240.41187,
    240.66906,
    240.9264,
    241.18386,
    241.44147,
    241.69921,
    241.9571,
    242.21511,
    242.47327,
    242.73156,
    242.99,
    243.24856,
    243.50727,
    243.76611,
    244.0251,
    244.28421,
    244.54347,
    244.80286,
    245.0624,
    245.32206,
    245.58187,
    245.84181,
    246.1019,
    246.36211,
    246.62247,
    246.88296,
    247.1436,
    247.40436,
    247.66527,
    247.92631,
    248.1875,
    248.44881,
    248.71027,
    248.97186,
    249.2336,
    249.49546,
    249.75747,
    250.01961,
    250.2819,
    250.54431,
    250.80687,
    251.06956,
    251.3324,
    251.59536,
    251.85847,
    252.12171,
    252.3851,
    252.64861,
    252.91227,
    253.17606,
    253.44,
    253.70406,
    253.96827,
    254.23261,
    254.4971,
    254.76171,
    255.02647,
    255.29136,
    255.5564,
    255.82156,
    256.08687,
    256.35231,
    256.6179,
    256.88361,
    257.14947,
    257.41546,
    257.6816,
    257.94786,
    258.21427,
    258.48081,
    258.7475,
    259.01431,
    259.28127,
    259.54836,
    259.8156,
    260.08296,
    260.35047,
    260.61811,
    260.8859,
    261.15381,
    261.42187,
    261.69006,
    261.9584,
    262.22686,
    262.49547,
    262.76421,
    263.0331,
    263.30211,
    263.57127,
    263.84056,
    264.11,
    264.37956,
    264.64927,
    264.91911,
    265.1891,
    265.45921,
    265.72947,
    265.99986,
    266.2704,
    266.54106,
    266.81187,
    267.08281,
    267.3539,
    267.62511,
    267.89647,
    268.16796,
    268.4396,
    268.71136,
    268.98327,
    269.25531,
    269.5275,
    269.79981,
    270.07227,
    270.34486,
    270.6176,
    270.89046,
    271.16347,
    271.43661,
    271.7099,
    271.98331,
    272.25687,
    272.53056,
    272.8044,
    273.07836,
    273.35247,
    273.62671,
    273.9011,
    274.17561,
    274.45027,
    274.72506,
    275,
    275.27506,
    275.55027,
    275.82561,
    276.1011,
    276.37671,
    276.65247,
    276.92836,
    277.2044,
    277.48056,
    277.75687,
    278.03331,
    278.3099,
    278.58661,
    278.86347,
    279.14046,
    279.4176,
    279.69486,
    279.97227,
    280.24981,
    280.5275,
    280.80531,
    281.08327,
    281.36136,
    281.6396,
    281.91796,
    282.19647,
    282.47511,
    282.7539,
    283.03281,
    283.31187,
    283.59106,
    283.8704,
    284.14986,
    284.42947,
    284.70921,
    284.9891,
    285.26911,
    285.54927,
    285.82956,
    286.11,
    286.39056,
    286.67127,
    286.95211,
    287.2331,
    287.51421,
    287.79547,
    288.07686,
    288.3584,
    288.64006,
    288.92187,
    289.20381,
    289.4859,
    289.76811,
    290.05047,
    290.33296,
    290.6156,
    290.89836,
    291.18127,
    291.46431,
    291.7475,
    292.03081,
    292.31427,
    292.59786,
    292.8816,
    293.16546,
    293.44947,
    293.73361,
    294.0179,
    294.30231,
    294.58687,
    294.87156,
    295.1564,
    295.44136,
    295.72647,
    296.01171,
    296.2971,
    296.58261,
    296.86827,
    297.15406,
    297.44,
    297.72606,
    298.01227,
    298.29861,
    298.5851,
    298.87171,
    299.15847,
    299.44536,
    299.7324,
    300.01956,
    300.30687,
    300.59431,
    300.8819,
    301.16961,
    301.45747,
    301.74546,
    302.0336,
    302.32186,
    302.61027,
    302.89881,
    303.1875,
    303.47631,
    303.76527,
    304.05436,
    304.3436,
    304.63296,
    304.92247,
    305.21211,
    305.5019,
    305.79181,
    306.08187,
    306.37206,
    306.6624,
    306.95286,
    307.24347,
    307.53421,
    307.8251,
    308.11611,
    308.40727,
    308.69856,
    308.99,
    309.28156,
    309.57327,
    309.86511,
    310.1571,
    310.44921,
    310.74147,
    311.03386,
    311.3264,
    311.61906,
    311.91187,
    312.20481,
    312.4979,
    312.79111,
    313.08447,
    313.37796,
    313.6716,
    313.96536,
    314.25927,
    314.55331,
    314.8475,
    315.14181,
    315.43627,
    315.73086,
    316.0256,
    316.32046,
    316.61547,
    316.91061,
    317.2059,
    317.50131,
    317.79687,
    318.09256,
    318.3884,
    318.68436,
    318.98047,
    319.27671,
    319.5731,
    319.86961,
    320.16627,
    320.46306,
    320.76,
    321.05706,
    321.35427,
    321.65161,
    321.9491,
    322.24671,
    322.54447,
    322.84236,
    323.1404,
    323.43856,
    323.73687,
    324.03531,
    324.3339,
    324.63261,
    324.93147,
    325.23046,
    325.5296,
    325.82886,
    326.12827,
    326.42781,
    326.7275,
    327.02731,
    327.32727,
    327.62736,
    327.9276,
    328.22796,
    328.52847,
    328.82911,
    329.1299,
    329.43081,
    329.73187,
    330.03306,
    330.3344,
    330.63586,
    330.93747,
    331.23921,
    331.5411,
    331.84311,
    332.14527,
    332.44756,
    332.75,
    333.05256,
    333.35527,
    333.65811,
    333.9611,
    334.26421,
    334.56747,
    334.87086,
    335.1744,
    335.47806,
    335.78187,
    336.08581,
    336.3899,
    336.69411,
    336.99847,
    337.30296,
    337.6076,
    337.91236,
    338.21727,
    338.52231,
    338.8275,
    339.13281,
    339.43827,
    339.74386,
    340.0496,
    340.35546,
    340.66147,
    340.96761,
    341.2739,
    341.58031,
    341.88687,
    342.19356,
    342.5004,
    342.80736,
    343.11447,
    343.42171,
    343.7291,
    344.03661,
    344.34427,
    344.65206,
    344.96,
    345.26806,
    345.57627,
    345.88461,
    346.1931,
    346.50171,
    346.81047,
    347.11936,
    347.4284,
    347.73756,
    348.04687,
    348.35631,
    348.6659,
    348.97561,
    349.28547,
    349.59546,
    349.9056,
    350.21586,
    350.52627,
    350.83681,
    351.1475,
    351.45831,
    351.76927,
    352.08036,
    352.3916,
    352.70296,
    353.01447,
    353.32611,
    353.6379,
    353.94981,
    354.26187,
    354.57406,
    354.8864,
    355.19886,
    355.51147,
    355.82421,
    356.1371,
    356.45011,
    356.76327,
    357.07656,
    357.39,
    357.70356,
    358.01727,
    358.33111,
    358.6451,
    358.95921,
    359.27347,
    359.58786,
    359.9024,
    360.21706,
    360.53187,
    360.84681,
    361.1619,
    361.47711,
    361.79247,
    362.10796,
    362.4236,
    362.73936,
    363.05527,
    363.37131,
    363.6875,
    364.00381,
    364.32027,
    364.63686,
    364.9536,
    365.27046,
    365.58747,
    365.90461,
    366.2219,
    366.53931,
    366.85687,
    367.17456,
    367.4924,
    367.81036,
    368.12847,
    368.44671,
    368.7651,
    369.08361,
    369.40227,
    369.72106,
    370.04,
    370.35906,
    370.67827,
    370.99761,
    371.3171,
    371.63671,
    371.95647,
    372.27636,
    372.5964,
    372.91656,
    373.23687,
    373.55731,
    373.8779,
    374.19861,
    374.51947,
    374.84046,
    375.1616,
    375.48286,
    375.80427,
    376.12581,
    376.4475,
    376.76931,
    377.09127,
    377.41336,
    377.7356,
    378.05796,
    378.38047,
    378.70311,
    379.0259,
    379.34881,
    379.67187,
    379.99506,
    380.3184,
    380.64186,
    380.96547,
    381.28921,
    381.6131,
    381.93711,
    382.26127,
    382.58556,
    382.91,
    383.23456,
    383.55927,
    383.88411,
    384.2091,
    384.53421,
    384.85947,
    385.18486,
    385.5104,
    385.83606,
    386.16187,
    386.48781,
    386.8139,
    387.14011,
    387.46647,
    387.79296,
    388.1196,
    388.44636,
    388.77327,
    389.10031,
    389.4275,
    389.75481,
    390.08227,
    390.40986,
    390.7376,
    391.06546,
    391.39347,
    391.72161,
    392.0499,
    392.37831,
    392.70687,
    393.03556,
    393.3644,
    393.69336,
    394.02247,
    394.35171,
    394.6811,
    395.01061,
    395.34027,
    395.67006,
    396,
    396.33006,
    396.66027,
    396.99061,
    397.3211,
    397.65171,
    397.98247,
    398.31336,
    398.6444,
    398.97556,
    399.30687,
    399.63831,
    399.9699,
    400.30161,
    400.63347,
    400.96546,
    401.2976,
    401.62986,
    401.96227,
    402.29481,
    402.6275,
    402.96031,
    403.29327,
    403.62636,
    403.9596,
    404.29296,
    404.62647,
    404.96011,
    405.2939,
    405.62781,
    405.96187,
    406.29606,
    406.6304,
    406.96486,
    407.29947,
    407.63421,
    407.9691,
    408.30411,
    408.63927,
    408.97456,
    409.31,
    409.64556,
    409.98127,
    410.31711,
    410.6531,
    410.98921,
    411.32547,
    411.66186,
    411.9984,
    412.33506,
    412.67187,
    413.00881,
    413.3459,
    413.68311,
    414.02047,
    414.35796,
    414.6956,
    415.03336,
    415.37127,
    415.70931,
    416.0475,
    416.38581,
    416.72427,
    417.06286,
    417.4016,
    417.74046,
    418.07947,
    418.41861,
    418.7579,
    419.09731,
    419.43687,
    419.77656,
    420.1164,
    420.45636,
    420.79647,
    421.13671,
    421.4771,
    421.81761,
    422.15827,
    422.49906,
    422.84,
    423.18106,
    423.52227,
    423.86361,
    424.2051,
    424.54671,
    424.88847,
    425.23036,
    425.5724,
    425.91456,
    426.25687,
    426.59931,
    426.9419,
    427.28461,
    427.62747,
    427.97046,
    428.3136,
    428.65686,
    429.00027,
    429.34381,
    429.6875,
    430.03131,
    430.37527,
    430.71936,
    431.0636,
    431.40796,
    431.75247,
    432.09711,
    432.4419,
    432.78681,
    433.13187,
    433.47706,
    433.8224,
    434.16786,
    434.51347,
    434.85921,
    435.2051,
    435.55111,
    435.89727,
    436.24356,
    436.59,
    436.93656,
    437.28327,
    437.63011,
    437.9771,
    438.32421,
    438.67147,
    439.01886,
    439.3664,
    439.71406,
    440.06187,
    440.40981,
    440.7579,
    441.10611,
    441.45447,
    441.80296,
    442.1516,
    442.50036,
    442.84927,
    443.19831,
    443.5475,
    443.89681,
    444.24627,
    444.59586,
    444.9456,
    445.29546,
    445.64547,
    445.99561,
    446.3459,
    446.69631,
    447.04687,
    447.39756,
    447.7484,
    448.09936,
    448.45047,
    448.80171,
    449.1531,
    449.50461,
    449.85627,
    450.20806,
    450.56,
    450.91206,
    451.26427,
    451.61661,
    451.9691,
    452.32171,
    452.67447,
    453.02736,
    453.3804,
    453.73356,
    454.08687,
    454.44031,
    454.7939,
    455.14761,
    455.50147,
    455.85546,
    456.2096,
    456.56386,
    456.91827,
    457.27281,
    457.6275,
    457.98231,
    458.33727,
    458.69236,
    459.0476,
    459.40296,
    459.75847,
    460.11411,
    460.4699,
    460.82581,
    461.18187,
    461.53806,
    461.8944,
    462.25086,
    462.60747,
    462.96421,
    463.3211,
    463.67811,
    464.03527,
    464.39256,
    464.75,
    465.10756,
    465.46527,
    465.82311,
    466.1811,
    466.53921,
    466.89747,
    467.25586,
    467.6144,
    467.97306,
    468.33187,
    468.69081,
    469.0499,
    469.40911,
    469.76847,
    470.12796,
    470.4876,
    470.84736,
    471.20727,
    471.56731,
    471.9275,
    472.28781,
    472.64827,
    473.00886,
    473.3696,
    473.73046,
    474.09147,
    474.45261,
    474.8139,
    475.17531,
    475.53687,
    475.89856,
    476.2604,
    476.62236,
    476.98447,
    477.34671,
    477.7091,
    478.07161,
    478.43427,
    478.79706,
    479.16,
    479.52306,
    479.88627,
    480.24961,
    480.6131,
    480.97671,
    481.34047,
    481.70436,
    482.0684,
    482.43256,
    482.79687,
    483.16131,
    483.5259,
    483.89061,
    484.25547,
    484.62046,
    484.9856,
    485.35086,
    485.71627,
    486.08181,
    486.4475,
    486.81331,
    487.17927,
    487.54536,
    487.9116,
    488.27796,
    488.64447,
    489.01111,
    489.3779,
    489.74481,
    490.11187,
    490.47906,
    490.8464,
    491.21386,
    491.58147,
    491.94921,
    492.3171,
    492.68511,
    493.05327,
    493.42156,
    493.79,
    494.15856,
    494.52727,
    494.89611,
    495.2651,
    495.63421,
    496.00347,
    496.37286,
    496.7424,
    497.11206,
    497.48187,
    497.85181,
    498.2219,
    498.59211,
    498.96247,
    499.33296,
    499.7036,
    500.07436,
    500.44527,
    500.81631,
    501.1875,
    501.55881,
    501.93027,
    502.30186,
    502.6736,
    503.04546,
    503.41747,
    503.78961,
    504.1619,
    504.53431,
    504.90687,
    505.27956,
    505.6524,
    506.02536,
    506.39847,
    506.77171,
    507.1451,
    507.51861,
    507.89227,
    508.26606,
    508.64,
    509.01406,
    509.38827,
    509.76261,
    510.1371,
    510.51171,
    510.88647,
    511.26136,
    511.6364,
    512.01156,
    512.38687,
    512.76231,
    513.1379,
    513.51361,
    513.88947,
    514.26546,
    514.6416,
    515.01786,
    515.39427,
    515.77081,
    516.1475,
    516.52431,
    516.90127,
    517.27836,
    517.6556,
    518.03296,
    518.41047,
    518.78811,
    519.1659,
    519.54381,
    519.92187,
    520.30006,
    520.6784,
    521.05686,
    521.43547,
    521.81421,
    522.1931,
    522.57211,
    522.95127,
    523.33056,
    523.71,
    524.08956,
    524.46927,
    524.84911,
    525.2291,
    525.60921,
    525.98947,
    526.36986,
    526.7504,
    527.13106,
    527.51187,
    527.89281,
    528.2739,
    528.65511,
    529.03647,
    529.41796,
    529.7996,
    530.18136,
    530.56327,
    530.94531,
    531.3275,
    531.70981,
    532.09227,
    532.47486,
    532.8576,
    533.24046,
    533.62347,
    534.00661,
    534.3899,
    534.77331,
    535.15687,
    535.54056,
    535.9244,
    536.30836,
    536.69247,
    537.07671,
    537.4611,
    537.84561,
    538.23027,
    538.61506,
    539,
    539.38506,
    539.77027,
    540.15561,
    540.5411,
    540.92671,
    541.31247,
    541.69836,
    542.0844,
    542.47056,
    542.85687,
    543.24331,
    543.6299,
    544.01661,
    544.40347,
    544.79046,
    545.1776,
    545.56486,
    545.95227,
    546.33981,
    546.7275,
    547.11531,
    547.50327,
    547.89136,
    548.2796,
    548.66796,
    549.05647,
    549.44511,
    549.8339,
    550.22281,
    550.61187,
    551.00106,
    551.3904,
    551.77986,
    552.16947,
    552.55921,
    552.9491,
    553.33911,
    553.72927,
    554.11956,
    554.51,
    554.90056,
    555.29127,
    555.68211,
    556.0731,
    556.46421,
    556.85547,
    557.24686,
    557.6384,
    558.03006,
    558.42187,
    558.81381,
    559.2059,
    559.59811,
    559.99047,
    560.38296,
    560.7756,
    561.16836,
    561.56127,
    561.95431,
    562.3475,
    562.74081,
    563.13427,
    563.52786,
    563.9216,
    564.31546,
    564.70947,
    565.10361,
    565.4979,
    565.89231,
    566.28687,
    566.68156,
    567.0764,
    567.47136,
    567.86647,
    568.26171,
    568.6571,
    569.05261,
    569.44827,
    569.84406,
    570.24,
    570.63606,
    571.03227,
    571.42861,
    571.8251,
    572.22171,
    572.61847,
    573.01536,
    573.4124,
    573.80956,
    574.20687,
    574.60431,
    575.0019,
    575.39961,
    575.79747,
    576.19546,
    576.5936,
    576.99186,
    577.39027,
    577.78881,
    578.1875,
    578.58631,
    578.98527,
    579.38436,
    579.7836,
    580.18296,
    580.58247,
    580.98211,
    581.3819,
    581.78181,
    582.18187,
    582.58206,
    582.9824,
    583.38286,
    583.78347,
    584.18421,
    584.5851,
    584.98611,
    585.38727,
    585.78856,
    586.19,
    586.59156,
    586.99327,
    587.39511,
    587.7971,
    588.19921,
    588.60147,
    589.00386,
    589.4064,
    589.80906,
    590.21187,
    590.61481,
    591.0179,
    591.42111,
    591.82447,
    592.22796,
    592.6316,
    593.03536,
    593.43927,
    593.84331,
    594.2475,
    594.65181,
    595.05627,
    595.46086,
    595.8656,
    596.27046,
    596.67547,
    597.08061,
    597.4859,
    597.89131,
    598.29687,
    598.70256,
    599.1084,
    599.51436,
    599.92047,
    600.32671,
    600.7331,
    601.13961,
    601.54627,
    601.95306,
    602.36,
    602.76706,
    603.17427,
    603.58161,
    603.9891,
    604.39671,
    604.80447,
    605.21236,
    605.6204,
    606.02856,
    606.43687,
    606.84531,
    607.2539,
    607.66261,
    608.07147,
    608.48046,
    608.8896,
    609.29886,
    609.70827,
    610.11781,
    610.5275,
    610.93731,
    611.34727,
    611.75736,
    612.1676,
    612.57796,
    612.98847,
    613.39911,
    613.8099,
    614.22081,
    614.63187,
    615.04306,
    615.4544,
    615.86586,
    616.27747,
    616.68921,
    617.1011,
    617.51311,
    617.92527,
    618.33756,
    618.75,
    619.16256,
    619.57527,
    619.98811,
    620.4011,
    620.81421,
    621.22747,
    621.64086,
    622.0544,
    622.46806,
    622.88187,
    623.29581,
    623.7099,
    624.12411,
    624.53847,
    624.95296,
    625.3676,
    625.78236,
    626.19727,
    626.61231,
    627.0275,
    627.44281,
    627.85827,
    628.27386,
    628.6896,
    629.10546,
    629.52147,
    629.93761,
    630.3539,
    630.77031,
    631.18687,
    631.60356,
    632.0204,
    632.43736,
    632.85447,
    633.27171,
    633.6891,
    634.10661,
    634.52427,
    634.94206,
    635.36,
    635.77806,
    636.19627,
    636.61461,
    637.0331,
    637.45171,
    637.87047,
    638.28936,
    638.7084,
    639.12756,
    639.54687,
    639.96631,
    640.3859,
    640.80561,
    641.22547,
    641.64546,
    642.0656,
    642.48586,
    642.90627,
    643.32681,
    643.7475,
    644.16831,
    644.58927,
    645.01036,
    645.4316,
    645.85296,
    646.27447,
    646.69611,
    647.1179,
    647.53981,
    647.96187,
    648.38406,
    648.8064,
    649.22886,
    649.65147,
    650.07421,
    650.4971,
    650.92011,
    651.34327,
    651.76656,
    652.19,
    652.61356,
    653.03727,
    653.46111,
    653.8851,
    654.30921,
    654.73347,
    655.15786,
    655.5824,
    656.00706,
    656.43187,
    656.85681,
    657.2819,
    657.70711,
    658.13247,
    658.55796,
    658.9836,
    659.40936,
    659.83527,
    660.26131,
    660.6875,
    661.11381,
    661.54027,
    661.96686,
    662.3936,
    662.82046,
    663.24747,
    663.67461,
    664.1019,
    664.52931,
    664.95687,
    665.38456,
    665.8124,
    666.24036,
    666.66847,
    667.09671,
    667.5251,
    667.95361,
    668.38227,
    668.81106,
    669.24,
    669.66906,
    670.09827,
    670.52761,
    670.9571,
    671.38671,
    671.81647,
    672.24636,
    672.6764,
    673.10656,
    673.53687,
    673.96731,
    674.3979,
    674.82861,
    675.25947,
    675.69046,
    676.1216,
    676.55286,
    676.98427,
    677.41581,
    677.8475,
    678.27931,
    678.71127,
    679.14336,
    679.5756,
    680.00796,
    680.44047,
    680.87311,
    681.3059,
    681.73881,
    682.17187,
    682.60506,
    683.0384,
    683.47186,
    683.90547,
    684.33921,
    684.7731,
    685.20711,
    685.64127,
    686.07556,
    686.51,
    686.94456,
    687.37927,
    687.81411,
    688.2491,
    688.68421,
    689.11947,
    689.55486,
    689.9904,
    690.42606,
    690.86187,
    691.29781,
    691.7339,
    692.17011,
    692.60647,
    693.04296,
    693.4796,
    693.91636,
    694.35327,
    694.79031,
    695.2275,
    695.66481,
    696.10227,
    696.53986,
    696.9776,
    697.41546,
    697.85347,
    698.29161,
    698.7299,
    699.16831,
    699.60687,
    700.04556,
    700.4844,
    700.92336,
    701.36247,
    701.80171,
    702.2411,
    702.68061,
    703.12027,
    703.56006,
    704,
    704.44006,
    704.88027,
    705.32061,
    705.7611,
    706.20171,
    706.64247,
    707.08336,
    707.5244,
    707.96556,
    708.40687,
    708.84831,
    709.2899,
    709.73161,
    710.17347,
    710.61546,
    711.0576,
    711.49986,
    711.94227,
    712.38481,
    712.8275,
    713.27031,
    713.71327,
    714.15636,
    714.5996,
    715.04296,
    715.48647,
    715.93011,
    716.3739,
    716.81781,
    717.26187,
    717.70606,
    718.1504,
    718.59486,
    719.03947,
    719.48421,
    719.9291,
    720.37411,
    720.81927,
    721.26456,
    721.71,
    722.15556,
    722.60127,
    723.04711,
    723.4931,
    723.93921,
    724.38547,
    724.83186,
    725.2784,
    725.72506,
    726.17187,
    726.61881,
    727.0659,
    727.51311,
    727.96047,
    728.40796,
    728.8556,
    729.30336,
    729.75127,
    730.19931,
    730.6475,
    731.09581,
    731.54427,
    731.99286,
    732.4416,
    732.89046,
    733.33947,
    733.78861,
    734.2379,
    734.68731,
    735.13687,
    735.58656,
    736.0364,
    736.48636,
    736.93647,
    737.38671,
    737.8371,
    738.28761,
    738.73827,
    739.18906,
    739.64,
    740.09106,
    740.54227,
    740.99361,
    741.4451,
    741.89671,
    742.34847,
    742.80036,
    743.2524,
    743.70456,
    744.15687,
    744.60931,
    745.0619,
    745.51461,
    745.96747,
    746.42046,
    746.8736,
    747.32686,
    747.78027,
    748.23381,
    748.6875,
    749.14131,
    749.59527,
    750.04936,
    750.5036,
    750.95796,
    751.41247,
    751.86711,
    752.3219,
    752.77681,
    753.23187,
    753.68706,
    754.1424,
    754.59786,
    755.05347,
    755.50921,
    755.9651,
    756.42111,
    756.87727,
    757.33356,
    757.79,
    758.24656,
    758.70327,
    759.16011,
    759.6171,
    760.07421,
    760.53147,
    760.98886,
    761.4464,
    761.90406,
    762.36187,
    762.81981,
    763.2779,
    763.73611,
    764.19447,
    764.65296,
    765.1116,
    765.57036,
    766.02927,
    766.48831,
    766.9475,
    767.40681,
    767.86627,
    768.32586,
    768.7856,
    769.24546,
    769.70547,
    770.16561,
    770.6259,
    771.08631,
    771.54687,
    772.00756,
    772.4684,
    772.92936,
    773.39047,
    773.85171,
    774.3131,
    774.77461,
    775.23627,
    775.69806,
    776.16,
    776.62206,
    777.08427,
    777.54661,
    778.0091,
    778.47171,
    778.93447,
    779.39736,
    779.8604,
    780.32356,
    780.78687,
    781.25031,
    781.7139,
    782.17761,
    782.64147,
    783.10546,
    783.5696,
    784.03386,
    784.49827,
    784.96281,
    785.4275,
    785.89231,
    786.35727,
    786.82236,
    787.2876,
    787.75296,
    788.21847,
    788.68411,
    789.1499,
    789.61581,
    790.08187,
    790.54806,
    791.0144,
    791.48086,
    791.94747,
    792.41421,
    792.8811,
    793.34811,
    793.81527,
    794.28256,
    794.75,
    795.21756,
    795.68527,
    796.15311,
    796.6211,
    797.08921,
    797.55747,
    798.02586,
    798.4944,
    798.96306,
    799.43187,
    799.90081,
    800.3699,
    800.83911,
    801.30847,
    801.77796,
    802.2476,
    802.71736,
    803.18727,
    803.65731,
    804.1275,
    804.59781,
    805.06827,
    805.53886,
    806.0096,
    806.48046,
    806.95147,
    807.42261,
    807.8939,
    808.36531,
    808.83687,
    809.30856,
    809.7804,
    810.25236,
    810.72447,
    811.19671,
    811.6691,
    812.14161,
    812.61427,
    813.08706,
    813.56,
    814.03306,
    814.50627,
    814.97961,
    815.4531,
    815.92671,
    816.40047,
    816.87436,
    817.3484,
    817.82256,
    818.29687,
    818.77131,
    819.2459,
    819.72061,
    820.19547,
    820.67046,
    821.1456,
    821.62086,
    822.09627,
    822.57181,
    823.0475,
    823.52331,
    823.99927,
    824.47536,
    824.9516,
    825.42796,
    825.90447,
    826.38111,
    826.8579,
    827.33481,
    827.81187,
    828.28906,
    828.7664,
    829.24386,
    829.72147,
    830.19921,
    830.6771,
    831.15511,
    831.63327,
    832.11156,
    832.59,
    833.06856,
    833.54727,
    834.02611,
    834.5051,
    834.98421,
    835.46347,
    835.94286,
    836.4224,
    836.90206,
    837.38187,
    837.86181,
    838.3419,
    838.82211,
    839.30247,
    839.78296,
    840.2636,
    840.74436,
    841.22527,
    841.70631,
    842.1875,
    842.66881,
    843.15027,
    843.63186,
    844.1136,
    844.59546,
    845.07747,
    845.55961,
    846.0419,
    846.52431,
    847.00687,
    847.48956,
    847.9724,
    848.45536,
    848.93847,
    849.42171,
    849.9051,
    850.38861,
    850.87227,
    851.35606,
    851.84,
    852.32406,
    852.80827,
    853.29261,
    853.7771,
    854.26171,
    854.74647,
    855.23136,
    855.7164,
    856.20156,
    856.68687,
    857.17231,
    857.6579,
    858.14361,
    858.62947,
    859.11546,
    859.6016,
    860.08786,
    860.57427,
    861.06081,
    861.5475,
    862.03431,
    862.52127,
    863.00836,
    863.4956,
    863.98296,
    864.47047,
    864.95811,
    865.4459,
    865.93381,
    866.42187,
    866.91006,
    867.3984,
    867.88686,
    868.37547,
    868.86421,
    869.3531,
    869.84211,
    870.33127,
    870.82056,
    871.31,
    871.79956,
    872.28927,
    872.77911,
    873.2691,
    873.75921,
    874.24947,
    874.73986,
    875.2304,
    875.72106,
    876.21187,
    876.70281,
    877.1939,
    877.68511,
    878.17647,
    878.66796,
    879.1596,
    879.65136,
    880.14327,
    880.63531,
    881.1275,
    881.61981,
    882.11227,
    882.60486,
    883.0976,
    883.59046,
    884.08347,
    884.57661,
    885.0699,
    885.56331,
    886.05687,
    886.55056,
    887.0444,
    887.53836,
    888.03247,
    888.52671,
    889.0211,
    889.51561,
    890.01027,
    890.50506,
    891,
    891.49506,
    891.99027,
    892.48561,
    892.9811,
    893.47671,
    893.97247,
    894.46836,
    894.9644,
    895.46056,
    895.95687,
    896.45331,
    896.9499,
    897.44661,
    897.94347,
    898.44046,
    898.9376,
    899.43486,
    899.93227,
    900.42981,
    900.9275,
    901.42531,
    901.92327,
    902.42136,
    902.9196,
    903.41796,
    903.91647,
    904.41511,
    904.9139,
    905.41281,
    905.91187,
    906.41106,
    906.9104,
    907.40986,
    907.90947,
    908.40921,
    908.9091,
    909.40911,
    909.90927,
    910.40956,
    910.91,
    911.41056,
    911.91127,
    912.41211,
    912.9131,
    913.41421,
    913.91547,
    914.41686,
    914.9184,
    915.42006,
    915.92187,
    916.42381,
    916.9259,
    917.42811,
    917.93047,
    918.43296,
    918.9356,
    919.43836,
    919.94127,
    920.44431,
    920.9475,
    921.45081,
    921.95427,
    922.45786,
    922.9616,
    923.46546,
    923.96947,
    924.47361,
    924.9779,
    925.48231,
    925.98687,
    926.49156,
    926.9964,
    927.50136,
    928.00647,
    928.51171,
    929.0171,
    929.52261,
    930.02827,
    930.53406,
    931.04,
    931.54606,
    932.05227,
    932.55861,
    933.0651,
    933.57171,
    934.07847,
    934.58536,
    935.0924,
    935.59956,
    936.10687,
    936.61431,
    937.1219,
    937.62961,
    938.13747,
    938.64546,
    939.1536,
    939.66186,
    940.17027,
    940.67881,
    941.1875,
    941.69631,
    942.20527,
    942.71436,
    943.2236,
    943.73296,
    944.24247,
    944.75211,
    945.2619,
    945.77181,
    946.28187,
    946.79206,
    947.3024,
    947.81286,
    948.32347,
    948.83421,
    949.3451,
    949.85611,
    950.36727,
    950.87856,
    951.39,
    951.90156,
    952.41327,
    952.92511,
    953.4371,
    953.94921,
    954.46147,
    954.97386,
    955.4864,
    955.99906,
    956.51187,
    957.02481,
    957.5379,
    958.05111,
    958.56447,
    959.07796,
    959.5916,
    960.10536,
    960.61927,
    961.13331,
    961.6475,
    962.16181,
    962.67627,
    963.19086,
    963.7056,
    964.22046,
    964.73547,
    965.25061,
    965.7659,
    966.28131,
    966.79687,
    967.31256,
    967.8284,
    968.34436,
    968.86047,
    969.37671,
    969.8931,
    970.40961,
    970.92627,
    971.44306,
    971.96,
    972.47706,
    972.99427,
    973.51161,
    974.0291,
    974.54671,
    975.06447,
    975.58236,
    976.1004,
    976.61856,
    977.13687,
    977.65531,
    978.1739,
    978.69261,
    979.21147,
    979.73046,
    980.2496,
    980.76886,
    981.28827,
    981.80781,
    982.3275,
    982.84731,
    983.36727,
    983.88736,
    984.4076,
    984.92796,
    985.44847,
    985.96911,
    986.4899,
    987.01081,
    987.53187,
    988.05306,
    988.5744,
    989.09586,
    989.61747,
    990.13921,
    990.6611,
    991.18311,
    991.70527,
    992.22756,
    992.75,
    993.27256,
    993.79527,
    994.31811,
    994.8411,
    995.36421,
    995.88747,
    996.41086,
    996.9344,
    997.45806,
    997.98187,
    998.50581,
    999.0299,
    999.55411,
    1000.07847,
    1000.60296,
    1001.1276,
    1001.65236,
    1002.17727,
    1002.70231,
    1003.2275,
    1003.75281,
    1004.27827,
    1004.80386,
    1005.3296,
    1005.85546,
    1006.38147,
    1006.90761,
    1007.4339,
    1007.96031,
    1008.48687,
    1009.01356,
    1009.5404,
    1010.06736,
    1010.59447,
    1011.12171,
    1011.6491,
    1012.17661,
    1012.70427,
    1013.23206,
    1013.76,
    1014.28806,
    1014.81627,
    1015.34461,
    1015.8731,
    1016.40171,
    1016.93047,
    1017.45936,
    1017.9884,
    1018.51756,
    1019.04687,
    1019.57631,
    1020.1059,
    1020.63561,
    1021.16547,
    1021.69546,
    1022.2256,
    1022.75586,
    1023.28627,
    1023.81681,
    1024.3475,
    1024.87831,
    1025.40927,
    1025.94036,
    1026.4716,
    1027.00296,
    1027.53447,
    1028.06611,
    1028.5979,
    1029.12981,
    1029.66187,
    1030.19406,
    1030.7264,
    1031.25886,
    1031.79147,
    1032.32421,
    1032.8571,
    1033.39011,
    1033.92327,
    1034.45656,
    1034.99,
    1035.52356,
    1036.05727,
    1036.59111,
    1037.1251,
    1037.65921,
    1038.19347,
    1038.72786,
    1039.2624,
    1039.79706,
    1040.33187,
    1040.86681,
    1041.4019,
    1041.93711,
    1042.47247,
    1043.00796,
    1043.5436,
    1044.07936,
    1044.61527,
    1045.15131,
    1045.6875,
    1046.22381,
    1046.76027,
    1047.29686,
    1047.8336,
    1048.37046,
    1048.90747,
    1049.44461,
    1049.9819,
    1050.51931,
    1051.05687,
    1051.59456,
    1052.1324,
    1052.67036,
    1053.20847,
    1053.74671,
    1054.2851,
    1054.82361,
    1055.36227,
    1055.90106,
    1056.44,
    1056.97906,
    1057.51827,
    1058.05761,
    1058.5971,
    1059.13671,
    1059.67647,
    1060.21636,
    1060.7564,
    1061.29656,
    1061.83687,
    1062.37731,
    1062.9179,
    1063.45861,
    1063.99947,
    1064.54046,
    1065.0816,
    1065.62286,
    1066.16427,
    1066.70581,
    1067.2475,
    1067.78931,
    1068.33127,
    1068.87336,
    1069.4156,
    1069.95796,
    1070.50047,
    1071.04311,
    1071.5859,
    1072.12881,
    1072.67187,
    1073.21506,
    1073.7584,
    1074.30186,
    1074.84547,
    1075.38921,
    1075.9331,
    1076.47711,
    1077.02127,
    1077.56556,
    1078.11,
    1078.65456,
    1079.19927,
    1079.74411,
    1080.2891,
    1080.83421,
    1081.37947,
    1081.92486,
    1082.4704,
    1083.01606,
    1083.56187,
    1084.10781,
    1084.6539,
    1085.20011,
    1085.74647,
    1086.29296,
    1086.8396,
    1087.38636,
    1087.93327,
    1088.48031,
    1089.0275,
    1089.57481,
    1090.12227,
    1090.66986,
    1091.2176,
    1091.76546,
    1092.31347,
    1092.86161,
    1093.4099,
    1093.95831,
    1094.50687,
    1095.05556,
    1095.6044,
    1096.15336,
    1096.70247,
    1097.25171,
    1097.8011,
    1098.35061,
    1098.90027,
    1099.45006,
    1100,
    1100.55006,
    1101.10027,
    1101.65061,
    1102.2011,
    1102.75171,
    1103.30247,
    1103.85336,
    1104.4044,
    1104.95556,
    1105.50687,
    1106.05831,
    1106.6099,
    1107.16161,
    1107.71347,
    1108.26546,
    1108.8176,
    1109.36986,
    1109.92227,
    1110.47481,
    1111.0275,
    1111.58031,
    1112.13327,
    1112.68636,
    1113.2396,
    1113.79296,
    1114.34647,
    1114.90011,
    1115.4539,
    1116.00781,
    1116.56187,
    1117.11606,
    1117.6704,
    1118.22486,
    1118.77947,
    1119.33421,
    1119.8891,
    1120.44411,
    1120.99927,
    1121.55456,
    1122.11,
    1122.66556,
    1123.22127,
    1123.77711,
    1124.3331,
    1124.88921,
    1125.44547,
    1126.00186,
    1126.5584,
    1127.11506,
    1127.67187,
    1128.22881,
    1128.7859,
    1129.34311,
    1129.90047,
    1130.45796,
    1131.0156,
    1131.57336,
    1132.13127,
    1132.68931,
    1133.2475,
    1133.80581,
    1134.36427,
    1134.92286,
    1135.4816,
    1136.04046,
    1136.59947,
    1137.15861,
    1137.7179,
    1138.27731,
    1138.83687,
    1139.39656,
    1139.9564,
    1140.51636,
    1141.07647,
    1141.63671,
    1142.1971,
    1142.75761,
    1143.31827,
    1143.87906,
    1144.44,
    1145.00106,
    1145.56227,
    1146.12361,
    1146.6851,
    1147.24671,
    1147.80847,
    1148.37036,
    1148.9324,
    1149.49456,
    1150.05687,
    1150.61931,
    1151.1819,
    1151.74461,
    1152.30747,
    1152.87046,
    1153.4336,
    1153.99686,
    1154.56027,
    1155.12381,
    1155.6875,
    1156.25131,
    1156.81527,
    1157.37936,
    1157.9436,
    1158.50796,
    1159.07247,
    1159.63711,
    1160.2019,
    1160.76681,
    1161.33187,
    1161.89706,
    1162.4624,
    1163.02786,
    1163.59347,
    1164.15921,
    1164.7251,
    1165.29111,
    1165.85727,
    1166.42356,
    1166.99,
    1167.55656,
    1168.12327,
    1168.69011,
    1169.2571,
    1169.82421,
    1170.39147,
    1170.95886,
    1171.5264,
    1172.09406,
    1172.66187,
    1173.22981,
    1173.7979,
    1174.36611,
    1174.93447,
    1175.50296,
    1176.0716,
    1176.64036,
    1177.20927,
    1177.77831,
    1178.3475,
    1178.91681,
    1179.48627,
    1180.05586,
    1180.6256,
    1181.19546,
    1181.76547,
    1182.33561,
    1182.9059,
    1183.47631,
    1184.04687,
    1184.61756,
    1185.1884,
    1185.75936,
    1186.33047,
    1186.90171,
    1187.4731,
    1188.04461,
    1188.61627,
    1189.18806,
    1189.76,
    1190.33206,
    1190.90427,
    1191.47661,
    1192.0491,
    1192.62171,
    1193.19447,
    1193.76736,
    1194.3404,
    1194.91356,
    1195.48687,
    1196.06031,
    1196.6339,
    1197.20761,
    1197.78147,
    1198.35546,
    1198.9296,
    1199.50386,
    1200.07827,
    1200.65281,
    1201.2275,
    1201.80231,
    1202.37727,
    1202.95236,
    1203.5276,
    1204.10296,
    1204.67847,
    1205.25411,
    1205.8299,
    1206.40581,
    1206.98187,
    1207.55806,
    1208.1344,
    1208.71086,
    1209.28747,
    1209.86421,
    1210.4411,
    1211.01811,
    1211.59527,
    1212.17256,
    1212.75,
    1213.32756,
    1213.90527,
    1214.48311,
    1215.0611,
    1215.63921,
    1216.21747,
    1216.79586,
    1217.3744,
    1217.95306,
    1218.53187,
    1219.11081,
    1219.6899,
    1220.26911,
    1220.84847,
    1221.42796,
    1222.0076,
    1222.58736,
    1223.16727,
    1223.74731,
    1224.3275,
    1224.90781,
    1225.48827,
    1226.06886,
    1226.6496,
    1227.23046,
    1227.81147,
    1228.39261,
    1228.9739,
    1229.55531,
    1230.13687,
    1230.71856,
    1231.3004,
    1231.88236,
    1232.46447,
    1233.04671,
    1233.6291,
    1234.21161,
    1234.79427,
    1235.37706,
    1235.96,
    1236.54306,
    1237.12627,
    1237.70961,
    1238.2931,
    1238.87671,
    1239.46047,
    1240.04436,
    1240.6284,
    1241.21256,
    1241.79687,
    1242.38131,
    1242.9659,
    1243.55061,
    1244.13547,
    1244.72046,
    1245.3056,
    1245.89086,
    1246.47627,
    1247.06181,
    1247.6475,
    1248.23331,
    1248.81927,
    1249.40536,
    1249.9916,
    1250.57796,
    1251.16447,
    1251.75111,
    1252.3379,
    1252.92481,
    1253.51187,
    1254.09906,
    1254.6864,
    1255.27386,
    1255.86147,
    1256.44921,
    1257.0371,
    1257.62511,
    1258.21327,
    1258.80156,
    1259.39,
    1259.97856,
    1260.56727,
    1261.15611,
    1261.7451,
    1262.33421,
    1262.92347,
    1263.51286,
    1264.1024,
    1264.69206,
    1265.28187,
    1265.87181,
    1266.4619,
    1267.05211,
    1267.64247,
    1268.23296,
    1268.8236,
    1269.41436,
    1270.00527,
    1270.59631,
    1271.1875,
    1271.77881,
    1272.37027,
    1272.96186,
    1273.5536,
    1274.14546,
    1274.73747,
    1275.32961,
    1275.9219,
    1276.51431,
    1277.10687,
    1277.69956,
    1278.2924,
    1278.88536,
    1279.47847,
    1280.07171,
    1280.6651,
    1281.25861,
    1281.85227,
    1282.44606,
    1283.04,
    1283.63406,
    1284.22827,
    1284.82261,
    1285.4171,
    1286.01171,
    1286.60647,
    1287.20136,
    1287.7964,
    1288.39156,
    1288.98687,
    1289.58231,
    1290.1779,
    1290.77361,
    1291.36947,
    1291.96546,
    1292.5616,
    1293.15786,
    1293.75427,
    1294.35081,
    1294.9475,
    1295.54431,
    1296.14127,
    1296.73836,
    1297.3356,
    1297.93296,
    1298.53047,
    1299.12811,
    1299.7259,
    1300.32381,
    1300.92187,
    1301.52006,
    1302.1184,
    1302.71686,
    1303.31547,
    1303.91421,
    1304.5131,
    1305.11211,
    1305.71127,
    1306.31056,
    1306.91,
    1307.50956,
    1308.10927,
    1308.70911,
    1309.3091,
    1309.90921,
    1310.50947,
    1311.10986,
    1311.7104,
    1312.31106,
    1312.91187,
    1313.51281,
    1314.1139,
    1314.71511,
    1315.31647,
    1315.91796,
    1316.5196,
    1317.12136,
    1317.72327,
    1318.32531,
    1318.9275,
    1319.52981,
    1320.13227,
    1320.73486,
    1321.3376,
    1321.94046,
    1322.54347,
    1323.14661,
    1323.7499,
    1324.35331,
    1324.95687,
    1325.56056,
    1326.1644,
    1326.76836,
    1327.37247,
    1327.97671,
    1328.5811,
    1329.18561,
    1329.79027,
    1330.39506,
    1331,
    1331.60506,
    1332.21027,
    1332.81561,
    1333.4211,
    1334.02671,
    1334.63247,
    1335.23836,
    1335.8444,
    1336.45056,
    1337.05687,
    1337.66331,
    1338.2699,
    1338.87661,
    1339.48347,
    1340.09046,
    1340.6976,
    1341.30486,
    1341.91227,
    1342.51981,
    1343.1275,
    1343.73531,
    1344.34327,
    1344.95136,
    1345.5596,
    1346.16796,
    1346.77647,
    1347.38511,
    1347.9939,
    1348.60281,
    1349.21187,
    1349.82106,
    1350.4304,
    1351.03986,
    1351.64947,
    1352.25921,
    1352.8691,
    1353.47911,
    1354.08927,
    1354.69956,
    1355.31,
    1355.92056,
    1356.53127,
    1357.14211,
    1357.7531,
    1358.36421,
    1358.97547,
    1359.58686,
    1360.1984,
    1360.81006,
    1361.42187,
    1362.03381,
    1362.6459,
    1363.25811,
    1363.87047,
    1364.48296,
    1365.0956,
    1365.70836,
    1366.32127,
    1366.93431,
    1367.5475,
    1368.16081,
    1368.77427,
    1369.38786,
    1370.0016,
    1370.61546,
    1371.22947,
    1371.84361,
    1372.4579,
    1373.07231,
    1373.68687,
    1374.30156,
    1374.9164,
    1375.53136,
    1376.14647,
    1376.76171,
    1377.3771,
    1377.99261,
    1378.60827,
    1379.22406,
    1379.84,
    1380.45606,
    1381.07227,
    1381.68861,
    1382.3051,
    1382.92171,
    1383.53847,
    1384.15536,
    1384.7724,
    1385.38956,
    1386.00687,
    1386.62431,
    1387.2419,
    1387.85961,
    1388.47747,
    1389.09546,
    1389.7136,
    1390.33186,
    1390.95027,
    1391.56881,
    1392.1875,
    1392.80631,
    1393.42527,
    1394.04436,
    1394.6636,
    1395.28296,
    1395.90247,
    1396.52211,
    1397.1419,
    1397.76181,
    1398.38187,
    1399.00206,
    1399.6224,
    1400.24286,
    1400.86347,
    1401.48421,
    1402.1051,
    1402.72611,
    1403.34727,
    1403.96856,
    1404.59,
    1405.21156,
    1405.83327,
    1406.45511,
    1407.0771,
    1407.69921,
    1408.32147,
    1408.94386,
    1409.5664,
    1410.18906,
    1410.81187,
    1411.43481,
    1412.0579,
    1412.68111,
    1413.30447,
    1413.92796,
    1414.5516,
    1415.17536,
    1415.79927,
    1416.42331,
    1417.0475,
    1417.67181,
    1418.29627,
    1418.92086,
    1419.5456,
    1420.17046,
    1420.79547,
    1421.42061,
    1422.0459,
    1422.67131,
    1423.29687,
    1423.92256,
    1424.5484,
    1425.17436,
    1425.80047,
    1426.42671,
    1427.0531,
    1427.67961,
    1428.30627,
    1428.93306,
    1429.56,
    1430.18706,
    1430.81427,
    1431.44161,
    1432.0691,
    1432.69671,
    1433.32447,
    1433.95236,
    1434.5804,
    1435.20856,
    1435.83687,
    1436.46531,
    1437.0939,
    1437.72261,
    1438.35147,
    1438.98046,
    1439.6096,
    1440.23886,
    1440.86827,
    1441.49781,
    1442.1275,
    1442.75731,
    1443.38727,
    1444.01736,
    1444.6476,
    1445.27796,
    1445.90847,
    1446.53911,
    1447.1699,
    1447.80081,
    1448.43187,
    1449.06306,
    1449.6944,
    1450.32586,
    1450.95747,
    1451.58921,
    1452.2211,
    1452.85311,
    1453.48527,
    1454.11756,
    1454.75,
    1455.38256,
    1456.01527,
    1456.64811,
    1457.2811,
    1457.91421,
    1458.54747,
    1459.18086,
    1459.8144,
    1460.44806,
    1461.08187,
    1461.71581,
    1462.3499,
    1462.98411,
    1463.61847,
    1464.25296,
    1464.8876,
    1465.52236,
    1466.15727,
    1466.79231,
    1467.4275,
    1468.06281,
    1468.69827,
    1469.33386,
    1469.9696,
    1470.60546,
    1471.24147,
    1471.87761,
    1472.5139,
    1473.15031,
    1473.78687,
    1474.42356,
    1475.0604,
    1475.69736,
    1476.33447,
    1476.97171,
    1477.6091,
    1478.24661,
    1478.88427,
    1479.52206,
    1480.16,
    1480.79806,
    1481.43627,
    1482.07461,
    1482.7131,
    1483.35171,
    1483.99047,
    1484.62936,
    1485.2684,
    1485.90756,
    1486.54687,
    1487.18631,
    1487.8259,
    1488.46561,
    1489.10547,
    1489.74546,
    1490.3856,
    1491.02586,
    1491.66627,
    1492.30681,
    1492.9475,
    1493.58831,
    1494.22927,
    1494.87036,
    1495.5116,
    1496.15296,
    1496.79447,
    1497.43611,
    1498.0779,
    1498.71981,
    1499.36187,
    1500.00406,
    1500.6464,
    1501.28886,
    1501.93147,
    1502.57421,
    1503.2171,
    1503.86011,
    1504.50327,
    1505.14656,
    1505.79,
    1506.43356,
    1507.07727,
    1507.72111,
    1508.3651,
    1509.00921,
    1509.65347,
    1510.29786,
    1510.9424,
    1511.58706,
    1512.23187,
    1512.87681,
    1513.5219,
    1514.16711,
    1514.81247,
    1515.45796,
    1516.1036,
    1516.74936,
    1517.39527,
    1518.04131,
    1518.6875,
    1519.33381,
    1519.98027,
    1520.62686,
    1521.2736,
    1521.92046,
    1522.56747,
    1523.21461,
    1523.8619,
    1524.50931,
    1525.15687,
    1525.80456,
    1526.4524,
    1527.10036,
    1527.74847,
    1528.39671,
    1529.0451,
    1529.69361,
    1530.34227,
    1530.99106,
    1531.64,
    1532.28906,
    1532.93827,
    1533.58761,
    1534.2371,
    1534.88671,
    1535.53647,
    1536.18636,
    1536.8364,
    1537.48656,
    1538.13687,
    1538.78731,
    1539.4379,
    1540.08861,
    1540.73947,
    1541.39046,
    1542.0416,
    1542.69286,
    1543.34427,
    1543.99581,
    1544.6475,
    1545.29931,
    1545.95127,
    1546.60336,
    1547.2556,
    1547.90796,
    1548.56047,
    1549.21311,
    1549.8659,
    1550.51881,
    1551.17187,
    1551.82506,
    1552.4784,
    1553.13186,
    1553.78547,
    1554.43921,
    1555.0931,
    1555.74711,
    1556.40127,
    1557.05556,
    1557.71,
    1558.36456,
    1559.01927,
    1559.67411,
    1560.3291,
    1560.98421,
    1561.63947,
    1562.29486,
    1562.9504,
    1563.60606,
    1564.26187,
    1564.91781,
    1565.5739,
    1566.23011,
    1566.88647,
    1567.54296,
    1568.1996,
    1568.85636,
    1569.51327,
    1570.17031,
    1570.8275,
    1571.48481,
    1572.14227,
    1572.79986,
    1573.4576,
    1574.11546,
    1574.77347,
    1575.43161,
    1576.0899,
    1576.74831,
    1577.40687,
    1578.06556,
    1578.7244,
    1579.38336,
    1580.04247,
    1580.70171,
    1581.3611,
    1582.02061,
    1582.68027,
    1583.34006,
    1584,
    1584.66006,
    1585.32027,
    1585.98061,
    1586.6411,
    1587.30171,
    1587.96247,
    1588.62336,
    1589.2844,
    1589.94556,
    1590.60687,
    1591.26831,
    1591.9299,
    1592.59161,
    1593.25347,
    1593.91546,
    1594.5776,
    1595.23986,
    1595.90227,
    1596.56481,
    1597.2275,
    1597.89031,
    1598.55327,
    1599.21636,
    1599.8796,
    1600.54296,
    1601.20647,
    1601.87011,
    1602.5339,
    1603.19781,
    1603.86187,
    1604.52606,
    1605.1904,
    1605.85486,
    1606.51947,
    1607.18421,
    1607.8491,
    1608.51411,
    1609.17927,
    1609.84456,
    1610.51,
    1611.17556,
    1611.84127,
    1612.50711,
    1613.1731,
    1613.83921,
    1614.50547,
    1615.17186,
    1615.8384,
    1616.50506,
    1617.17187,
    1617.83881,
    1618.5059,
    1619.17311,
    1619.84047,
    1620.50796,
    1621.1756,
    1621.84336,
    1622.51127,
    1623.17931,
    1623.8475,
    1624.51581,
    1625.18427,
    1625.85286,
    1626.5216,
    1627.19046,
    1627.85947,
    1628.52861,
    1629.1979,
    1629.86731,
    1630.53687,
    1631.20656,
    1631.8764,
    1632.54636,
    1633.21647,
    1633.88671,
    1634.5571,
    1635.22761,
    1635.89827,
    1636.56906,
    1637.24,
    1637.91106,
    1638.58227,
    1639.25361,
    1639.9251,
    1640.59671,
    1641.26847,
    1641.94036,
    1642.6124,
    1643.28456,
    1643.95687,
    1644.62931,
    1645.3019,
    1645.97461,
    1646.64747,
    1647.32046,
    1647.9936,
    1648.66686,
    1649.34027,
    1650.01381,
    1650.6875,
    1651.36131,
    1652.03527,
    1652.70936,
    1653.3836,
    1654.05796,
    1654.73247,
    1655.40711,
    1656.0819,
    1656.75681,
    1657.43187,
    1658.10706,
    1658.7824,
    1659.45786,
    1660.13347,
    1660.80921,
    1661.4851,
    1662.16111,
    1662.83727,
    1663.51356,
    1664.19,
    1664.86656,
    1665.54327,
    1666.22011,
    1666.8971,
    1667.57421,
    1668.25147,
    1668.92886,
    1669.6064,
    1670.28406,
    1670.96187,
    1671.63981,
    1672.3179,
    1672.99611,
    1673.67447,
    1674.35296,
    1675.0316,
    1675.71036,
    1676.38927,
    1677.06831,
    1677.7475,
    1678.42681,
    1679.10627,
    1679.78586,
    1680.4656,
    1681.14546,
    1681.82547,
    1682.50561,
    1683.1859,
    1683.86631,
    1684.54687,
    1685.22756,
    1685.9084,
    1686.58936,
    1687.27047,
    1687.95171,
    1688.6331,
    1689.31461,
    1689.99627,
    1690.67806,
    1691.36,
    1692.04206,
    1692.72427,
    1693.40661,
    1694.0891,
    1694.77171,
    1695.45447,
    1696.13736,
    1696.8204,
    1697.50356,
    1698.18687,
    1698.87031,
    1699.5539,
    1700.23761,
    1700.92147,
    1701.60546,
    1702.2896,
    1702.97386,
    1703.65827,
    1704.34281,
    1705.0275,
    1705.71231,
    1706.39727,
    1707.08236,
    1707.7676,
    1708.45296,
    1709.13847,
    1709.82411,
    1710.5099,
    1711.19581,
    1711.88187,
    1712.56806,
    1713.2544,
    1713.94086,
    1714.62747,
    1715.31421,
    1716.0011,
    1716.68811,
    1717.37527,
    1718.06256,
    1718.75,
    1719.43756,
    1720.12527,
    1720.81311,
    1721.5011,
    1722.18921,
    1722.87747,
    1723.56586,
    1724.2544,
    1724.94306,
    1725.63187,
    1726.32081,
    1727.0099,
    1727.69911,
    1728.38847,
    1729.07796,
    1729.7676,
    1730.45736,
    1731.14727,
    1731.83731,
    1732.5275,
    1733.21781,
    1733.90827,
    1734.59886,
    1735.2896,
    1735.98046,
    1736.67147,
    1737.36261,
    1738.0539,
    1738.74531,
    1739.43687,
    1740.12856,
    1740.8204,
    1741.51236,
    1742.20447,
    1742.89671,
    1743.5891,
    1744.28161,
    1744.97427,
    1745.66706,
    1746.36,
    1747.05306,
    1747.74627,
    1748.43961,
    1749.1331,
    1749.82671,
    1750.52047,
    1751.21436,
    1751.9084,
    1752.60256,
    1753.29687,
    1753.99131,
    1754.6859,
    1755.38061,
    1756.07547,
    1756.77046,
    1757.4656,
    1758.16086,
    1758.85627,
    1759.55181,
    1760.2475,
    1760.94331,
    1761.63927,
    1762.33536,
    1763.0316,
    1763.72796,
    1764.42447,
    1765.12111,
    1765.8179,
    1766.51481,
    1767.21187,
    1767.90906,
    1768.6064,
    1769.30386,
    1770.00147,
    1770.69921,
    1771.3971,
    1772.09511,
    1772.79327,
    1773.49156,
    1774.19,
    1774.88856,
    1775.58727,
    1776.28611,
    1776.9851,
    1777.68421,
    1778.38347,
    1779.08286,
    1779.7824,
    1780.48206,
    1781.18187,
    1781.88181,
    1782.5819,
    1783.28211,
    1783.98247,
    1784.68296,
    1785.3836,
    1786.08436,
    1786.78527,
    1787.48631,
    1788.1875,
    1788.88881,
    1789.59027,
    1790.29186,
    1790.9936,
    1791.69546,
    1792.39747,
    1793.09961,
    1793.8019,
    1794.50431,
    1795.20687,
    1795.90956,
    1796.6124,
    1797.31536,
    1798.01847,
    1798.72171,
    1799.4251,
    1800.12861,
    1800.83227,
    1801.53606,
    1802.24,
    1802.94406,
    1803.64827,
    1804.35261,
    1805.0571,
    1805.76171,
    1806.46647,
    1807.17136,
    1807.8764,
    1808.58156,
    1809.28687,
    1809.99231,
    1810.6979,
    1811.40361,
    1812.10947,
    1812.81546,
    1813.5216,
    1814.22786,
    1814.93427,
    1815.64081,
    1816.3475,
    1817.05431,
    1817.76127,
    1818.46836,
    1819.1756,
    1819.88296,
    1820.59047,
    1821.29811,
    1822.0059,
    1822.71381,
    1823.42187,
    1824.13006,
    1824.8384,
    1825.54686,
    1826.25547,
    1826.96421,
    1827.6731,
    1828.38211,
    1829.09127,
    1829.80056,
    1830.51,
    1831.21956,
    1831.92927,
    1832.63911,
    1833.3491,
    1834.05921,
    1834.76947,
    1835.47986,
    1836.1904,
    1836.90106,
    1837.61187,
    1838.32281,
    1839.0339,
    1839.74511,
    1840.45647,
    1841.16796,
    1841.8796,
    1842.59136,
    1843.30327,
    1844.01531,
    1844.7275,
    1845.43981,
    1846.15227,
    1846.86486,
    1847.5776,
    1848.29046,
    1849.00347,
    1849.71661,
    1850.4299,
    1851.14331,
    1851.85687,
    1852.57056,
    1853.2844,
    1853.99836,
    1854.71247,
    1855.42671,
    1856.1411,
    1856.85561,
    1857.57027,
    1858.28506,
    1859,
    1859.71506,
    1860.43027,
    1861.14561,
    1861.8611,
    1862.57671,
    1863.29247,
    1864.00836,
    1864.7244,
    1865.44056,
    1866.15687,
    1866.87331,
    1867.5899,
    1868.30661,
    1869.02347,
    1869.74046,
    1870.4576,
    1871.17486,
    1871.89227,
    1872.60981,
    1873.3275,
    1874.04531,
    1874.76327,
    1875.48136,
    1876.1996,
    1876.91796,
    1877.63647,
    1878.35511,
    1879.0739,
    1879.79281,
    1880.51187,
    1881.23106,
    1881.9504,
    1882.66986,
    1883.38947,
    1884.10921,
    1884.8291,
    1885.54911,
    1886.26927,
    1886.98956,
    1887.71,
    1888.43056,
    1889.15127,
    1889.87211,
    1890.5931,
    1891.31421,
    1892.03547,
    1892.75686,
    1893.4784,
    1894.20006,
    1894.92187,
    1895.64381,
    1896.3659,
    1897.08811,
    1897.81047,
    1898.53296,
    1899.2556,
    1899.97836,
    1900.70127,
    1901.42431,
    1902.1475,
    1902.87081,
    1903.59427,
    1904.31786,
    1905.0416,
    1905.76546,
    1906.48947,
    1907.21361,
    1907.9379,
    1908.66231,
    1909.38687,
    1910.11156,
    1910.8364,
    1911.56136,
    1912.28647,
    1913.01171,
    1913.7371,
    1914.46261,
    1915.18827,
    1915.91406,
    1916.64,
    1917.36606,
    1918.09227,
    1918.81861,
    1919.5451,
    1920.27171,
    1920.99847,
    1921.72536,
    1922.4524,
    1923.17956,
    1923.90687,
    1924.63431,
    1925.3619,
    1926.08961,
    1926.81747,
    1927.54546,
    1928.2736,
    1929.00186,
    1929.73027,
    1930.45881,
    1931.1875,
    1931.91631,
    1932.64527,
    1933.37436,
    1934.1036,
    1934.83296,
    1935.56247,
    1936.29211,
    1937.0219,
    1937.75181,
    1938.48187,
    1939.21206,
    1939.9424,
    1940.67286,
    1941.40347,
    1942.13421,
    1942.8651,
    1943.59611,
    1944.32727,
    1945.05856,
    1945.79,
    1946.52156,
    1947.25327,
    1947.98511,
    1948.7171,
    1949.44921,
    1950.18147,
    1950.91386,
    1951.6464,
    1952.37906,
    1953.11187,
    1953.84481,
    1954.5779,
    1955.31111,
    1956.04447,
    1956.77796,
    1957.5116,
    1958.24536,
    1958.97927,
    1959.71331,
    1960.4475,
    1961.18181,
    1961.91627,
    1962.65086,
    1963.3856,
    1964.12046,
    1964.85547,
    1965.59061,
    1966.3259,
    1967.06131,
    1967.79687,
    1968.53256,
    1969.2684,
    1970.00436,
    1970.74047,
    1971.47671,
    1972.2131,
    1972.94961,
    1973.68627,
    1974.42306,
    1975.16,
    1975.89706,
    1976.63427,
    1977.37161,
    1978.1091,
    1978.84671,
    1979.58447,
    1980.32236,
    1981.0604,
    1981.79856,
    1982.53687,
    1983.27531,
    1984.0139,
    1984.75261,
    1985.49147,
    1986.23046,
    1986.9696,
    1987.70886,
    1988.44827,
    1989.18781,
    1989.9275,
    1990.66731,
    1991.40727,
    1992.14736,
    1992.8876,
    1993.62796,
    1994.36847,
    1995.10911,
    1995.8499,
    1996.59081,
    1997.33187,
    1998.07306,
    1998.8144,
    1999.55586,
    2000.29747,
    2001.03921,
    2001.7811,
    2002.52311,
    2003.26527,
    2004.00756,
    2004.75,
    2005.49256,
    2006.23527,
    2006.97811,
    2007.7211,
    2008.46421,
    2009.20747,
    2009.95086,
    2010.6944,
    2011.43806,
    2012.18187,
    2012.92581,
    2013.6699,
    2014.41411,
    2015.15847,
    2015.90296,
    2016.6476,
    2017.39236,
    2018.13727,
    2018.88231,
    2019.6275,
    2020.37281,
    2021.11827,
    2021.86386,
    2022.6096,
    2023.35546,
    2024.10147,
    2024.84761,
    2025.5939,
    2026.34031,
    2027.08687,
    2027.83356,
    2028.5804,
    2029.32736,
    2030.07447,
    2030.82171,
    2031.5691,
    2032.31661,
    2033.06427,
    2033.81206,
    2034.56,
    2035.30806,
    2036.05627,
    2036.80461,
    2037.5531,
    2038.30171,
    2039.05047,
    2039.79936,
    2040.5484,
    2041.29756,
    2042.04687,
    2042.79631,
    2043.5459,
    2044.29561,
    2045.04547,
    2045.79546,
    2046.5456,
    2047.29586,
    2048.04627,
    2048.79681,
    2049.5475,
    2050.29831,
    2051.04927,
    2051.80036,
    2052.5516,
    2053.30296,
    2054.05447,
    2054.80611,
    2055.5579,
    2056.30981,
    2057.06187,
    2057.81406,
    2058.5664,
    2059.31886,
    2060.07147,
    2060.82421,
    2061.5771,
    2062.33011,
    2063.08327,
    2063.83656,
    2064.59,
    2065.34356,
    2066.09727,
    2066.85111,
    2067.6051,
    2068.35921,
    2069.11347,
    2069.86786,
    2070.6224,
    2071.37706,
    2072.13187,
    2072.88681,
    2073.6419,
    2074.39711,
    2075.15247,
    2075.90796,
    2076.6636,
    2077.41936,
    2078.17527,
    2078.93131,
    2079.6875,
    2080.44381,
    2081.20027,
    2081.95686,
    2082.7136,
    2083.47046,
    2084.22747,
    2084.98461,
    2085.7419,
    2086.49931,
    2087.25687,
    2088.01456,
    2088.7724,
    2089.53036,
    2090.28847,
    2091.04671,
    2091.8051,
    2092.56361,
    2093.32227,
    2094.08106,
    2094.84,
    2095.59906,
    2096.35827,
    2097.11761,
    2097.8771,
    2098.63671,
    2099.39647,
    2100.15636,
    2100.9164,
    2101.67656,
    2102.43687,
    2103.19731,
    2103.9579,
    2104.71861,
    2105.47947,
    2106.24046,
    2107.0016,
    2107.76286,
    2108.52427,
    2109.28581,
    2110.0475,
    2110.80931,
    2111.57127,
    2112.33336,
    2113.0956,
    2113.85796,
    2114.62047,
    2115.38311,
    2116.1459,
    2116.90881,
    2117.67187,
    2118.43506,
    2119.1984,
    2119.96186,
    2120.72547,
    2121.48921,
    2122.2531,
    2123.01711,
    2123.78127,
    2124.54556,
    2125.31,
    2126.07456,
    2126.83927,
    2127.60411,
    2128.3691,
    2129.13421,
    2129.89947,
    2130.66486,
    2131.4304,
    2132.19606,
    2132.96187,
    2133.72781,
    2134.4939,
    2135.26011,
    2136.02647,
    2136.79296,
    2137.5596,
    2138.32636,
    2139.09327,
    2139.86031,
    2140.6275,
    2141.39481,
    2142.16227,
    2142.92986,
    2143.6976,
    2144.46546,
    2145.23347,
    2146.00161,
    2146.7699,
    2147.53831,
    2148.30687,
    2149.07556,
    2149.8444,
    2150.61336,
    2151.38247,
    2152.15171,
    2152.9211,
    2153.69061,
    2154.46027,
    2155.23006,
    2156,
    2156.77006,
    2157.54027,
    2158.31061,
    2159.0811,
    2159.85171,
    2160.62247,
    2161.39336,
    2162.1644,
    2162.93556,
    2163.70687,
    2164.47831,
    2165.2499,
    2166.02161,
    2166.79347,
    2167.56546,
    2168.3376,
    2169.10986,
    2169.88227,
    2170.65481,
    2171.4275,
    2172.20031,
    2172.97327,
    2173.74636,
    2174.5196,
    2175.29296,
    2176.06647,
    2176.84011,
    2177.6139,
    2178.38781,
    2179.16187,
    2179.93606,
    2180.7104,
    2181.48486,
    2182.25947,
    2183.03421,
    2183.8091,
    2184.58411,
    2185.35927,
    2186.13456,
    2186.91,
    2187.68556,
    2188.46127,
    2189.23711,
    2190.0131,
    2190.78921,
    2191.56547,
    2192.34186,
    2193.1184,
    2193.89506,
    2194.67187,
    2195.44881,
    2196.2259,
    2197.00311,
    2197.78047,
    2198.55796,
    2199.3356,
    2200.11336,
    2200.89127,
    2201.66931,
    2202.4475,
    2203.22581,
    2204.00427,
    2204.78286,
    2205.5616,
    2206.34046,
    2207.11947,
    2207.89861,
    2208.6779,
    2209.45731,
    2210.23687,
    2211.01656,
    2211.7964,
    2212.57636,
    2213.35647,
    2214.13671,
    2214.9171,
    2215.69761,
    2216.47827,
    2217.25906,
    2218.04,
    2218.82106,
    2219.60227,
    2220.38361,
    2221.1651,
    2221.94671,
    2222.72847,
    2223.51036,
    2224.2924,
    2225.07456,
    2225.85687,
    2226.63931,
    2227.4219,
    2228.20461,
    2228.98747,
    2229.77046,
    2230.5536,
    2231.33686,
    2232.12027,
    2232.90381,
    2233.6875,
    2234.47131,
    2235.25527,
    2236.03936,
    2236.8236,
    2237.60796,
    2238.39247,
    2239.17711,
    2239.9619,
    2240.74681,
    2241.53187,
    2242.31706,
    2243.1024,
    2243.88786,
    2244.67347,
    2245.45921,
    2246.2451,
    2247.03111,
    2247.81727,
    2248.60356,
    2249.39,
    2250.17656,
    2250.96327,
    2251.75011,
    2252.5371,
    2253.32421,
    2254.11147,
    2254.89886,
    2255.6864,
    2256.47406,
    2257.26187,
    2258.04981,
    2258.8379,
    2259.62611,
    2260.41447,
    2261.20296,
    2261.9916,
    2262.78036,
    2263.56927,
    2264.35831,
    2265.1475,
    2265.93681,
    2266.72627,
    2267.51586,
    2268.3056,
    2269.09546,
    2269.88547,
    2270.67561,
    2271.4659,
    2272.25631,
    2273.04687,
    2273.83756,
    2274.6284,
    2275.41936,
    2276.21047,
    2277.00171,
    2277.7931,
    2278.58461,
    2279.37627,
    2280.16806,
    2280.96,
    2281.75206,
    2282.54427,
    2283.33661,
    2284.1291,
    2284.92171,
    2285.71447,
    2286.50736,
    2287.3004,
    2288.09356,
    2288.88687,
    2289.68031,
    2290.4739,
    2291.26761,
    2292.06147,
    2292.85546,
    2293.6496,
    2294.44386,
    2295.23827,
    2296.03281,
    2296.8275,
    2297.62231,
    2298.41727,
    2299.21236,
    2300.0076,
    2300.80296,
    2301.59847,
    2302.39411,
    2303.1899,
    2303.98581,
    2304.78187,
    2305.57806,
    2306.3744,
    2307.17086,
    2307.96747,
    2308.76421,
    2309.5611,
    2310.35811,
    2311.15527,
    2311.95256,
    2312.75,
    2313.54756,
    2314.34527,
    2315.14311,
    2315.9411,
    2316.73921,
    2317.53747,
    2318.33586,
    2319.1344,
    2319.93306,
    2320.73187,
    2321.53081,
    2322.3299,
    2323.12911,
    2323.92847,
    2324.72796,
    2325.5276,
    2326.32736,
    2327.12727,
    2327.92731,
    2328.7275,
    2329.52781,
    2330.32827,
    2331.12886,
    2331.9296,
    2332.73046,
    2333.53147,
    2334.33261,
    2335.1339,
    2335.93531,
    2336.73687,
    2337.53856,
    2338.3404,
    2339.14236,
    2339.94447,
    2340.74671,
    2341.5491,
    2342.35161,
    2343.15427,
    2343.95706,
    2344.76,
    2345.56306,
    2346.36627,
    2347.16961,
    2347.9731,
    2348.77671,
    2349.58047,
    2350.38436,
    2351.1884,
    2351.99256,
    2352.79687,
    2353.60131,
    2354.4059,
    2355.21061,
    2356.01547,
    2356.82046,
    2357.6256,
    2358.43086,
    2359.23627,
    2360.04181,
    2360.8475,
    2361.65331,
    2362.45927,
    2363.26536,
    2364.0716,
    2364.87796,
    2365.68447,
    2366.49111,
    2367.2979,
    2368.10481,
    2368.91187,
    2369.71906,
    2370.5264,
    2371.33386,
    2372.14147,
    2372.94921,
    2373.7571,
    2374.56511,
    2375.37327,
    2376.18156,
    2376.99,
    2377.79856,
    2378.60727,
    2379.41611,
    2380.2251,
    2381.03421,
    2381.84347,
    2382.65286,
    2383.4624,
    2384.27206,
    2385.08187,
    2385.89181,
    2386.7019,
    2387.51211,
    2388.32247,
    2389.13296,
    2389.9436,
    2390.75436,
    2391.56527,
    2392.37631,
    2393.1875,
    2393.99881,
    2394.81027,
    2395.62186,
    2396.4336,
    2397.24546,
    2398.05747,
    2398.86961,
    2399.6819,
    2400.49431,
    2401.30687,
    2402.11956,
    2402.9324,
    2403.74536,
    2404.55847,
    2405.37171,
    2406.1851,
    2406.99861,
    2407.81227,
    2408.62606,
    2409.44,
    2410.25406,
    2411.06827,
    2411.88261,
    2412.6971,
    2413.51171,
    2414.32647,
    2415.14136,
    2415.9564,
    2416.77156,
    2417.58687,
    2418.40231,
    2419.2179,
    2420.03361,
    2420.84947,
    2421.66546,
    2422.4816,
    2423.29786,
    2424.11427,
    2424.93081,
    2425.7475,
    2426.56431,
    2427.38127,
    2428.19836,
    2429.0156,
    2429.83296,
    2430.65047,
    2431.46811,
    2432.2859,
    2433.10381,
    2433.92187,
    2434.74006,
    2435.5584,
    2436.37686,
    2437.19547,
    2438.01421,
    2438.8331,
    2439.65211,
    2440.47127,
    2441.29056,
    2442.11,
    2442.92956,
    2443.74927,
    2444.56911,
    2445.3891,
    2446.20921,
    2447.02947,
    2447.84986,
    2448.6704,
    2449.49106,
    2450.31187,
    2451.13281,
    2451.9539,
    2452.77511,
    2453.59647,
    2454.41796,
    2455.2396,
    2456.06136,
    2456.88327,
    2457.70531,
    2458.5275,
    2459.34981,
    2460.17227,
    2460.99486,
    2461.8176,
    2462.64046,
    2463.46347,
    2464.28661,
    2465.1099,
    2465.93331,
    2466.75687,
    2467.58056,
    2468.4044,
    2469.22836,
    2470.05247,
    2470.87671,
    2471.7011,
    2472.52561,
    2473.35027,
    2474.17506,
    2475,
    2475.82506,
    2476.65027,
    2477.47561,
    2478.3011,
    2479.12671,
    2479.95247,
    2480.77836,
    2481.6044,
    2482.43056,
    2483.25687,
    2484.08331,
    2484.9099,
    2485.73661,
    2486.56347,
    2487.39046,
    2488.2176,
    2489.04486,
    2489.87227,
    2490.69981,
    2491.5275,
    2492.35531,
    2493.18327,
    2494.01136,
    2494.8396,
    2495.66796,
    2496.49647,
    2497.32511,
    2498.1539,
    2498.98281,
    2499.81187,
    2500.64106,
    2501.4704,
    2502.29986,
    2503.12947,
    2503.95921,
    2504.7891,
    2505.61911,
    2506.44927,
    2507.27956,
    2508.11,
    2508.94056,
    2509.77127,
    2510.60211,
    2511.4331,
    2512.26421,
    2513.09547,
    2513.92686,
    2514.7584,
    2515.59006,
    2516.42187,
    2517.25381,
    2518.0859,
    2518.91811,
    2519.75047,
    2520.58296,
    2521.4156,
    2522.24836,
    2523.08127,
    2523.91431,
    2524.7475,
    2525.58081,
    2526.41427,
    2527.24786,
    2528.0816,
    2528.91546,
    2529.74947,
    2530.58361,
    2531.4179,
    2532.25231,
    2533.08687,
    2533.92156,
    2534.7564,
    2535.59136,
    2536.42647,
    2537.26171,
    2538.0971,
    2538.93261,
    2539.76827,
    2540.60406,
    2541.44,
    2542.27606,
    2543.11227,
    2543.94861,
    2544.7851,
    2545.62171,
    2546.45847,
    2547.29536,
    2548.1324,
    2548.96956,
    2549.80687,
    2550.64431,
    2551.4819,
    2552.31961,
    2553.15747,
    2553.99546,
    2554.8336,
    2555.67186,
    2556.51027,
    2557.34881,
    2558.1875,
    2559.02631,
    2559.86527,
    2560.70436,
    2561.5436,
    2562.38296,
    2563.22247,
    2564.06211,
    2564.9019,
    2565.74181,
    2566.58187,
    2567.42206,
    2568.2624,
    2569.10286,
    2569.94347,
    2570.78421,
    2571.6251,
    2572.46611,
    2573.30727,
    2574.14856,
    2574.99,
    2575.83156,
    2576.67327,
    2577.51511,
    2578.3571,
    2579.19921,
    2580.04147,
    2580.88386,
    2581.7264,
    2582.56906,
    2583.41187,
    2584.25481,
    2585.0979,
    2585.94111,
    2586.78447,
    2587.62796,
    2588.4716,
    2589.31536,
    2590.15927,
    2591.00331,
    2591.8475,
    2592.69181,
    2593.53627,
    2594.38086,
    2595.2256,
    2596.07046,
    2596.91547,
    2597.76061,
    2598.6059,
    2599.45131,
    2600.29687,
    2601.14256,
    2601.9884,
    2602.83436,
    2603.68047,
    2604.52671,
    2605.3731,
    2606.21961,
    2607.06627,
    2607.91306,
    2608.76,
    2609.60706,
    2610.45427,
    2611.30161,
    2612.1491,
    2612.99671,
    2613.84447,
    2614.69236,
    2615.5404,
    2616.38856,
    2617.23687,
    2618.08531,
    2618.9339,
    2619.78261,
    2620.63147,
    2621.48046,
    2622.3296,
    2623.17886,
    2624.02827,
    2624.87781,
    2625.7275,
    2626.57731,
    2627.42727,
    2628.27736,
    2629.1276,
    2629.97796,
    2630.82847,
    2631.67911,
    2632.5299,
    2633.38081,
    2634.23187,
    2635.08306,
    2635.9344,
    2636.78586,
    2637.63747,
    2638.48921,
    2639.3411,
    2640.19311,
    2641.04527,
    2641.89756,
    2642.75,
    2643.60256,
    2644.45527,
    2645.30811,
    2646.1611,
    2647.01421,
    2647.86747,
    2648.72086,
    2649.5744,
    2650.42806,
    2651.28187,
    2652.13581,
    2652.9899,
    2653.84411,
    2654.69847,
    2655.55296,
    2656.4076,
    2657.26236,
    2658.11727,
    2658.97231,
    2659.8275,
    2660.68281,
    2661.53827,
    2662.39386,
    2663.2496,
    2664.10546,
    2664.96147,
    2665.81761,
    2666.6739,
    2667.53031,
    2668.38687,
    2669.24356,
    2670.1004,
    2670.95736,
    2671.81447,
    2672.67171,
    2673.5291,
    2674.38661,
    2675.24427,
    2676.10206,
    2676.96,
    2677.81806,
    2678.67627,
    2679.53461,
    2680.3931,
    2681.25171,
    2682.11047,
    2682.96936,
    2683.8284,
    2684.68756,
    2685.54687,
    2686.40631,
    2687.2659,
    2688.12561,
    2688.98547,
    2689.84546,
    2690.7056,
    2691.56586,
    2692.42627,
    2693.28681,
    2694.1475,
    2695.00831,
    2695.86927,
    2696.73036,
    2697.5916,
    2698.45296,
    2699.31447,
    2700.17611,
    2701.0379,
    2701.89981,
    2702.76187,
    2703.62406,
    2704.4864,
    2705.34886,
    2706.21147,
    2707.07421,
    2707.9371,
    2708.80011,
    2709.66327,
    2710.52656,
    2711.39,
    2712.25356,
    2713.11727,
    2713.98111,
    2714.8451,
    2715.70921,
    2716.57347,
    2717.43786,
    2718.3024,
    2719.16706,
    2720.03187,
    2720.89681,
    2721.7619,
    2722.62711,
    2723.49247,
    2724.35796,
    2725.2236,
    2726.08936,
    2726.95527,
    2727.82131,
    2728.6875,
    2729.55381,
    2730.42027,
    2731.28686,
    2732.1536,
    2733.02046,
    2733.88747,
    2734.75461,
    2735.6219,
    2736.48931,
    2737.35687,
    2738.22456,
    2739.0924,
    2739.96036,
    2740.82847,
    2741.69671,
    2742.5651,
    2743.43361,
    2744.30227,
    2745.17106,
    2746.04,
    2746.90906,
    2747.77827,
    2748.64761,
    2749.5171,
    2750.38671,
    2751.25647,
    2752.12636,
    2752.9964,
    2753.86656,
    2754.73687,
    2755.60731,
    2756.4779,
    2757.34861,
    2758.21947,
    2759.09046,
    2759.9616,
    2760.83286,
    2761.70427,
    2762.57581,
    2763.4475,
    2764.31931,
    2765.19127,
    2766.06336,
    2766.9356,
    2767.80796,
    2768.68047,
    2769.55311,
    2770.4259,
    2771.29881,
    2772.17187,
    2773.04506,
    2773.9184,
    2774.79186,
    2775.66547,
    2776.53921,
    2777.4131,
    2778.28711,
    2779.16127,
    2780.03556,
    2780.91,
    2781.78456,
    2782.65927,
    2783.53411,
    2784.4091,
    2785.28421,
    2786.15947,
    2787.03486,
    2787.9104,
    2788.78606,
    2789.66187,
    2790.53781,
    2791.4139,
    2792.29011,
    2793.16647,
    2794.04296,
    2794.9196,
    2795.79636,
    2796.67327,
    2797.55031,
    2798.4275,
    2799.30481,
    2800.18227,
    2801.05986,
    2801.9376,
    2802.81546,
    2803.69347,
    2804.57161,
    2805.4499,
    2806.32831,
    2807.20687,
    2808.08556,
    2808.9644,
    2809.84336,
    2810.72247,
    2811.60171,
    2812.4811,
    2813.36061,
    2814.24027,
    2815.12006,
    2816,
    2816.88006,
    2817.76027,
    2818.64061,
    2819.5211,
    2820.40171,
    2821.28247,
    2822.16336,
    2823.0444,
    2823.92556,
    2824.80687,
    2825.68831,
    2826.5699,
    2827.45161,
    2828.33347,
    2829.21546,
    2830.0976,
    2830.97986,
    2831.86227,
    2832.74481,
    2833.6275,
    2834.51031,
    2835.39327,
    2836.27636,
    2837.1596,
    2838.04296,
    2838.92647,
    2839.81011,
    2840.6939,
    2841.57781,
    2842.46187,
    2843.34606,
    2844.2304,
    2845.11486,
    2845.99947,
    2846.88421,
    2847.7691,
    2848.65411,
    2849.53927,
    2850.42456,
    2851.31,
    2852.19556,
    2853.08127,
    2853.96711,
    2854.8531,
    2855.73921,
    2856.62547,
    2857.51186,
    2858.3984,
    2859.28506,
    2860.17187,
    2861.05881,
    2861.9459,
    2862.83311,
    2863.72047,
    2864.60796,
    2865.4956,
    2866.38336,
    2867.27127,
    2868.15931,
    2869.0475,
    2869.93581,
    2870.82427,
    2871.71286,
    2872.6016,
    2873.49046,
    2874.37947,
    2875.26861,
    2876.1579,
    2877.04731,
    2877.93687,
    2878.82656,
    2879.7164,
    2880.60636,
    2881.49647,
    2882.38671,
    2883.2771,
    2884.16761,
    2885.05827,
    2885.94906,
    2886.84,
    2887.73106,
    2888.62227,
    2889.51361,
    2890.4051,
    2891.29671,
    2892.18847,
    2893.08036,
    2893.9724,
    2894.86456,
    2895.75687,
    2896.64931,
    2897.5419,
    2898.43461,
    2899.32747,
    2900.22046,
    2901.1136,
    2902.00686,
    2902.90027,
    2903.79381,
    2904.6875,
    2905.58131,
    2906.47527,
    2907.36936,
    2908.2636,
    2909.15796,
    2910.05247,
    2910.94711,
    2911.8419,
    2912.73681,
    2913.63187,
    2914.52706,
    2915.4224,
    2916.31786,
    2917.21347,
    2918.10921,
    2919.0051,
    2919.90111,
    2920.79727,
    2921.69356,
    2922.59,
    2923.48656,
    2924.38327,
    2925.28011,
    2926.1771,
    2927.07421,
    2927.97147,
    2928.86886,
    2929.7664,
    2930.66406,
    2931.56187,
    2932.45981,
    2933.3579,
    2934.25611,
    2935.15447,
    2936.05296,
    2936.9516,
    2937.85036,
    2938.74927,
    2939.64831,
    2940.5475,
    2941.44681,
    2942.34627,
    2943.24586,
    2944.1456,
    2945.04546,
    2945.94547,
    2946.84561,
    2947.7459,
    2948.64631,
    2949.54687,
    2950.44756,
    2951.3484,
    2952.24936,
    2953.15047,
    2954.05171,
    2954.9531,
    2955.85461,
    2956.75627,
    2957.65806,
    2958.56,
    2959.46206,
    2960.36427,
    2961.26661,
    2962.1691,
    2963.07171,
    2963.97447,
    2964.87736,
    2965.7804,
    2966.68356,
    2967.58687,
    2968.49031,
    2969.3939,
    2970.29761,
    2971.20147,
    2972.10546,
    2973.0096,
    2973.91386,
    2974.81827,
    2975.72281,
    2976.6275,
    2977.53231,
    2978.43727,
    2979.34236,
    2980.2476,
    2981.15296,
    2982.05847,
    2982.96411,
    2983.8699,
    2984.77581,
    2985.68187,
    2986.58806,
    2987.4944,
    2988.40086,
    2989.30747,
    2990.21421,
    2991.1211,
    2992.02811,
    2992.93527,
    2993.84256,
    2994.75,
    2995.65756,
    2996.56527,
    2997.47311,
    2998.3811,
    2999.28921,
    3000.19747,
    3001.10586,
    3002.0144,
    3002.92306,
    3003.83187,
    3004.74081,
    3005.6499,
    3006.55911,
    3007.46847,
    3008.37796,
    3009.2876,
    3010.19736,
    3011.10727,
    3012.01731,
    3012.9275,
    3013.83781,
    3014.74827,
    3015.65886,
    3016.5696,
    3017.48046,
    3018.39147,
    3019.30261,
    3020.2139,
    3021.12531,
    3022.03687,
    3022.94856,
    3023.8604,
    3024.77236,
    3025.68447,
    3026.59671,
    3027.5091,
    3028.42161,
    3029.33427,
    3030.24706,
    3031.16,
    3032.07306,
    3032.98627,
    3033.89961,
    3034.8131,
    3035.72671,
    3036.64047,
    3037.55436,
    3038.4684,
    3039.38256,
    3040.29687,
    3041.21131,
    3042.1259,
    3043.04061,
    3043.95547,
    3044.87046,
    3045.7856,
    3046.70086,
    3047.61627,
    3048.53181,
    3049.4475,
    3050.36331,
    3051.27927,
    3052.19536,
    3053.1116,
    3054.02796,
    3054.94447,
    3055.86111,
    3056.7779,
    3057.69481,
    3058.61187,
    3059.52906,
    3060.4464,
    3061.36386,
    3062.28147,
    3063.19921,
    3064.1171,
    3065.03511,
    3065.95327,
    3066.87156,
    3067.79,
    3068.70856,
    3069.62727,
    3070.54611,
    3071.4651,
    3072.38421,
    3073.30347,
    3074.22286,
    3075.1424,
    3076.06206,
    3076.98187,
    3077.90181,
    3078.8219,
    3079.74211,
    3080.66247,
    3081.58296,
    3082.5036,
    3083.42436,
    3084.34527,
    3085.26631,
    3086.1875,
    3087.10881,
    3088.03027,
    3088.95186,
    3089.8736,
    3090.79546,
    3091.71747,
    3092.63961,
    3093.5619,
    3094.48431,
    3095.40687,
    3096.32956,
    3097.2524,
    3098.17536,
    3099.09847,
    3100.02171,
    3100.9451,
    3101.86861,
    3102.79227,
    3103.71606,
    3104.64,
    3105.56406,
    3106.48827,
    3107.41261,
    3108.3371,
    3109.26171,
    3110.18647,
    3111.11136,
    3112.0364,
    3112.96156,
    3113.88687,
    3114.81231,
    3115.7379,
    3116.66361,
    3117.58947,
    3118.51546,
    3119.4416,
    3120.36786,
    3121.29427,
    3122.22081,
    3123.1475,
    3124.07431,
    3125.00127,
    3125.92836,
    3126.8556,
    3127.78296,
    3128.71047,
    3129.63811,
    3130.5659,
    3131.49381,
    3132.42187,
    3133.35006,
    3134.2784,
    3135.20686,
    3136.13547,
    3137.06421,
    3137.9931,
    3138.92211,
    3139.85127,
    3140.78056,
    3141.71,
    3142.63956,
    3143.56927,
    3144.49911,
    3145.4291,
    3146.35921,
    3147.28947,
    3148.21986,
    3149.1504,
    3150.08106,
    3151.01187,
    3151.94281,
    3152.8739,
    3153.80511,
    3154.73647,
    3155.66796,
    3156.5996,
    3157.53136,
    3158.46327,
    3159.39531,
    3160.3275,
    3161.25981,
    3162.19227,
    3163.12486,
    3164.0576,
    3164.99046,
    3165.92347,
    3166.85661,
    3167.7899,
    3168.72331,
    3169.65687,
    3170.59056,
    3171.5244,
    3172.45836,
    3173.39247,
    3174.32671,
    3175.2611,
    3176.19561,
    3177.13027,
    3178.06506,
    3179,
    3179.93506,
    3180.87027,
    3181.80561,
    3182.7411,
    3183.67671,
    3184.61247,
    3185.54836,
    3186.4844,
    3187.42056,
    3188.35687,
    3189.29331,
    3190.2299,
    3191.16661,
    3192.10347,
    3193.04046,
    3193.9776,
    3194.91486,
    3195.85227,
    3196.78981,
    3197.7275,
    3198.66531,
    3199.60327,
    3200.54136,
    3201.4796,
    3202.41796,
    3203.35647,
    3204.29511,
    3205.2339,
    3206.17281,
    3207.11187,
    3208.05106,
    3208.9904,
    3209.92986,
    3210.86947,
    3211.80921,
    3212.7491,
    3213.68911,
    3214.62927,
    3215.56956,
    3216.51,
    3217.45056,
    3218.39127,
    3219.33211,
    3220.2731,
    3221.21421,
    3222.15547,
    3223.09686,
    3224.0384,
    3224.98006,
    3225.92187,
    3226.86381,
    3227.8059,
    3228.74811,
    3229.69047,
    3230.63296,
    3231.5756,
    3232.51836,
    3233.46127,
    3234.40431,
    3235.3475,
    3236.29081,
    3237.23427,
    3238.17786,
    3239.1216,
    3240.06546,
    3241.00947,
    3241.95361,
    3242.8979,
    3243.84231,
    3244.78687,
    3245.73156,
    3246.6764,
    3247.62136,
    3248.56647,
    3249.51171,
    3250.4571,
    3251.40261,
    3252.34827,
    3253.29406,
    3254.24,
    3255.18606,
    3256.13227,
    3257.07861,
    3258.0251,
    3258.97171,
    3259.91847,
    3260.86536,
    3261.8124,
    3262.75956,
    3263.70687,
    3264.65431,
    3265.6019,
    3266.54961,
    3267.49747,
    3268.44546,
    3269.3936,
    3270.34186,
    3271.29027,
    3272.23881,
    3273.1875,
    3274.13631,
    3275.08527,
    3276.03436,
    3276.9836,
    3277.93296,
    3278.88247,
    3279.83211,
    3280.7819,
    3281.73181,
    3282.68187,
    3283.63206,
    3284.5824,
    3285.53286,
    3286.48347,
    3287.43421,
    3288.3851,
    3289.33611,
    3290.28727,
    3291.23856,
    3292.19,
    3293.14156,
    3294.09327,
    3295.04511,
    3295.9971,
    3296.94921,
    3297.90147,
    3298.85386,
    3299.8064,
    3300.75906,
    3301.71187,
    3302.66481,
    3303.6179,
    3304.57111,
    3305.52447,
    3306.47796,
    3307.4316,
    3308.38536,
    3309.33927,
    3310.29331,
    3311.2475,
    3312.20181,
    3313.15627,
    3314.11086,
    3315.0656,
    3316.02046,
    3316.97547,
    3317.93061,
    3318.8859,
    3319.84131,
    3320.79687,
    3321.75256,
    3322.7084,
    3323.66436,
    3324.62047,
    3325.57671,
    3326.5331,
    3327.48961,
    3328.44627,
    3329.40306,
    3330.36,
    3331.31706,
    3332.27427,
    3333.23161,
    3334.1891,
    3335.14671,
    3336.10447,
    3337.06236,
    3338.0204,
    3338.97856,
    3339.93687,
    3340.89531,
    3341.8539,
    3342.81261,
    3343.77147,
    3344.73046,
    3345.6896,
    3346.64886,
    3347.60827,
    3348.56781,
    3349.5275,
    3350.48731,
    3351.44727,
    3352.40736,
    3353.3676,
    3354.32796,
    3355.28847,
    3356.24911,
    3357.2099,
    3358.17081,
    3359.13187,
    3360.09306,
    3361.0544,
    3362.01586,
    3362.97747,
    3363.93921,
    3364.9011,
    3365.86311,
    3366.82527,
    3367.78756,
    3368.75,
    3369.71256,
    3370.67527,
    3371.63811,
    3372.6011,
    3373.56421,
    3374.52747,
    3375.49086,
    3376.4544,
    3377.41806,
    3378.38187,
    3379.34581,
    3380.3099,
    3381.27411,
    3382.23847,
    3383.20296,
    3384.1676,
    3385.13236,
    3386.09727,
    3387.06231,
    3388.0275,
    3388.99281,
    3389.95827,
    3390.92386,
    3391.8896,
    3392.85546,
    3393.82147,
    3394.78761,
    3395.7539,
    3396.72031,
    3397.68687,
    3398.65356,
    3399.6204,
    3400.58736,
    3401.55447,
    3402.52171,
    3403.4891,
    3404.45661,
    3405.42427,
    3406.39206,
    3407.36,
    3408.32806,
    3409.29627,
    3410.26461,
    3411.2331,
    3412.20171,
    3413.17047,
    3414.13936,
    3415.1084,
    3416.07756,
    3417.04687,
    3418.01631,
    3418.9859,
    3419.95561,
    3420.92547,
    3421.89546,
    3422.8656,
    3423.83586,
    3424.80627,
    3425.77681,
    3426.7475,
    3427.71831,
    3428.68927,
    3429.66036,
    3430.6316,
    3431.60296,
    3432.57447,
    3433.54611,
    3434.5179,
    3435.48981,
    3436.46187,
    3437.43406,
    3438.4064,
    3439.37886,
    3440.35147,
    3441.32421,
    3442.2971,
    3443.27011,
    3444.24327,
    3445.21656,
    3446.19,
    3447.16356,
    3448.13727,
    3449.11111,
    3450.0851,
    3451.05921,
    3452.03347,
    3453.00786,
    3453.9824,
    3454.95706,
    3455.93187,
    3456.90681,
    3457.8819,
    3458.85711,
    3459.83247,
    3460.80796,
    3461.7836,
    3462.75936,
    3463.73527,
    3464.71131,
    3465.6875,
    3466.66381,
    3467.64027,
    3468.61686,
    3469.5936,
    3470.57046,
    3471.54747,
    3472.52461,
    3473.5019,
    3474.47931,
    3475.45687,
    3476.43456,
    3477.4124,
    3478.39036,
    3479.36847,
    3480.34671,
    3481.3251,
    3482.30361,
    3483.28227,
    3484.26106,
    3485.24,
    3486.21906,
    3487.19827,
    3488.17761,
    3489.1571,
    3490.13671,
    3491.11647,
    3492.09636,
    3493.0764,
    3494.05656,
    3495.03687,
    3496.01731,
    3496.9979,
    3497.97861,
    3498.95947,
    3499.94046,
    3500.9216,
    3501.90286,
    3502.88427,
    3503.86581,
    3504.8475,
    3505.82931,
    3506.81127,
    3507.79336,
    3508.7756,
    3509.75796,
    3510.74047,
    3511.72311,
    3512.7059,
    3513.68881,
    3514.67187,
    3515.65506,
    3516.6384,
    3517.62186,
    3518.60547,
    3519.58921,
    3520.5731,
    3521.55711,
    3522.54127,
    3523.52556,
    3524.51,
    3525.49456,
    3526.47927,
    3527.46411,
    3528.4491,
    3529.43421,
    3530.41947,
    3531.40486,
    3532.3904,
    3533.37606,
    3534.36187,
    3535.34781,
    3536.3339,
    3537.32011,
    3538.30647,
    3539.29296,
    3540.2796,
    3541.26636,
    3542.25327,
    3543.24031,
    3544.2275,
    3545.21481,
    3546.20227,
    3547.18986,
    3548.1776,
    3549.16546,
    3550.15347,
    3551.14161,
    3552.1299,
    3553.11831,
    3554.10687,
    3555.09556,
    3556.0844,
    3557.07336,
    3558.06247,
    3559.05171,
    3560.0411,
    3561.03061,
    3562.02027,
    3563.01006,
    3564,
    3564.99006,
    3565.98027,
    3566.97061,
    3567.9611,
    3568.95171,
    3569.94247,
    3570.93336,
    3571.9244,
    3572.91556,
    3573.90687,
    3574.89831,
    3575.8899,
    3576.88161,
    3577.87347,
    3578.86546,
    3579.8576,
    3580.84986,
    3581.84227,
    3582.83481,
    3583.8275,
    3584.82031,
    3585.81327,
    3586.80636,
    3587.7996,
    3588.79296,
    3589.78647,
    3590.78011,
    3591.7739,
    3592.76781,
    3593.76187,
    3594.75606,
    3595.7504,
    3596.74486,
    3597.73947,
    3598.73421,
    3599.7291,
    3600.72411,
    3601.71927,
    3602.71456,
    3603.71,
    3604.70556,
    3605.70127,
    3606.69711,
    3607.6931,
    3608.68921,
    3609.68547,
    3610.68186,
    3611.6784,
    3612.67506,
    3613.67187,
    3614.66881,
    3615.6659,
    3616.66311,
    3617.66047,
    3618.65796,
    3619.6556,
    3620.65336,
    3621.65127,
    3622.64931,
    3623.6475,
    3624.64581,
    3625.64427,
    3626.64286,
    3627.6416,
    3628.64046,
    3629.63947,
    3630.63861,
    3631.6379,
    3632.63731,
    3633.63687,
    3634.63656,
    3635.6364,
    3636.63636,
    3637.63647,
    3638.63671,
    3639.6371,
    3640.63761,
    3641.63827,
    3642.63906,
    3643.64,
    3644.64106,
    3645.64227,
    3646.64361,
    3647.6451,
    3648.64671,
    3649.64847,
    3650.65036,
    3651.6524,
    3652.65456,
    3653.65687,
    3654.65931,
    3655.6619,
    3656.66461,
    3657.66747,
    3658.67046,
    3659.6736,
    3660.67686,
    3661.68027,
    3662.68381,
    3663.6875,
    3664.69131,
    3665.69527,
    3666.69936,
    3667.7036,
    3668.70796,
    3669.71247,
    3670.71711,
    3671.7219,
    3672.72681,
    3673.73187,
    3674.73706,
    3675.7424,
    3676.74786,
    3677.75347,
    3678.75921,
    3679.7651,
    3680.77111,
    3681.77727,
    3682.78356,
    3683.79,
    3684.79656,
    3685.80327,
    3686.81011,
    3687.8171,
    3688.82421,
    3689.83147,
    3690.83886,
    3691.8464,
    3692.85406,
    3693.86187,
    3694.86981,
    3695.8779,
    3696.88611,
    3697.89447,
    3698.90296,
    3699.9116,
    3700.92036,
    3701.92927,
    3702.93831,
    3703.9475,
    3704.95681,
    3705.96627,
    3706.97586,
    3707.9856,
    3708.99546,
    3710.00547,
    3711.01561,
    3712.0259,
    3713.03631,
    3714.04687,
    3715.05756,
    3716.0684,
    3717.07936,
    3718.09047,
    3719.10171,
    3720.1131,
    3721.12461,
    3722.13627,
    3723.14806,
    3724.16,
    3725.17206,
    3726.18427,
    3727.19661,
    3728.2091,
    3729.22171,
    3730.23447,
    3731.24736,
    3732.2604,
    3733.27356,
    3734.28687,
    3735.30031,
    3736.3139,
    3737.32761,
    3738.34147,
    3739.35546,
    3740.3696,
    3741.38386,
    3742.39827,
    3743.41281,
    3744.4275,
    3745.44231,
    3746.45727,
    3747.47236,
    3748.4876,
    3749.50296,
    3750.51847,
    3751.53411,
    3752.5499,
    3753.56581,
    3754.58187,
    3755.59806,
    3756.6144,
    3757.63086,
    3758.64747,
    3759.66421,
    3760.6811,
    3761.69811,
    3762.71527,
    3763.73256,
    3764.75,
    3765.76756,
    3766.78527,
    3767.80311,
    3768.8211,
    3769.83921,
    3770.85747,
    3771.87586,
    3772.8944,
    3773.91306,
    3774.93187,
    3775.95081,
    3776.9699,
    3777.98911,
    3779.00847,
    3780.02796,
    3781.0476,
    3782.06736,
    3783.08727,
    3784.10731,
    3785.1275,
    3786.14781,
    3787.16827,
    3788.18886,
    3789.2096,
    3790.23046,
    3791.25147,
    3792.27261,
    3793.2939,
    3794.31531,
    3795.33687,
    3796.35856,
    3797.3804,
    3798.40236,
    3799.42447,
    3800.44671,
    3801.4691,
    3802.49161,
    3803.51427,
    3804.53706,
    3805.56,
    3806.58306,
    3807.60627,
    3808.62961,
    3809.6531,
    3810.67671,
    3811.70047,
    3812.72436,
    3813.7484,
    3814.77256,
    3815.79687,
    3816.82131,
    3817.8459,
    3818.87061,
    3819.89547,
    3820.92046,
    3821.9456,
    3822.97086,
    3823.99627,
    3825.02181,
    3826.0475,
    3827.07331,
    3828.09927,
    3829.12536,
    3830.1516,
    3831.17796,
    3832.20447,
    3833.23111,
    3834.2579,
    3835.28481,
    3836.31187,
    3837.33906,
    3838.3664,
    3839.39386,
    3840.42147,
    3841.44921,
    3842.4771,
    3843.50511,
    3844.53327,
    3845.56156,
    3846.59,
    3847.61856,
    3848.64727,
    3849.67611,
    3850.7051,
    3851.73421,
    3852.76347,
    3853.79286,
    3854.8224,
    3855.85206,
    3856.88187,
    3857.91181,
    3858.9419,
    3859.97211,
    3861.00247,
    3862.03296,
    3863.0636,
    3864.09436,
    3865.12527,
    3866.15631,
    3867.1875,
    3868.21881,
    3869.25027,
    3870.28186,
    3871.3136,
    3872.34546,
    3873.37747,
    3874.40961,
    3875.4419,
    3876.47431,
    3877.50687,
    3878.53956,
    3879.5724,
    3880.60536,
    3881.63847,
    3882.67171,
    3883.7051,
    3884.73861,
    3885.77227,
    3886.80606,
    3887.84,
    3888.87406,
    3889.90827,
    3890.94261,
    3891.9771,
    3893.01171,
    3894.04647,
    3895.08136,
    3896.1164,
    3897.15156,
    3898.18687,
    3899.22231,
    3900.2579,
    3901.29361,
    3902.32947,
    3903.36546,
    3904.4016,
    3905.43786,
    3906.47427,
    3907.51081,
    3908.5475,
    3909.58431,
    3910.62127,
    3911.65836,
    3912.6956,
    3913.73296,
    3914.77047,
    3915.80811,
    3916.8459,
    3917.88381,
    3918.92187,
    3919.96006,
    3920.9984,
    3922.03686,
    3923.07547,
    3924.11421,
    3925.1531,
    3926.19211,
    3927.23127,
    3928.27056,
    3929.31,
    3930.34956,
    3931.38927,
    3932.42911,
    3933.4691,
    3934.50921,
    3935.54947,
    3936.58986,
    3937.6304,
    3938.67106,
    3939.71187,
    3940.75281,
    3941.7939,
    3942.83511,
    3943.87647,
    3944.91796,
    3945.9596,
    3947.00136,
    3948.04327,
    3949.08531,
    3950.1275,
    3951.16981,
    3952.21227,
    3953.25486,
    3954.2976,
    3955.34046,
    3956.38347,
    3957.42661,
    3958.4699,
    3959.51331,
    3960.55687,
    3961.60056,
    3962.6444,
    3963.68836,
    3964.73247,
    3965.77671,
    3966.8211,
    3967.86561,
    3968.91027,
    3969.95506,
    3971,
    3972.04506,
    3973.09027,
    3974.13561,
    3975.1811,
    3976.22671,
    3977.27247,
    3978.31836,
    3979.3644,
    3980.41056,
    3981.45687,
    3982.50331,
    3983.5499,
    3984.59661,
    3985.64347,
    3986.69046,
    3987.7376,
    3988.78486,
    3989.83227,
    3990.87981,
    3991.9275,
    3992.97531,
    3994.02327,
    3995.07136,
    3996.1196,
    3997.16796,
    3998.21647,
    3999.26511,
    4000.3139,
    4001.36281,
    4002.41187,
    4003.46106,
    4004.5104,
    4005.55986,
    4006.60947,
    4007.65921,
    4008.7091,
    4009.75911,
    4010.80927,
    4011.85956,
    4012.91,
    4013.96056,
    4015.01127,
    4016.06211,
    4017.1131,
    4018.16421,
    4019.21547,
    4020.26686,
    4021.3184,
    4022.37006,
    4023.42187,
    4024.47381,
    4025.5259,
    4026.57811,
    4027.63047,
    4028.68296,
    4029.7356,
    4030.78836,
    4031.84127,
    4032.89431,
    4033.9475,
    4035.00081,
    4036.05427,
    4037.10786,
    4038.1616,
    4039.21546,
    4040.26947,
    4041.32361,
    4042.3779,
    4043.43231,
    4044.48687,
    4045.54156,
    4046.5964,
    4047.65136,
    4048.70647,
    4049.76171,
    4050.8171,
    4051.87261,
    4052.92827,
    4053.98406,
    4055.04,
    4056.09606,
    4057.15227,
    4058.20861,
    4059.2651,
    4060.32171,
    4061.37847,
    4062.43536,
    4063.4924,
    4064.54956,
    4065.60687,
    4066.66431,
    4067.7219,
    4068.77961,
    4069.83747,
    4070.89546,
    4071.9536,
    4073.01186,
    4074.07027,
    4075.12881,
    4076.1875,
    4077.24631,
    4078.30527,
    4079.36436,
    4080.4236,
    4081.48296,
    4082.54247,
    4083.60211,
    4084.6619,
    4085.72181,
    4086.78187,
    4087.84206,
    4088.9024,
    4089.96286,
    4091.02347,
    4092.08421,
    4093.1451,
    4094.20611,
    4095.26727,
    4096.32856,
    4097.39,
    4098.45156,
    4099.51327,
    4100.57511,
    4101.6371,
    4102.69921,
    4103.76147,
    4104.82386,
    4105.8864,
    4106.94906,
    4108.01187,
    4109.07481,
    4110.1379,
    4111.20111,
    4112.26447,
    4113.32796,
    4114.3916,
    4115.45536,
    4116.51927,
    4117.58331,
    4118.6475,
    4119.71181,
    4120.77627,
    4121.84086,
    4122.9056,
    4123.97046,
    4125.03547,
    4126.10061,
    4127.1659,
    4128.23131,
    4129.29687,
    4130.36256,
    4131.4284,
    4132.49436,
    4133.56047,
    4134.62671,
    4135.6931,
    4136.75961,
    4137.82627,
    4138.89306,
    4139.96,
    4141.02706,
    4142.09427,
    4143.16161,
    4144.2291,
    4145.29671,
    4146.36447,
    4147.43236,
    4148.5004,
    4149.56856,
    4150.63687,
    4151.70531,
    4152.7739,
    4153.84261,
    4154.91147,
    4155.98046,
    4157.0496,
    4158.11886,
    4159.18827,
    4160.25781,
    4161.3275,
    4162.39731,
    4163.46727,
    4164.53736,
    4165.6076,
    4166.67796,
    4167.74847,
    4168.81911,
    4169.8899,
    4170.96081,
    4172.03187,
    4173.10306,
    4174.1744,
    4175.24586,
    4176.31747,
    4177.38921,
    4178.4611,
    4179.53311,
    4180.60527,
    4181.67756,
    4182.75,
    4183.82256,
    4184.89527,
    4185.96811,
    4187.0411,
    4188.11421,
    4189.18747,
    4190.26086,
    4191.3344,
    4192.40806,
    4193.48187,
    4194.55581,
    4195.6299,
    4196.70411,
    4197.77847,
    4198.85296,
    4199.9276,
    4201.00236,
    4202.07727,
    4203.15231,
    4204.2275,
    4205.30281,
    4206.37827,
    4207.45386,
    4208.5296,
    4209.60546,
    4210.68147,
    4211.75761,
    4212.8339,
    4213.91031,
    4214.98687,
    4216.06356,
    4217.1404,
    4218.21736,
    4219.29447,
    4220.37171,
    4221.4491,
    4222.52661,
    4223.60427,
    4224.68206,
    4225.76,
    4226.83806,
    4227.91627,
    4228.99461,
    4230.0731,
    4231.15171,
    4232.23047,
    4233.30936,
    4234.3884,
    4235.46756,
    4236.54687,
    4237.62631,
    4238.7059,
    4239.78561,
    4240.86547,
    4241.94546,
    4243.0256,
    4244.10586,
    4245.18627,
    4246.26681,
    4247.3475,
    4248.42831,
    4249.50927,
    4250.59036,
    4251.6716,
    4252.75296,
    4253.83447,
    4254.91611,
    4255.9979,
    4257.07981,
    4258.16187,
    4259.24406,
    4260.3264,
    4261.40886,
    4262.49147,
    4263.57421,
    4264.6571,
    4265.74011,
    4266.82327,
    4267.90656,
    4268.99,
    4270.07356,
    4271.15727,
    4272.24111,
    4273.3251,
    4274.40921,
    4275.49347,
    4276.57786,
    4277.6624,
    4278.74706,
    4279.83187,
    4280.91681,
    4282.0019,
    4283.08711,
    4284.17247,
    4285.25796,
    4286.3436,
    4287.42936,
    4288.51527,
    4289.60131,
    4290.6875,
    4291.77381,
    4292.86027,
    4293.94686,
    4295.0336,
    4296.12046,
    4297.20747,
    4298.29461,
    4299.3819,
    4300.46931,
    4301.55687,
    4302.64456,
    4303.7324,
    4304.82036,
    4305.90847,
    4306.99671,
    4308.0851,
    4309.17361,
    4310.26227,
    4311.35106,
    4312.44,
    4313.52906,
    4314.61827,
    4315.70761,
    4316.7971,
    4317.88671,
    4318.97647,
    4320.06636,
    4321.1564,
    4322.24656,
    4323.33687,
    4324.42731,
    4325.5179,
    4326.60861,
    4327.69947,
    4328.79046,
    4329.8816,
    4330.97286,
    4332.06427,
    4333.15581,
    4334.2475,
    4335.33931,
    4336.43127,
    4337.52336,
    4338.6156,
    4339.70796,
    4340.80047,
    4341.89311,
    4342.9859,
    4344.07881,
    4345.17187,
    4346.26506,
    4347.3584,
    4348.45186,
    4349.54547,
    4350.63921,
    4351.7331,
    4352.82711,
    4353.92127,
    4355.01556,
    4356.11,
    4357.20456,
    4358.29927,
    4359.39411,
    4360.4891,
    4361.58421,
    4362.67947,
    4363.77486,
    4364.8704,
    4365.96606,
    4367.06187,
    4368.15781,
    4369.2539,
    4370.35011,
    4371.44647,
    4372.54296,
    4373.6396,
    4374.73636,
    4375.83327,
    4376.93031,
    4378.0275,
    4379.12481,
    4380.22227,
    4381.31986,
    4382.4176,
    4383.51546,
    4384.61347,
    4385.71161,
    4386.8099,
    4387.90831,
    4389.00687,
    4390.10556,
    4391.2044,
    4392.30336,
    4393.40247,
    4394.50171,
    4395.6011,
    4396.70061,
    4397.80027,
    4398.90006,
    4400,
    4401.10006,
    4402.20027,
    4403.30061,
    4404.4011,
    4405.50171,
    4406.60247,
    4407.70336,
    4408.8044,
    4409.90556,
    4411.00687,
    4412.10831,
    4413.2099,
    4414.31161,
    4415.41347,
    4416.51546,
    4417.6176,
    4418.71986,
    4419.82227,
    4420.92481,
    4422.0275,
    4423.13031,
    4424.23327,
    4425.33636,
    4426.4396,
    4427.54296,
    4428.64647,
    4429.75011,
    4430.8539,
    4431.95781,
    4433.06187,
    4434.16606,
    4435.2704,
    4436.37486,
    4437.47947,
    4438.58421,
    4439.6891,
    4440.79411,
    4441.89927,
    4443.00456,
    4444.11,
    4445.21556,
    4446.32127,
    4447.42711,
    4448.5331,
    4449.63921,
    4450.74547,
    4451.85186,
    4452.9584,
    4454.06506,
    4455.17187,
    4456.27881,
    4457.3859,
    4458.49311,
    4459.60047,
    4460.70796,
    4461.8156,
    4462.92336,
    4464.03127,
    4465.13931,
    4466.2475,
    4467.35581,
    4468.46427,
    4469.57286,
    4470.6816,
    4471.79046,
    4472.89947,
    4474.00861,
    4475.1179,
    4476.22731,
    4477.33687,
    4478.44656,
    4479.5564,
    4480.66636,
    4481.77647,
    4482.88671,
    4483.9971,
    4485.10761,
    4486.21827,
    4487.32906,
    4488.44,
    4489.55106,
    4490.66227,
    4491.77361,
    4492.8851,
    4493.99671,
    4495.10847,
    4496.22036,
    4497.3324,
    4498.44456,
    4499.55687,
    4500.66931,
    4501.7819,
    4502.89461,
    4504.00747,
    4505.12046,
    4506.2336,
    4507.34686,
    4508.46027,
    4509.57381,
    4510.6875,
    4511.80131,
    4512.91527,
    4514.02936,
    4515.1436,
    4516.25796,
    4517.37247,
    4518.48711,
    4519.6019,
    4520.71681,
    4521.83187,
    4522.94706,
    4524.0624,
    4525.17786,
    4526.29347,
    4527.40921,
    4528.5251,
    4529.64111,
    4530.75727,
    4531.87356,
    4532.99,
    4534.10656,
    4535.22327,
    4536.34011,
    4537.4571,
    4538.57421,
    4539.69147,
    4540.80886,
    4541.9264,
    4543.04406,
    4544.16187,
    4545.27981,
    4546.3979,
    4547.51611,
    4548.63447,
    4549.75296,
    4550.8716,
    4551.99036,
    4553.10927,
    4554.22831,
    4555.3475,
    4556.46681,
    4557.58627,
    4558.70586,
    4559.8256,
    4560.94546,
    4562.06547,
    4563.18561,
    4564.3059,
    4565.42631,
    4566.54687,
    4567.66756,
    4568.7884,
    4569.90936,
    4571.03047,
    4572.15171,
    4573.2731,
    4574.39461,
    4575.51627,
    4576.63806,
    4577.76,
    4578.88206,
    4580.00427,
    4581.12661,
    4582.2491,
    4583.37171,
    4584.49447,
    4585.61736,
    4586.7404,
    4587.86356,
    4588.98687,
    4590.11031,
    4591.2339,
    4592.35761,
    4593.48147,
    4594.60546,
    4595.7296,
    4596.85386,
    4597.97827,
    4599.10281,
    4600.2275,
    4601.35231,
    4602.47727,
    4603.60236,
    4604.7276,
    4605.85296,
    4606.97847,
    4608.10411,
    4609.2299,
    4610.35581,
    4611.48187,
    4612.60806,
    4613.7344,
    4614.86086,
    4615.98747,
    4617.11421,
    4618.2411,
    4619.36811,
    4620.49527,
    4621.62256,
    4622.75,
    4623.87756,
    4625.00527,
    4626.13311,
    4627.2611,
    4628.38921,
    4629.51747,
    4630.64586,
    4631.7744,
    4632.90306,
    4634.03187,
    4635.16081,
    4636.2899,
    4637.41911,
    4638.54847,
    4639.67796,
    4640.8076,
    4641.93736,
    4643.06727,
    4644.19731,
    4645.3275,
    4646.45781,
    4647.58827,
    4648.71886,
    4649.8496,
    4650.98046,
    4652.11147,
    4653.24261,
    4654.3739,
    4655.50531,
    4656.63687,
    4657.76856,
    4658.9004,
    4660.03236,
    4661.16447,
    4662.29671,
    4663.4291,
    4664.56161,
    4665.69427,
    4666.82706,
    4667.96,
    4669.09306,
    4670.22627,
    4671.35961,
    4672.4931,
    4673.62671,
    4674.76047,
    4675.89436,
    4677.0284,
    4678.16256,
    4679.29687,
    4680.43131,
    4681.5659,
    4682.70061,
    4683.83547,
    4684.97046,
    4686.1056,
    4687.24086,
    4688.37627,
    4689.51181,
    4690.6475,
    4691.78331,
    4692.91927,
    4694.05536,
    4695.1916,
    4696.32796,
    4697.46447,
    4698.60111,
    4699.7379,
    4700.87481,
    4702.01187,
    4703.14906,
    4704.2864,
    4705.42386,
    4706.56147,
    4707.69921,
    4708.8371,
    4709.97511,
    4711.11327,
    4712.25156,
    4713.39,
    4714.52856,
    4715.66727,
    4716.80611,
    4717.9451,
    4719.08421,
    4720.22347,
    4721.36286,
    4722.5024,
    4723.64206,
    4724.78187,
    4725.92181,
    4727.0619,
    4728.20211,
    4729.34247,
    4730.48296,
    4731.6236,
    4732.76436,
    4733.90527,
    4735.04631,
    4736.1875,
    4737.32881,
    4738.47027,
    4739.61186,
    4740.7536,
    4741.89546,
    4743.03747,
    4744.17961,
    4745.3219,
    4746.46431,
    4747.60687,
    4748.74956,
    4749.8924,
    4751.03536,
    4752.17847,
    4753.32171,
    4754.4651,
    4755.60861,
    4756.75227,
    4757.89606,
    4759.04,
    4760.18406,
    4761.32827,
    4762.47261,
    4763.6171,
    4764.76171,
    4765.90647,
    4767.05136,
    4768.1964,
    4769.34156,
    4770.48687,
    4771.63231,
    4772.7779,
    4773.92361,
    4775.06947,
    4776.21546,
    4777.3616,
    4778.50786,
    4779.65427,
    4780.80081,
    4781.9475,
    4783.09431,
    4784.24127,
    4785.38836,
    4786.5356,
    4787.68296,
    4788.83047,
    4789.97811,
    4791.1259,
    4792.27381,
    4793.42187,
    4794.57006,
    4795.7184,
    4796.86686,
    4798.01547,
    4799.16421,
    4800.3131,
    4801.46211,
    4802.61127,
    4803.76056,
    4804.91,
    4806.05956,
    4807.20927,
    4808.35911,
    4809.5091,
    4810.65921,
    4811.80947,
    4812.95986,
    4814.1104,
    4815.26106,
    4816.41187,
    4817.56281,
    4818.7139,
    4819.86511,
    4821.01647,
    4822.16796,
    4823.3196,
    4824.47136,
    4825.62327,
    4826.77531,
    4827.9275,
    4829.07981,
    4830.23227,
    4831.38486,
    4832.5376,
    4833.69046,
    4834.84347,
    4835.99661,
    4837.1499,
    4838.30331,
    4839.45687,
    4840.61056,
    4841.7644,
    4842.91836,
    4844.07247,
    4845.22671,
    4846.3811,
    4847.53561,
    4848.69027,
    4849.84506,
    4851,
    4852.15506,
    4853.31027,
    4854.46561,
    4855.6211,
    4856.77671,
    4857.93247,
    4859.08836,
    4860.2444,
    4861.40056,
    4862.55687,
    4863.71331,
    4864.8699,
    4866.02661,
    4867.18347,
    4868.34046,
    4869.4976,
    4870.65486,
    4871.81227,
    4872.96981,
    4874.1275,
    4875.28531,
    4876.44327,
    4877.60136,
    4878.7596,
    4879.91796,
    4881.07647,
    4882.23511,
    4883.3939,
    4884.55281,
    4885.71187,
    4886.87106,
    4888.0304,
    4889.18986,
    4890.34947,
    4891.50921,
    4892.6691,
    4893.82911,
    4894.98927,
    4896.14956,
    4897.31,
    4898.47056,
    4899.63127,
    4900.79211,
    4901.9531,
    4903.11421,
    4904.27547,
    4905.43686,
    4906.5984,
    4907.76006,
    4908.92187,
    4910.08381,
    4911.2459,
    4912.40811,
    4913.57047,
    4914.73296,
    4915.8956,
    4917.05836,
    4918.22127,
    4919.38431,
    4920.5475,
    4921.71081,
    4922.87427,
    4924.03786,
    4925.2016,
    4926.36546,
    4927.52947,
    4928.69361,
    4929.8579,
    4931.02231,
    4932.18687,
    4933.35156,
    4934.5164,
    4935.68136,
    4936.84647,
    4938.01171,
    4939.1771,
    4940.34261,
    4941.50827,
    4942.67406,
    4943.84,
    4945.00606,
    4946.17227,
    4947.33861,
    4948.5051,
    4949.67171,
    4950.83847,
    4952.00536,
    4953.1724,
    4954.33956,
    4955.50687,
    4956.67431,
    4957.8419,
    4959.00961,
    4960.17747,
    4961.34546,
    4962.5136,
    4963.68186,
    4964.85027,
    4966.01881,
    4967.1875,
    4968.35631,
    4969.52527,
    4970.69436,
    4971.8636,
    4973.03296,
    4974.20247,
    4975.37211,
    4976.5419,
    4977.71181,
    4978.88187,
    4980.05206,
    4981.2224,
    4982.39286,
    4983.56347,
    4984.73421,
    4985.9051,
    4987.07611,
    4988.24727,
    4989.41856,
    4990.59,
    4991.76156,
    4992.93327,
    4994.10511,
    4995.2771,
    4996.44921,
    4997.62147,
    4998.79386,
    4999.9664,
    5001.13906,
    5002.31187,
    5003.48481,
    5004.6579,
    5005.83111,
    5007.00447,
    5008.17796,
    5009.3516,
    5010.52536,
    5011.69927,
    5012.87331,
    5014.0475,
    5015.22181,
    5016.39627,
    5017.57086,
    5018.7456,
    5019.92046,
    5021.09547,
    5022.27061,
    5023.4459,
    5024.62131,
    5025.79687,
    5026.97256,
    5028.1484,
    5029.32436,
    5030.50047,
    5031.67671,
    5032.8531,
    5034.02961,
    5035.20627,
    5036.38306,
    5037.56,
    5038.73706,
    5039.91427,
    5041.09161,
    5042.2691,
    5043.44671,
    5044.62447,
    5045.80236,
    5046.9804,
    5048.15856,
    5049.33687,
    5050.51531,
    5051.6939,
    5052.87261,
    5054.05147,
    5055.23046,
    5056.4096,
    5057.58886,
    5058.76827,
    5059.94781,
    5061.1275,
    5062.30731,
    5063.48727,
    5064.66736,
    5065.8476,
    5067.02796,
    5068.20847,
    5069.38911,
    5070.5699,
    5071.75081,
    5072.93187,
    5074.11306,
    5075.2944,
    5076.47586,
    5077.65747,
    5078.83921,
    5080.0211,
    5081.20311,
    5082.38527,
    5083.56756,
    5084.75,
    5085.93256,
    5087.11527,
    5088.29811,
    5089.4811,
    5090.66421,
    5091.84747,
    5093.03086,
    5094.2144,
    5095.39806,
    5096.58187,
    5097.76581,
    5098.9499,
    5100.13411,
    5101.31847,
    5102.50296,
    5103.6876,
    5104.87236,
    5106.05727,
    5107.24231,
    5108.4275,
    5109.61281,
    5110.79827,
    5111.98386,
    5113.1696,
    5114.35546,
    5115.54147,
    5116.72761,
    5117.9139,
    5119.10031,
    5120.28687,
    5121.47356,
    5122.6604,
    5123.84736,
    5125.03447,
    5126.22171,
    5127.4091,
    5128.59661,
    5129.78427,
    5130.97206,
    5132.16,
    5133.34806,
    5134.53627,
    5135.72461,
    5136.9131,
    5138.10171,
    5139.29047,
    5140.47936,
    5141.6684,
    5142.85756,
    5144.04687,
    5145.23631,
    5146.4259,
    5147.61561,
    5148.80547,
    5149.99546,
    5151.1856,
    5152.37586,
    5153.56627,
    5154.75681,
    5155.9475,
    5157.13831,
    5158.32927,
    5159.52036,
    5160.7116,
    5161.90296,
    5163.09447,
    5164.28611,
    5165.4779,
    5166.66981,
    5167.86187,
    5169.05406,
    5170.2464,
    5171.43886,
    5172.63147,
    5173.82421,
    5175.0171,
    5176.21011,
    5177.40327,
    5178.59656,
    5179.79,
    5180.98356,
    5182.17727,
    5183.37111,
    5184.5651,
    5185.75921,
    5186.95347,
    5188.14786,
    5189.3424,
    5190.53706,
    5191.73187,
    5192.92681,
    5194.1219,
    5195.31711,
    5196.51247,
    5197.70796,
    5198.9036,
    5200.09936,
    5201.29527,
    5202.49131,
    5203.6875,
    5204.88381,
    5206.08027,
    5207.27686,
    5208.4736,
    5209.67046,
    5210.86747,
    5212.06461,
    5213.2619,
    5214.45931,
    5215.65687,
    5216.85456,
    5218.0524,
    5219.25036,
    5220.44847,
    5221.64671,
    5222.8451,
    5224.04361,
    5225.24227,
    5226.44106,
    5227.64,
    5228.83906,
    5230.03827,
    5231.23761,
    5232.4371,
    5233.63671,
    5234.83647,
    5236.03636,
    5237.2364,
    5238.43656,
    5239.63687,
    5240.83731,
    5242.0379,
    5243.23861,
    5244.43947,
    5245.64046,
    5246.8416,
    5248.04286,
    5249.24427,
    5250.44581,
    5251.6475,
    5252.84931,
    5254.05127,
    5255.25336,
    5256.4556,
    5257.65796,
    5258.86047,
    5260.06311,
    5261.2659,
    5262.46881,
    5263.67187,
    5264.87506,
    5266.0784,
    5267.28186,
    5268.48547,
    5269.68921,
    5270.8931,
    5272.09711,
    5273.30127,
    5274.50556,
    5275.71,
    5276.91456,
    5278.11927,
    5279.32411,
    5280.5291,
    5281.73421,
    5282.93947,
    5284.14486,
    5285.3504,
    5286.55606,
    5287.76187,
    5288.96781,
    5290.1739,
    5291.38011,
    5292.58647,
    5293.79296,
    5294.9996,
    5296.20636,
    5297.41327,
    5298.62031,
    5299.8275,
    5301.03481,
    5302.24227,
    5303.44986,
    5304.6576,
    5305.86546,
    5307.07347,
    5308.28161,
    5309.4899,
    5310.69831,
    5311.90687,
    5313.11556,
    5314.3244,
    5315.53336,
    5316.74247,
    5317.95171,
    5319.1611,
    5320.37061,
    5321.58027,
    5322.79006,
    5324,
    5325.21006,
    5326.42027,
    5327.63061,
    5328.8411,
    5330.05171,
    5331.26247,
    5332.47336,
    5333.6844,
    5334.89556,
    5336.10687,
    5337.31831,
    5338.5299,
    5339.74161,
    5340.95347,
    5342.16546,
    5343.3776,
    5344.58986,
    5345.80227,
    5347.01481,
    5348.2275,
    5349.44031,
    5350.65327,
    5351.86636,
    5353.0796,
    5354.29296,
    5355.50647,
    5356.72011,
    5357.9339,
    5359.14781,
    5360.36187,
    5361.57606,
    5362.7904,
    5364.00486,
    5365.21947,
    5366.43421,
    5367.6491,
    5368.86411,
    5370.07927,
    5371.29456,
    5372.51,
    5373.72556,
    5374.94127,
    5376.15711,
    5377.3731,
    5378.58921,
    5379.80547,
    5381.02186,
    5382.2384,
    5383.45506,
    5384.67187,
    5385.88881,
    5387.1059,
    5388.32311,
    5389.54047,
    5390.75796,
    5391.9756,
    5393.19336,
    5394.41127,
    5395.62931,
    5396.8475,
    5398.06581,
    5399.28427,
    5400.50286,
    5401.7216,
    5402.94046,
    5404.15947,
    5405.37861,
    5406.5979,
    5407.81731,
    5409.03687,
    5410.25656,
    5411.4764,
    5412.69636,
    5413.91647,
    5415.13671,
    5416.3571,
    5417.57761,
    5418.79827,
    5420.01906,
    5421.24,
    5422.46106,
    5423.68227,
    5424.90361,
    5426.1251,
    5427.34671,
    5428.56847,
    5429.79036,
    5431.0124,
    5432.23456,
    5433.45687,
    5434.67931,
    5435.9019,
    5437.12461,
    5438.34747,
    5439.57046,
    5440.7936,
    5442.01686,
    5443.24027,
    5444.46381,
    5445.6875,
    5446.91131,
    5448.13527,
    5449.35936,
    5450.5836,
    5451.80796,
    5453.03247,
    5454.25711,
    5455.4819,
    5456.70681,
    5457.93187,
    5459.15706,
    5460.3824,
    5461.60786,
    5462.83347,
    5464.05921,
    5465.2851,
    5466.51111,
    5467.73727,
    5468.96356,
    5470.19,
    5471.41656,
    5472.64327,
    5473.87011,
    5475.0971,
    5476.32421,
    5477.55147,
    5478.77886,
    5480.0064,
    5481.23406,
    5482.46187,
    5483.68981,
    5484.9179,
    5486.14611,
    5487.37447,
    5488.60296,
    5489.8316,
    5491.06036,
    5492.28927,
    5493.51831,
    5494.7475,
    5495.97681,
    5497.20627,
    5498.43586,
    5499.6656,
    5500.89546,
    5502.12547,
    5503.35561,
    5504.5859,
    5505.81631,
    5507.04687,
    5508.27756,
    5509.5084,
    5510.73936,
    5511.97047,
    5513.20171,
    5514.4331,
    5515.66461,
    5516.89627,
    5518.12806,
    5519.36,
    5520.59206,
    5521.82427,
    5523.05661,
    5524.2891,
    5525.52171,
    5526.75447,
    5527.98736,
    5529.2204,
    5530.45356,
    5531.68687,
    5532.92031,
    5534.1539,
    5535.38761,
    5536.62147,
    5537.85546,
    5539.0896,
    5540.32386,
    5541.55827,
    5542.79281,
    5544.0275,
    5545.26231,
    5546.49727,
    5547.73236,
    5548.9676,
    5550.20296,
    5551.43847,
    5552.67411,
    5553.9099,
    5555.14581,
    5556.38187,
    5557.61806,
    5558.8544,
    5560.09086,
    5561.32747,
    5562.56421,
    5563.8011,
    5565.03811,
    5566.27527,
    5567.51256,
    5568.75,
    5569.98756,
    5571.22527,
    5572.46311,
    5573.7011,
    5574.93921,
    5576.17747,
    5577.41586,
    5578.6544,
    5579.89306,
    5581.13187,
    5582.37081,
    5583.6099,
    5584.84911,
    5586.08847,
    5587.32796,
    5588.5676,
    5589.80736,
    5591.04727,
    5592.28731,
    5593.5275,
    5594.76781,
    5596.00827,
    5597.24886,
    5598.4896,
    5599.73046,
    5600.97147,
    5602.21261,
    5603.4539,
    5604.69531,
    5605.93687,
    5607.17856,
    5608.4204,
    5609.66236,
    5610.90447,
    5612.14671,
    5613.3891,
    5614.63161,
    5615.87427,
    5617.11706,
    5618.36,
    5619.60306,
    5620.84627,
    5622.08961,
    5623.3331,
    5624.57671,
    5625.82047,
    5627.06436,
    5628.3084,
    5629.55256,
    5630.79687,
    5632.04131,
    5633.2859,
    5634.53061,
    5635.77547,
    5637.02046,
    5638.2656,
    5639.51086,
    5640.75627,
    5642.00181,
    5643.2475,
    5644.49331,
    5645.73927,
    5646.98536,
    5648.2316,
    5649.47796,
    5650.72447,
    5651.97111,
    5653.2179,
    5654.46481,
    5655.71187,
    5656.95906,
    5658.2064,
    5659.45386,
    5660.70147,
    5661.94921,
    5663.1971,
    5664.44511,
    5665.69327,
    5666.94156,
    5668.19,
    5669.43856,
    5670.68727,
    5671.93611,
    5673.1851,
    5674.43421,
    5675.68347,
    5676.93286,
    5678.1824,
    5679.43206,
    5680.68187,
    5681.93181,
    5683.1819,
    5684.43211,
    5685.68247,
    5686.93296,
    5688.1836,
    5689.43436,
    5690.68527,
    5691.93631,
    5693.1875,
    5694.43881,
    5695.69027,
    5696.94186,
    5698.1936,
    5699.44546,
    5700.69747,
    5701.94961,
    5703.2019,
    5704.45431,
    5705.70687,
    5706.95956,
    5708.2124,
    5709.46536,
    5710.71847,
    5711.97171,
    5713.2251,
    5714.47861,
    5715.73227,
    5716.98606,
    5718.24,
    5719.49406,
    5720.74827,
    5722.00261,
    5723.2571,
    5724.51171,
    5725.76647,
    5727.02136,
    5728.2764,
    5729.53156,
    5730.78687,
    5732.04231,
    5733.2979,
    5734.55361,
    5735.80947,
    5737.06546,
    5738.3216,
    5739.57786,
    5740.83427,
    5742.09081,
    5743.3475,
    5744.60431,
    5745.86127,
    5747.11836,
    5748.3756,
    5749.63296,
    5750.89047,
    5752.14811,
    5753.4059,
    5754.66381,
    5755.92187,
    5757.18006,
    5758.4384,
    5759.69686,
    5760.95547,
    5762.21421,
    5763.4731,
    5764.73211,
    5765.99127,
    5767.25056,
    5768.51,
    5769.76956,
    5771.02927,
    5772.28911,
    5773.5491,
    5774.80921,
    5776.06947,
    5777.32986,
    5778.5904,
    5779.85106,
    5781.11187,
    5782.37281,
    5783.6339,
    5784.89511,
    5786.15647,
    5787.41796,
    5788.6796,
    5789.94136,
    5791.20327,
    5792.46531,
    5793.7275,
    5794.98981,
    5796.25227,
    5797.51486,
    5798.7776,
    5800.04046,
    5801.30347,
    5802.56661,
    5803.8299,
    5805.09331,
    5806.35687,
    5807.62056,
    5808.8844,
    5810.14836,
    5811.41247,
    5812.67671,
    5813.9411,
    5815.20561,
    5816.47027,
    5817.73506,
    5819,
    5820.26506,
    5821.53027,
    5822.79561,
    5824.0611,
    5825.32671,
    5826.59247,
    5827.85836,
    5829.1244,
    5830.39056,
    5831.65687,
    5832.92331,
    5834.1899,
    5835.45661,
    5836.72347,
    5837.99046,
    5839.2576,
    5840.52486,
    5841.79227,
    5843.05981,
    5844.3275,
    5845.59531,
    5846.86327,
    5848.13136,
    5849.3996,
    5850.66796,
    5851.93647,
    5853.20511,
    5854.4739,
    5855.74281,
    5857.01187,
    5858.28106,
    5859.5504,
    5860.81986,
    5862.08947,
    5863.35921,
    5864.6291,
    5865.89911,
    5867.16927,
    5868.43956,
    5869.71,
    5870.98056,
    5872.25127,
    5873.52211,
    5874.7931,
    5876.06421,
    5877.33547,
    5878.60686,
    5879.8784,
    5881.15006,
    5882.42187,
    5883.69381,
    5884.9659,
    5886.23811,
    5887.51047,
    5888.78296,
    5890.0556,
    5891.32836,
    5892.60127,
    5893.87431,
    5895.1475,
    5896.42081,
    5897.69427,
    5898.96786,
    5900.2416,
    5901.51546,
    5902.78947,
    5904.06361,
    5905.3379,
    5906.61231,
    5907.88687,
    5909.16156,
    5910.4364,
    5911.71136,
    5912.98647,
    5914.26171,
    5915.5371,
    5916.81261,
    5918.08827,
    5919.36406,
    5920.64,
    5921.91606,
    5923.19227,
    5924.46861,
    5925.7451,
    5927.02171,
    5928.29847,
    5929.57536,
    5930.8524,
    5932.12956,
    5933.40687,
    5934.68431,
    5935.9619,
    5937.23961,
    5938.51747,
    5939.79546,
    5941.0736,
    5942.35186,
    5943.63027,
    5944.90881,
    5946.1875,
    5947.46631,
    5948.74527,
    5950.02436,
    5951.3036,
    5952.58296,
    5953.86247,
    5955.14211,
    5956.4219,
    5957.70181,
    5958.98187,
    5960.26206,
    5961.5424,
    5962.82286,
    5964.10347,
    5965.38421,
    5966.6651,
    5967.94611,
    5969.22727,
    5970.50856,
    5971.79,
    5973.07156,
    5974.35327,
    5975.63511,
    5976.9171,
    5978.19921,
    5979.48147,
    5980.76386,
    5982.0464,
    5983.32906,
    5984.61187,
    5985.89481,
    5987.1779,
    5988.46111,
    5989.74447,
    5991.02796,
    5992.3116,
    5993.59536,
    5994.87927,
    5996.16331,
    5997.4475,
    5998.73181,
    6000.01627,
    6001.30086,
    6002.5856,
    6003.87046,
    6005.15547,
    6006.44061,
    6007.7259,
    6009.01131,
    6010.29687,
    6011.58256,
    6012.8684,
    6014.15436,
    6015.44047,
    6016.72671,
    6018.0131,
    6019.29961,
    6020.58627,
    6021.87306,
    6023.16,
    6024.44706,
    6025.73427,
    6027.02161,
    6028.3091,
    6029.59671,
    6030.88447,
    6032.17236,
    6033.4604,
    6034.74856,
    6036.03687,
    6037.32531,
    6038.6139,
    6039.90261,
    6041.19147,
    6042.48046,
    6043.7696,
    6045.05886,
    6046.34827,
    6047.63781,
    6048.9275,
    6050.21731,
    6051.50727,
    6052.79736,
    6054.0876,
    6055.37796,
    6056.66847,
    6057.95911,
    6059.2499,
    6060.54081,
    6061.83187,
    6063.12306,
    6064.4144,
    6065.70586,
    6066.99747,
    6068.28921,
    6069.5811,
    6070.87311,
    6072.16527,
    6073.45756,
    6074.75,
    6076.04256,
    6077.33527,
    6078.62811,
    6079.9211,
    6081.21421,
    6082.50747,
    6083.80086,
    6085.0944,
    6086.38806,
    6087.68187,
    6088.97581,
    6090.2699,
    6091.56411,
    6092.85847,
    6094.15296,
    6095.4476,
    6096.74236,
    6098.03727,
    6099.33231,
    6100.6275,
    6101.92281,
    6103.21827,
    6104.51386,
    6105.8096,
    6107.10546,
    6108.40147,
    6109.69761,
    6110.9939,
    6112.29031,
    6113.58687,
    6114.88356,
    6116.1804,
    6117.47736,
    6118.77447,
    6120.07171,
    6121.3691,
    6122.66661,
    6123.96427,
    6125.26206,
    6126.56,
    6127.85806,
    6129.15627,
    6130.45461,
    6131.7531,
    6133.05171,
    6134.35047,
    6135.64936,
    6136.9484,
    6138.24756,
    6139.54687,
    6140.84631,
    6142.1459,
    6143.44561,
    6144.74547,
    6146.04546,
    6147.3456,
    6148.64586,
    6149.94627,
    6151.24681,
    6152.5475,
    6153.84831,
    6155.14927,
    6156.45036,
    6157.7516,
    6159.05296,
    6160.35447,
    6161.65611,
    6162.9579,
    6164.25981,
    6165.56187,
    6166.86406,
    6168.1664,
    6169.46886,
    6170.77147,
    6172.07421,
    6173.3771,
    6174.68011,
    6175.98327,
    6177.28656,
    6178.59,
    6179.89356,
    6181.19727,
    6182.50111,
    6183.8051,
    6185.10921,
    6186.41347,
    6187.71786,
    6189.0224,
    6190.32706,
    6191.63187,
    6192.93681,
    6194.2419,
    6195.54711,
    6196.85247,
    6198.15796,
    6199.4636,
    6200.76936,
    6202.07527,
    6203.38131,
    6204.6875,
    6205.99381,
    6207.30027,
    6208.60686,
    6209.9136,
    6211.22046,
    6212.52747,
    6213.83461,
    6215.1419,
    6216.44931,
    6217.75687,
    6219.06456,
    6220.3724,
    6221.68036,
    6222.98847,
    6224.29671,
    6225.6051,
    6226.91361,
    6228.22227,
    6229.53106,
    6230.84,
    6232.14906,
    6233.45827,
    6234.76761,
    6236.0771,
    6237.38671,
    6238.69647,
    6240.00636,
    6241.3164,
    6242.62656,
    6243.93687,
    6245.24731,
    6246.5579,
    6247.86861,
    6249.17947,
    6250.49046,
    6251.8016,
    6253.11286,
    6254.42427,
    6255.73581,
    6257.0475,
    6258.35931,
    6259.67127,
    6260.98336,
    6262.2956,
    6263.60796,
    6264.92047,
    6266.23311,
    6267.5459,
    6268.85881,
    6270.17187,
    6271.48506,
    6272.7984,
    6274.11186,
    6275.42547,
    6276.73921,
    6278.0531,
    6279.36711,
    6280.68127,
    6281.99556,
    6283.31,
    6284.62456,
    6285.93927,
    6287.25411,
    6288.5691,
    6289.88421,
    6291.19947,
    6292.51486,
    6293.8304,
    6295.14606,
    6296.46187,
    6297.77781,
    6299.0939,
    6300.41011,
    6301.72647,
    6303.04296,
    6304.3596,
    6305.67636,
    6306.99327,
    6308.31031,
    6309.6275,
    6310.94481,
    6312.26227,
    6313.57986,
    6314.8976,
    6316.21546,
    6317.53347,
    6318.85161,
    6320.1699,
    6321.48831,
    6322.80687,
    6324.12556,
    6325.4444,
    6326.76336,
    6328.08247,
    6329.40171,
    6330.7211,
    6332.04061,
    6333.36027,
    6334.68006,
    6336,
    6337.32006,
    6338.64027,
    6339.96061,
    6341.2811,
    6342.60171,
    6343.92247,
    6345.24336,
    6346.5644,
    6347.88556,
    6349.20687,
    6350.52831,
    6351.8499,
    6353.17161,
    6354.49347,
    6355.81546,
    6357.1376,
    6358.45986,
    6359.78227,
    6361.10481,
    6362.4275,
    6363.75031,
    6365.07327,
    6366.39636,
    6367.7196,
    6369.04296,
    6370.36647,
    6371.69011,
    6373.0139,
    6374.33781,
    6375.66187,
    6376.98606,
    6378.3104,
    6379.63486,
    6380.95947,
    6382.28421,
    6383.6091,
    6384.93411,
    6386.25927,
    6387.58456,
    6388.91,
    6390.23556,
    6391.56127,
    6392.88711,
    6394.2131,
    6395.53921,
    6396.86547,
    6398.19186,
    6399.5184,
    6400.84506,
    6402.17187,
    6403.49881,
    6404.8259,
    6406.15311,
    6407.48047,
    6408.80796,
    6410.1356,
    6411.46336,
    6412.79127,
    6414.11931,
    6415.4475,
    6416.77581,
    6418.10427,
    6419.43286,
    6420.7616,
    6422.09046,
    6423.41947,
    6424.74861,
    6426.0779,
    6427.40731,
    6428.73687,
    6430.06656,
    6431.3964,
    6432.72636,
    6434.05647,
    6435.38671,
    6436.7171,
    6438.04761,
    6439.37827,
    6440.70906,
    6442.04,
    6443.37106,
    6444.70227,
    6446.03361,
    6447.3651,
    6448.69671,
    6450.02847,
    6451.36036,
    6452.6924,
    6454.02456,
    6455.35687,
    6456.68931,
    6458.0219,
    6459.35461,
    6460.68747,
    6462.02046,
    6463.3536,
    6464.68686,
    6466.02027,
    6467.35381,
    6468.6875,
    6470.02131,
    6471.35527,
    6472.68936,
    6474.0236,
    6475.35796,
    6476.69247,
    6478.02711,
    6479.3619,
    6480.69681,
    6482.03187,
    6483.36706,
    6484.7024,
    6486.03786,
    6487.37347,
    6488.70921,
    6490.0451,
    6491.38111,
    6492.71727,
    6494.05356,
    6495.39,
    6496.72656,
    6498.06327,
    6499.40011,
    6500.7371,
    6502.07421,
    6503.41147,
    6504.74886,
    6506.0864,
    6507.42406,
    6508.76187,
    6510.09981,
    6511.4379,
    6512.77611,
    6514.11447,
    6515.45296,
    6516.7916,
    6518.13036,
    6519.46927,
    6520.80831,
    6522.1475,
    6523.48681,
    6524.82627,
    6526.16586,
    6527.5056,
    6528.84546,
    6530.18547,
    6531.52561,
    6532.8659,
    6534.20631,
    6535.54687,
    6536.88756,
    6538.2284,
    6539.56936,
    6540.91047,
    6542.25171,
    6543.5931,
    6544.93461,
    6546.27627,
    6547.61806,
    6548.96,
    6550.30206,
    6551.64427,
    6552.98661,
    6554.3291,
    6555.67171,
    6557.01447,
    6558.35736,
    6559.7004,
    6561.04356,
    6562.38687,
    6563.73031,
    6565.0739,
    6566.41761,
    6567.76147,
    6569.10546,
    6570.4496,
    6571.79386,
    6573.13827,
    6574.48281,
    6575.8275,
    6577.17231,
    6578.51727,
    6579.86236,
    6581.2076,
    6582.55296,
    6583.89847,
    6585.24411,
    6586.5899,
    6587.93581,
    6589.28187,
    6590.62806,
    6591.9744,
    6593.32086,
    6594.66747,
    6596.01421,
    6597.3611,
    6598.70811,
    6600.05527,
    6601.40256,
    6602.75,
    6604.09756,
    6605.44527,
    6606.79311,
    6608.1411,
    6609.48921,
    6610.83747,
    6612.18586,
    6613.5344,
    6614.88306,
    6616.23187,
    6617.58081,
    6618.9299,
    6620.27911,
    6621.62847,
    6622.97796,
    6624.3276,
    6625.67736,
    6627.02727,
    6628.37731,
    6629.7275,
    6631.07781,
    6632.42827,
    6633.77886,
    6635.1296,
    6636.48046,
    6637.83147,
    6639.18261,
    6640.5339,
    6641.88531,
    6643.23687,
    6644.58856,
    6645.9404,
    6647.29236,
    6648.64447,
    6649.99671,
    6651.3491,
    6652.70161,
    6654.05427,
    6655.40706,
    6656.76,
    6658.11306,
    6659.46627,
    6660.81961,
    6662.1731,
    6663.52671,
    6664.88047,
    6666.23436,
    6667.5884,
    6668.94256,
    6670.29687,
    6671.65131,
    6673.0059,
    6674.36061,
    6675.71547,
    6677.07046,
    6678.4256,
    6679.78086,
    6681.13627,
    6682.49181,
    6683.8475,
    6685.20331,
    6686.55927,
    6687.91536,
    6689.2716,
    6690.62796,
    6691.98447,
    6693.34111,
    6694.6979,
    6696.05481,
    6697.41187,
    6698.76906,
    6700.1264,
    6701.48386,
    6702.84147,
    6704.19921,
    6705.5571,
    6706.91511,
    6708.27327,
    6709.63156,
    6710.99,
    6712.34856,
    6713.70727,
    6715.06611,
    6716.4251,
    6717.78421,
    6719.14347,
    6720.50286,
    6721.8624,
    6723.22206,
    6724.58187,
    6725.94181,
    6727.3019,
    6728.66211,
    6730.02247,
    6731.38296,
    6732.7436,
    6734.10436,
    6735.46527,
    6736.82631,
    6738.1875,
    6739.54881,
    6740.91027,
    6742.27186,
    6743.6336,
    6744.99546,
    6746.35747,
    6747.71961,
    6749.0819,
    6750.44431,
    6751.80687,
    6753.16956,
    6754.5324,
    6755.89536,
    6757.25847,
    6758.62171,
    6759.9851,
    6761.34861,
    6762.71227,
    6764.07606,
    6765.44,
    6766.80406,
    6768.16827,
    6769.53261,
    6770.8971,
    6772.26171,
    6773.62647,
    6774.99136,
    6776.3564,
    6777.72156,
    6779.08687,
    6780.45231,
    6781.8179,
    6783.18361,
    6784.54947,
    6785.91546,
    6787.2816,
    6788.64786,
    6790.01427,
    6791.38081,
    6792.7475,
    6794.11431,
    6795.48127,
    6796.84836,
    6798.2156,
    6799.58296,
    6800.95047,
    6802.31811,
    6803.6859,
    6805.05381,
    6806.42187,
    6807.79006,
    6809.1584,
    6810.52686,
    6811.89547,
    6813.26421,
    6814.6331,
    6816.00211,
    6817.37127,
    6818.74056,
    6820.11,
    6821.47956,
    6822.84927,
    6824.21911,
    6825.5891,
    6826.95921,
    6828.32947,
    6829.69986,
    6831.0704,
    6832.44106,
    6833.81187,
    6835.18281,
    6836.5539,
    6837.92511,
    6839.29647,
    6840.66796,
    6842.0396,
    6843.41136,
    6844.78327,
    6846.15531,
    6847.5275,
    6848.89981,
    6850.27227,
    6851.64486,
    6853.0176,
    6854.39046,
    6855.76347,
    6857.13661,
    6858.5099,
    6859.88331,
    6861.25687,
    6862.63056,
    6864.0044,
    6865.37836,
    6866.75247,
    6868.12671,
    6869.5011,
    6870.87561,
    6872.25027,
    6873.62506,
    6875
];
const data1 = [
    0,
    0,
    0.00005,
    0.00022,
    0.0005,
    0.0009,
    0.0014,
    0.00202,
    0.00275,
    0.0036,
    0.00455,
    0.00562,
    0.0068,
    0.0081,
    0.0095,
    0.01102,
    0.01265,
    0.0144,
    0.01625,
    0.01822,
    0.0203,
    0.0225,
    0.0248,
    0.02722,
    0.02975,
    0.0324,
    0.03515,
    0.03802,
    0.041,
    0.0441,
    0.0473,
    0.05062,
    0.05405,
    0.0576,
    0.06125,
    0.06502,
    0.0689,
    0.0729,
    0.077,
    0.08122,
    0.08555,
    0.09,
    0.09455,
    0.09922,
    0.104,
    0.1089,
    0.1139,
    0.11902,
    0.12425,
    0.1296,
    0.13505,
    0.14062,
    0.1463,
    0.1521,
    0.158,
    0.16402,
    0.17015,
    0.1764,
    0.18275,
    0.18922,
    0.1958,
    0.2025,
    0.2093,
    0.21622,
    0.22325,
    0.2304,
    0.23765,
    0.24502,
    0.2525,
    0.2601,
    0.2678,
    0.27562,
    0.28355,
    0.2916,
    0.29975,
    0.30802,
    0.3164,
    0.3249,
    0.3335,
    0.34222,
    0.35105,
    0.36,
    0.36905,
    0.37822,
    0.3875,
    0.3969,
    0.4064,
    0.41602,
    0.42575,
    0.4356,
    0.44555,
    0.45562,
    0.4658,
    0.4761,
    0.4865,
    0.49702,
    0.50765,
    0.5184,
    0.52925,
    0.54022,
    0.5513,
    0.5625,
    0.5738,
    0.58522,
    0.59675,
    0.6084,
    0.62015,
    0.63202,
    0.644,
    0.6561,
    0.6683,
    0.68062,
    0.69305,
    0.7056,
    0.71825,
    0.73102,
    0.7439,
    0.7569,
    0.77,
    0.78322,
    0.79655,
    0.81,
    0.82355,
    0.83722,
    0.851,
    0.8649,
    0.8789,
    0.89302,
    0.90725,
    0.9216,
    0.93605,
    0.95062,
    0.9653,
    0.9801,
    0.995,
    1.01002,
    1.02515,
    1.0404,
    1.05575,
    1.07122,
    1.0868,
    1.1025,
    1.1183,
    1.13422,
    1.15025,
    1.1664,
    1.18265,
    1.19902,
    1.2155,
    1.2321,
    1.2488,
    1.26562,
    1.28255,
    1.2996,
    1.31675,
    1.33402,
    1.3514,
    1.3689,
    1.3865,
    1.40422,
    1.42205,
    1.44,
    1.45805,
    1.47622,
    1.4945,
    1.5129,
    1.5314,
    1.55002,
    1.56875,
    1.5876,
    1.60655,
    1.62562,
    1.6448,
    1.6641,
    1.6835,
    1.70302,
    1.72265,
    1.7424,
    1.76225,
    1.78222,
    1.8023,
    1.8225,
    1.8428,
    1.86322,
    1.88375,
    1.9044,
    1.92515,
    1.94602,
    1.967,
    1.9881,
    2.0093,
    2.03062,
    2.05205,
    2.0736,
    2.09525,
    2.11702,
    2.1389,
    2.1609,
    2.183,
    2.20522,
    2.22755,
    2.25,
    2.27255,
    2.29522,
    2.318,
    2.3409,
    2.3639,
    2.38702,
    2.41025,
    2.4336,
    2.45705,
    2.48062,
    2.5043,
    2.5281,
    2.552,
    2.57602,
    2.60015,
    2.6244,
    2.64875,
    2.67322,
    2.6978,
    2.7225,
    2.7473,
    2.77222,
    2.79725,
    2.8224,
    2.84765,
    2.87302,
    2.8985,
    2.9241,
    2.9498,
    2.97562,
    3.00155,
    3.0276,
    3.05375,
    3.08002,
    3.1064,
    3.1329,
    3.1595,
    3.18622,
    3.21305,
    3.24,
    3.26705,
    3.29422,
    3.3215,
    3.3489,
    3.3764,
    3.40402,
    3.43175,
    3.4596,
    3.48755,
    3.51562,
    3.5438,
    3.5721,
    3.6005,
    3.62902,
    3.65765,
    3.6864,
    3.71525,
    3.74422,
    3.7733,
    3.8025,
    3.8318,
    3.86122,
    3.89075,
    3.9204,
    3.95015,
    3.98002,
    4.01,
    4.0401,
    4.0703,
    4.10062,
    4.13105,
    4.1616,
    4.19225,
    4.22302,
    4.2539,
    4.2849,
    4.316,
    4.34722,
    4.37855,
    4.41,
    4.44155,
    4.47322,
    4.505,
    4.5369,
    4.5689,
    4.60102,
    4.63325,
    4.6656,
    4.69805,
    4.73062,
    4.7633,
    4.7961,
    4.829,
    4.86202,
    4.89515,
    4.9284,
    4.96175,
    4.99522,
    5.0288,
    5.0625,
    5.0963,
    5.13022,
    5.16425,
    5.1984,
    5.23265,
    5.26702,
    5.3015,
    5.3361,
    5.3708,
    5.40562,
    5.44055,
    5.4756,
    5.51075,
    5.54602,
    5.5814,
    5.6169,
    5.6525,
    5.68822,
    5.72405,
    5.76,
    5.79605,
    5.83222,
    5.8685,
    5.9049,
    5.9414,
    5.97802,
    6.01475,
    6.0516,
    6.08855,
    6.12562,
    6.1628,
    6.2001,
    6.2375,
    6.27502,
    6.31265,
    6.3504,
    6.38825,
    6.42622,
    6.4643,
    6.5025,
    6.5408,
    6.57922,
    6.61775,
    6.6564,
    6.69515,
    6.73402,
    6.773,
    6.8121,
    6.8513,
    6.89062,
    6.93005,
    6.9696,
    7.00925,
    7.04902,
    7.0889,
    7.1289,
    7.169,
    7.20922,
    7.24955,
    7.29,
    7.33055,
    7.37122,
    7.412,
    7.4529,
    7.4939,
    7.53502,
    7.57625,
    7.6176,
    7.65905,
    7.70062,
    7.7423,
    7.7841,
    7.826,
    7.86802,
    7.91015,
    7.9524,
    7.99475,
    8.03722,
    8.0798,
    8.1225,
    8.1653,
    8.20822,
    8.25125,
    8.2944,
    8.33765,
    8.38102,
    8.4245,
    8.4681,
    8.5118,
    8.55562,
    8.59955,
    8.6436,
    8.68775,
    8.73202,
    8.7764,
    8.8209,
    8.8655,
    8.91022,
    8.95505,
    9,
    9.04505,
    9.09022,
    9.1355,
    9.1809,
    9.2264,
    9.27202,
    9.31775,
    9.3636,
    9.40955,
    9.45562,
    9.5018,
    9.5481,
    9.5945,
    9.64102,
    9.68765,
    9.7344,
    9.78125,
    9.82822,
    9.8753,
    9.9225,
    9.9698,
    10.01722,
    10.06475,
    10.1124,
    10.16015,
    10.20802,
    10.256,
    10.3041,
    10.3523,
    10.40062,
    10.44905,
    10.4976,
    10.54625,
    10.59502,
    10.6439,
    10.6929,
    10.742,
    10.79122,
    10.84055,
    10.89,
    10.93955,
    10.98922,
    11.039,
    11.0889,
    11.1389,
    11.18902,
    11.23925,
    11.2896,
    11.34005,
    11.39062,
    11.4413,
    11.4921,
    11.543,
    11.59402,
    11.64515,
    11.6964,
    11.74775,
    11.79922,
    11.8508,
    11.9025,
    11.9543,
    12.00622,
    12.05825,
    12.1104,
    12.16265,
    12.21502,
    12.2675,
    12.3201,
    12.3728,
    12.42562,
    12.47855,
    12.5316,
    12.58475,
    12.63802,
    12.6914,
    12.7449,
    12.7985,
    12.85222,
    12.90605,
    12.96,
    13.01405,
    13.06822,
    13.1225,
    13.1769,
    13.2314,
    13.28602,
    13.34075,
    13.3956,
    13.45055,
    13.50562,
    13.5608,
    13.6161,
    13.6715,
    13.72702,
    13.78265,
    13.8384,
    13.89425,
    13.95022,
    14.0063,
    14.0625,
    14.1188,
    14.17522,
    14.23175,
    14.2884,
    14.34515,
    14.40202,
    14.459,
    14.5161,
    14.5733,
    14.63062,
    14.68805,
    14.7456,
    14.80325,
    14.86102,
    14.9189,
    14.9769,
    15.035,
    15.09322,
    15.15155,
    15.21,
    15.26855,
    15.32722,
    15.386,
    15.4449,
    15.5039,
    15.56302,
    15.62225,
    15.6816,
    15.74105,
    15.80062,
    15.8603,
    15.9201,
    15.98,
    16.04002,
    16.10015,
    16.1604,
    16.22075,
    16.28122,
    16.3418,
    16.4025,
    16.4633,
    16.52422,
    16.58525,
    16.6464,
    16.70765,
    16.76902,
    16.8305,
    16.8921,
    16.9538,
    17.01562,
    17.07755,
    17.1396,
    17.20175,
    17.26402,
    17.3264,
    17.3889,
    17.4515,
    17.51422,
    17.57705,
    17.64,
    17.70305,
    17.76622,
    17.8295,
    17.8929,
    17.9564,
    18.02002,
    18.08375,
    18.1476,
    18.21155,
    18.27562,
    18.3398,
    18.4041,
    18.4685,
    18.53302,
    18.59765,
    18.6624,
    18.72725,
    18.79222,
    18.8573,
    18.9225,
    18.9878,
    19.05322,
    19.11875,
    19.1844,
    19.25015,
    19.31602,
    19.382,
    19.4481,
    19.5143,
    19.58062,
    19.64705,
    19.7136,
    19.78025,
    19.84702,
    19.9139,
    19.9809,
    20.048,
    20.11522,
    20.18255,
    20.25,
    20.31755,
    20.38522,
    20.453,
    20.5209,
    20.5889,
    20.65702,
    20.72525,
    20.7936,
    20.86205,
    20.93062,
    20.9993,
    21.0681,
    21.137,
    21.20602,
    21.27515,
    21.3444,
    21.41375,
    21.48322,
    21.5528,
    21.6225,
    21.6923,
    21.76222,
    21.83225,
    21.9024,
    21.97265,
    22.04302,
    22.1135,
    22.1841,
    22.2548,
    22.32562,
    22.39655,
    22.4676,
    22.53875,
    22.61002,
    22.6814,
    22.7529,
    22.8245,
    22.89622,
    22.96805,
    23.04,
    23.11205,
    23.18422,
    23.2565,
    23.3289,
    23.4014,
    23.47402,
    23.54675,
    23.6196,
    23.69255,
    23.76562,
    23.8388,
    23.9121,
    23.9855,
    24.05902,
    24.13265,
    24.2064,
    24.28025,
    24.35422,
    24.4283,
    24.5025,
    24.5768,
    24.65122,
    24.72575,
    24.8004,
    24.87515,
    24.95002,
    25.025,
    25.1001,
    25.1753,
    25.25062,
    25.32605,
    25.4016,
    25.47725,
    25.55302,
    25.6289,
    25.7049,
    25.781,
    25.85722,
    25.93355,
    26.01,
    26.08655,
    26.16322,
    26.24,
    26.3169,
    26.3939,
    26.47102,
    26.54825,
    26.6256,
    26.70305,
    26.78062,
    26.8583,
    26.9361,
    27.014,
    27.09202,
    27.17015,
    27.2484,
    27.32675,
    27.40522,
    27.4838,
    27.5625,
    27.6413,
    27.72022,
    27.79925,
    27.8784,
    27.95765,
    28.03702,
    28.1165,
    28.1961,
    28.2758,
    28.35562,
    28.43555,
    28.5156,
    28.59575,
    28.67602,
    28.7564,
    28.8369,
    28.9175,
    28.99822,
    29.07905,
    29.16,
    29.24105,
    29.32222,
    29.4035,
    29.4849,
    29.5664,
    29.64802,
    29.72975,
    29.8116,
    29.89355,
    29.97562,
    30.0578,
    30.1401,
    30.2225,
    30.30502,
    30.38765,
    30.4704,
    30.55325,
    30.63622,
    30.7193,
    30.8025,
    30.8858,
    30.96922,
    31.05275,
    31.1364,
    31.22015,
    31.30402,
    31.388,
    31.4721,
    31.5563,
    31.64062,
    31.72505,
    31.8096,
    31.89425,
    31.97902,
    32.0639,
    32.1489,
    32.234,
    32.31922,
    32.40455,
    32.49,
    32.57555,
    32.66122,
    32.747,
    32.8329,
    32.9189,
    33.00502,
    33.09125,
    33.1776,
    33.26405,
    33.35062,
    33.4373,
    33.5241,
    33.611,
    33.69802,
    33.78515,
    33.8724,
    33.95975,
    34.04722,
    34.1348,
    34.2225,
    34.3103,
    34.39822,
    34.48625,
    34.5744,
    34.66265,
    34.75102,
    34.8395,
    34.9281,
    35.0168,
    35.10562,
    35.19455,
    35.2836,
    35.37275,
    35.46202,
    35.5514,
    35.6409,
    35.7305,
    35.82022,
    35.91005,
    36,
    36.09005,
    36.18022,
    36.2705,
    36.3609,
    36.4514,
    36.54202,
    36.63275,
    36.7236,
    36.81455,
    36.90562,
    36.9968,
    37.0881,
    37.1795,
    37.27102,
    37.36265,
    37.4544,
    37.54625,
    37.63822,
    37.7303,
    37.8225,
    37.9148,
    38.00722,
    38.09975,
    38.1924,
    38.28515,
    38.37802,
    38.471,
    38.5641,
    38.6573,
    38.75062,
    38.84405,
    38.9376,
    39.03125,
    39.12502,
    39.2189,
    39.3129,
    39.407,
    39.50122,
    39.59555,
    39.69,
    39.78455,
    39.87922,
    39.974,
    40.0689,
    40.1639,
    40.25902,
    40.35425,
    40.4496,
    40.54505,
    40.64062,
    40.7363,
    40.8321,
    40.928,
    41.02402,
    41.12015,
    41.2164,
    41.31275,
    41.40922,
    41.5058,
    41.6025,
    41.6993,
    41.79622,
    41.89325,
    41.9904,
    42.08765,
    42.18502,
    42.2825,
    42.3801,
    42.4778,
    42.57562,
    42.67355,
    42.7716,
    42.86975,
    42.96802,
    43.0664,
    43.1649,
    43.2635,
    43.36222,
    43.46105,
    43.56,
    43.65905,
    43.75822,
    43.8575,
    43.9569,
    44.0564,
    44.15602,
    44.25575,
    44.3556,
    44.45555,
    44.55562,
    44.6558,
    44.7561,
    44.8565,
    44.95702,
    45.05765,
    45.1584,
    45.25925,
    45.36022,
    45.4613,
    45.5625,
    45.6638,
    45.76522,
    45.86675,
    45.9684,
    46.07015,
    46.17202,
    46.274,
    46.3761,
    46.4783,
    46.58062,
    46.68305,
    46.7856,
    46.88825,
    46.99102,
    47.0939,
    47.1969,
    47.3,
    47.40322,
    47.50655,
    47.61,
    47.71355,
    47.81722,
    47.921,
    48.0249,
    48.1289,
    48.23302,
    48.33725,
    48.4416,
    48.54605,
    48.65062,
    48.7553,
    48.8601,
    48.965,
    49.07002,
    49.17515,
    49.2804,
    49.38575,
    49.49122,
    49.5968,
    49.7025,
    49.8083,
    49.91422,
    50.02025,
    50.1264,
    50.23265,
    50.33902,
    50.4455,
    50.5521,
    50.6588,
    50.76562,
    50.87255,
    50.9796,
    51.08675,
    51.19402,
    51.3014,
    51.4089,
    51.5165,
    51.62422,
    51.73205,
    51.84,
    51.94805,
    52.05622,
    52.1645,
    52.2729,
    52.3814,
    52.49002,
    52.59875,
    52.7076,
    52.81655,
    52.92562,
    53.0348,
    53.1441,
    53.2535,
    53.36302,
    53.47265,
    53.5824,
    53.69225,
    53.80222,
    53.9123,
    54.0225,
    54.1328,
    54.24322,
    54.35375,
    54.4644,
    54.57515,
    54.68602,
    54.797,
    54.9081,
    55.0193,
    55.13062,
    55.24205,
    55.3536,
    55.46525,
    55.57702,
    55.6889,
    55.8009,
    55.913,
    56.02522,
    56.13755,
    56.25,
    56.36255,
    56.47522,
    56.588,
    56.7009,
    56.8139,
    56.92702,
    57.04025,
    57.1536,
    57.26705,
    57.38062,
    57.4943,
    57.6081,
    57.722,
    57.83602,
    57.95015,
    58.0644,
    58.17875,
    58.29322,
    58.4078,
    58.5225,
    58.6373,
    58.75222,
    58.86725,
    58.9824,
    59.09765,
    59.21302,
    59.3285,
    59.4441,
    59.5598,
    59.67562,
    59.79155,
    59.9076,
    60.02375,
    60.14002,
    60.2564,
    60.3729,
    60.4895,
    60.60622,
    60.72305,
    60.84,
    60.95705,
    61.07422,
    61.1915,
    61.3089,
    61.4264,
    61.54402,
    61.66175,
    61.7796,
    61.89755,
    62.01562,
    62.1338,
    62.2521,
    62.3705,
    62.48902,
    62.60765,
    62.7264,
    62.84525,
    62.96422,
    63.0833,
    63.2025,
    63.3218,
    63.44122,
    63.56075,
    63.6804,
    63.80015,
    63.92002,
    64.04,
    64.1601,
    64.2803,
    64.40062,
    64.52105,
    64.6416,
    64.76225,
    64.88302,
    65.0039,
    65.1249,
    65.246,
    65.36722,
    65.48855,
    65.61,
    65.73155,
    65.85322,
    65.975,
    66.0969,
    66.2189,
    66.34102,
    66.46325,
    66.5856,
    66.70805,
    66.83062,
    66.9533,
    67.0761,
    67.199,
    67.32202,
    67.44515,
    67.5684,
    67.69175,
    67.81522,
    67.9388,
    68.0625,
    68.1863,
    68.31022,
    68.43425,
    68.5584,
    68.68265,
    68.80702,
    68.9315,
    69.0561,
    69.1808,
    69.30562,
    69.43055,
    69.5556,
    69.68075,
    69.80602,
    69.9314,
    70.0569,
    70.1825,
    70.30822,
    70.43405,
    70.56,
    70.68605,
    70.81222,
    70.9385,
    71.0649,
    71.1914,
    71.31802,
    71.44475,
    71.5716,
    71.69855,
    71.82562,
    71.9528,
    72.0801,
    72.2075,
    72.33502,
    72.46265,
    72.5904,
    72.71825,
    72.84622,
    72.9743,
    73.1025,
    73.2308,
    73.35922,
    73.48775,
    73.6164,
    73.74515,
    73.87402,
    74.003,
    74.1321,
    74.2613,
    74.39062,
    74.52005,
    74.6496,
    74.77925,
    74.90902,
    75.0389,
    75.1689,
    75.299,
    75.42922,
    75.55955,
    75.69,
    75.82055,
    75.95122,
    76.082,
    76.2129,
    76.3439,
    76.47502,
    76.60625,
    76.7376,
    76.86905,
    77.00062,
    77.1323,
    77.2641,
    77.396,
    77.52802,
    77.66015,
    77.7924,
    77.92475,
    78.05722,
    78.1898,
    78.3225,
    78.4553,
    78.58822,
    78.72125,
    78.8544,
    78.98765,
    79.12102,
    79.2545,
    79.3881,
    79.5218,
    79.65562,
    79.78955,
    79.9236,
    80.05775,
    80.19202,
    80.3264,
    80.4609,
    80.5955,
    80.73022,
    80.86505,
    81,
    81.13505,
    81.27022,
    81.4055,
    81.5409,
    81.6764,
    81.81202,
    81.94775,
    82.0836,
    82.21955,
    82.35562,
    82.4918,
    82.6281,
    82.7645,
    82.90102,
    83.03765,
    83.1744,
    83.31125,
    83.44822,
    83.5853,
    83.7225,
    83.8598,
    83.99722,
    84.13475,
    84.2724,
    84.41015,
    84.54802,
    84.686,
    84.8241,
    84.9623,
    85.10062,
    85.23905,
    85.3776,
    85.51625,
    85.65502,
    85.7939,
    85.9329,
    86.072,
    86.21122,
    86.35055,
    86.49,
    86.62955,
    86.76922,
    86.909,
    87.0489,
    87.1889,
    87.32902,
    87.46925,
    87.6096,
    87.75005,
    87.89062,
    88.0313,
    88.1721,
    88.313,
    88.45402,
    88.59515,
    88.7364,
    88.87775,
    89.01922,
    89.1608,
    89.3025,
    89.4443,
    89.58622,
    89.72825,
    89.8704,
    90.01265,
    90.15502,
    90.2975,
    90.4401,
    90.5828,
    90.72562,
    90.86855,
    91.0116,
    91.15475,
    91.29802,
    91.4414,
    91.5849,
    91.7285,
    91.87222,
    92.01605,
    92.16,
    92.30405,
    92.44822,
    92.5925,
    92.7369,
    92.8814,
    93.02602,
    93.17075,
    93.3156,
    93.46055,
    93.60562,
    93.7508,
    93.8961,
    94.0415,
    94.18702,
    94.33265,
    94.4784,
    94.62425,
    94.77022,
    94.9163,
    95.0625,
    95.2088,
    95.35522,
    95.50175,
    95.6484,
    95.79515,
    95.94202,
    96.089,
    96.2361,
    96.3833,
    96.53062,
    96.67805,
    96.8256,
    96.97325,
    97.12102,
    97.2689,
    97.4169,
    97.565,
    97.71322,
    97.86155,
    98.01,
    98.15855,
    98.30722,
    98.456,
    98.6049,
    98.7539,
    98.90302,
    99.05225,
    99.2016,
    99.35105,
    99.50062,
    99.6503,
    99.8001,
    99.95,
    100.10002,
    100.25015,
    100.4004,
    100.55075,
    100.70122,
    100.8518,
    101.0025,
    101.1533,
    101.30422,
    101.45525,
    101.6064,
    101.75765,
    101.90902,
    102.0605,
    102.2121,
    102.3638,
    102.51562,
    102.66755,
    102.8196,
    102.97175,
    103.12402,
    103.2764,
    103.4289,
    103.5815,
    103.73422,
    103.88705,
    104.04,
    104.19305,
    104.34622,
    104.4995,
    104.6529,
    104.8064,
    104.96002,
    105.11375,
    105.2676,
    105.42155,
    105.57562,
    105.7298,
    105.8841,
    106.0385,
    106.19302,
    106.34765,
    106.5024,
    106.65725,
    106.81222,
    106.9673,
    107.1225,
    107.2778,
    107.43322,
    107.58875,
    107.7444,
    107.90015,
    108.05602,
    108.212,
    108.3681,
    108.5243,
    108.68062,
    108.83705,
    108.9936,
    109.15025,
    109.30702,
    109.4639,
    109.6209,
    109.778,
    109.93522,
    110.09255,
    110.25,
    110.40755,
    110.56522,
    110.723,
    110.8809,
    111.0389,
    111.19702,
    111.35525,
    111.5136,
    111.67205,
    111.83062,
    111.9893,
    112.1481,
    112.307,
    112.46602,
    112.62515,
    112.7844,
    112.94375,
    113.10322,
    113.2628,
    113.4225,
    113.5823,
    113.74222,
    113.90225,
    114.0624,
    114.22265,
    114.38302,
    114.5435,
    114.7041,
    114.8648,
    115.02562,
    115.18655,
    115.3476,
    115.50875,
    115.67002,
    115.8314,
    115.9929,
    116.1545,
    116.31622,
    116.47805,
    116.64,
    116.80205,
    116.96422,
    117.1265,
    117.2889,
    117.4514,
    117.61402,
    117.77675,
    117.9396,
    118.10255,
    118.26562,
    118.4288,
    118.5921,
    118.7555,
    118.91902,
    119.08265,
    119.2464,
    119.41025,
    119.57422,
    119.7383,
    119.9025,
    120.0668,
    120.23122,
    120.39575,
    120.5604,
    120.72515,
    120.89002,
    121.055,
    121.2201,
    121.3853,
    121.55062,
    121.71605,
    121.8816,
    122.04725,
    122.21302,
    122.3789,
    122.5449,
    122.711,
    122.87722,
    123.04355,
    123.21,
    123.37655,
    123.54322,
    123.71,
    123.8769,
    124.0439,
    124.21102,
    124.37825,
    124.5456,
    124.71305,
    124.88062,
    125.0483,
    125.2161,
    125.384,
    125.55202,
    125.72015,
    125.8884,
    126.05675,
    126.22522,
    126.3938,
    126.5625,
    126.7313,
    126.90022,
    127.06925,
    127.2384,
    127.40765,
    127.57702,
    127.7465,
    127.9161,
    128.0858,
    128.25562,
    128.42555,
    128.5956,
    128.76575,
    128.93602,
    129.1064,
    129.2769,
    129.4475,
    129.61822,
    129.78905,
    129.96,
    130.13105,
    130.30222,
    130.4735,
    130.6449,
    130.8164,
    130.98802,
    131.15975,
    131.3316,
    131.50355,
    131.67562,
    131.8478,
    132.0201,
    132.1925,
    132.36502,
    132.53765,
    132.7104,
    132.88325,
    133.05622,
    133.2293,
    133.4025,
    133.5758,
    133.74922,
    133.92275,
    134.0964,
    134.27015,
    134.44402,
    134.618,
    134.7921,
    134.9663,
    135.14062,
    135.31505,
    135.4896,
    135.66425,
    135.83902,
    136.0139,
    136.1889,
    136.364,
    136.53922,
    136.71455,
    136.89,
    137.06555,
    137.24122,
    137.417,
    137.5929,
    137.7689,
    137.94502,
    138.12125,
    138.2976,
    138.47405,
    138.65062,
    138.8273,
    139.0041,
    139.181,
    139.35802,
    139.53515,
    139.7124,
    139.88975,
    140.06722,
    140.2448,
    140.4225,
    140.6003,
    140.77822,
    140.95625,
    141.1344,
    141.31265,
    141.49102,
    141.6695,
    141.8481,
    142.0268,
    142.20562,
    142.38455,
    142.5636,
    142.74275,
    142.92202,
    143.1014,
    143.2809,
    143.4605,
    143.64022,
    143.82005,
    144,
    144.18005,
    144.36022,
    144.5405,
    144.7209,
    144.9014,
    145.08202,
    145.26275,
    145.4436,
    145.62455,
    145.80562,
    145.9868,
    146.1681,
    146.3495,
    146.53102,
    146.71265,
    146.8944,
    147.07625,
    147.25822,
    147.4403,
    147.6225,
    147.8048,
    147.98722,
    148.16975,
    148.3524,
    148.53515,
    148.71802,
    148.901,
    149.0841,
    149.2673,
    149.45062,
    149.63405,
    149.8176,
    150.00125,
    150.18502,
    150.3689,
    150.5529,
    150.737,
    150.92122,
    151.10555,
    151.29,
    151.47455,
    151.65922,
    151.844,
    152.0289,
    152.2139,
    152.39902,
    152.58425,
    152.7696,
    152.95505,
    153.14062,
    153.3263,
    153.5121,
    153.698,
    153.88402,
    154.07015,
    154.2564,
    154.44275,
    154.62922,
    154.8158,
    155.0025,
    155.1893,
    155.37622,
    155.56325,
    155.7504,
    155.93765,
    156.12502,
    156.3125,
    156.5001,
    156.6878,
    156.87562,
    157.06355,
    157.2516,
    157.43975,
    157.62802,
    157.8164,
    158.0049,
    158.1935,
    158.38222,
    158.57105,
    158.76,
    158.94905,
    159.13822,
    159.3275,
    159.5169,
    159.7064,
    159.89602,
    160.08575,
    160.2756,
    160.46555,
    160.65562,
    160.8458,
    161.0361,
    161.2265,
    161.41702,
    161.60765,
    161.7984,
    161.98925,
    162.18022,
    162.3713,
    162.5625,
    162.7538,
    162.94522,
    163.13675,
    163.3284,
    163.52015,
    163.71202,
    163.904,
    164.0961,
    164.2883,
    164.48062,
    164.67305,
    164.8656,
    165.05825,
    165.25102,
    165.4439,
    165.6369,
    165.83,
    166.02322,
    166.21655,
    166.41,
    166.60355,
    166.79722,
    166.991,
    167.1849,
    167.3789,
    167.57302,
    167.76725,
    167.9616,
    168.15605,
    168.35062,
    168.5453,
    168.7401,
    168.935,
    169.13002,
    169.32515,
    169.5204,
    169.71575,
    169.91122,
    170.1068,
    170.3025,
    170.4983,
    170.69422,
    170.89025,
    171.0864,
    171.28265,
    171.47902,
    171.6755,
    171.8721,
    172.0688,
    172.26562,
    172.46255,
    172.6596,
    172.85675,
    173.05402,
    173.2514,
    173.4489,
    173.6465,
    173.84422,
    174.04205,
    174.24,
    174.43805,
    174.63622,
    174.8345,
    175.0329,
    175.2314,
    175.43002,
    175.62875,
    175.8276,
    176.02655,
    176.22562,
    176.4248,
    176.6241,
    176.8235,
    177.02302,
    177.22265,
    177.4224,
    177.62225,
    177.82222,
    178.0223,
    178.2225,
    178.4228,
    178.62322,
    178.82375,
    179.0244,
    179.22515,
    179.42602,
    179.627,
    179.8281,
    180.0293,
    180.23062,
    180.43205,
    180.6336,
    180.83525,
    181.03702,
    181.2389,
    181.4409,
    181.643,
    181.84522,
    182.04755,
    182.25,
    182.45255,
    182.65522,
    182.858,
    183.0609,
    183.2639,
    183.46702,
    183.67025,
    183.8736,
    184.07705,
    184.28062,
    184.4843,
    184.6881,
    184.892,
    185.09602,
    185.30015,
    185.5044,
    185.70875,
    185.91322,
    186.1178,
    186.3225,
    186.5273,
    186.73222,
    186.93725,
    187.1424,
    187.34765,
    187.55302,
    187.7585,
    187.9641,
    188.1698,
    188.37562,
    188.58155,
    188.7876,
    188.99375,
    189.20002,
    189.4064,
    189.6129,
    189.8195,
    190.02622,
    190.23305,
    190.44,
    190.64705,
    190.85422,
    191.0615,
    191.2689,
    191.4764,
    191.68402,
    191.89175,
    192.0996,
    192.30755,
    192.51562,
    192.7238,
    192.9321,
    193.1405,
    193.34902,
    193.55765,
    193.7664,
    193.97525,
    194.18422,
    194.3933,
    194.6025,
    194.8118,
    195.02122,
    195.23075,
    195.4404,
    195.65015,
    195.86002,
    196.07,
    196.2801,
    196.4903,
    196.70062,
    196.91105,
    197.1216,
    197.33225,
    197.54302,
    197.7539,
    197.9649,
    198.176,
    198.38722,
    198.59855,
    198.81,
    199.02155,
    199.23322,
    199.445,
    199.6569,
    199.8689,
    200.08102,
    200.29325,
    200.5056,
    200.71805,
    200.93062,
    201.1433,
    201.3561,
    201.569,
    201.78202,
    201.99515,
    202.2084,
    202.42175,
    202.63522,
    202.8488,
    203.0625,
    203.2763,
    203.49022,
    203.70425,
    203.9184,
    204.13265,
    204.34702,
    204.5615,
    204.7761,
    204.9908,
    205.20562,
    205.42055,
    205.6356,
    205.85075,
    206.06602,
    206.2814,
    206.4969,
    206.7125,
    206.92822,
    207.14405,
    207.36,
    207.57605,
    207.79222,
    208.0085,
    208.2249,
    208.4414,
    208.65802,
    208.87475,
    209.0916,
    209.30855,
    209.52562,
    209.7428,
    209.9601,
    210.1775,
    210.39502,
    210.61265,
    210.8304,
    211.04825,
    211.26622,
    211.4843,
    211.7025,
    211.9208,
    212.13922,
    212.35775,
    212.5764,
    212.79515,
    213.01402,
    213.233,
    213.4521,
    213.6713,
    213.89062,
    214.11005,
    214.3296,
    214.54925,
    214.76902,
    214.9889,
    215.2089,
    215.429,
    215.64922,
    215.86955,
    216.09,
    216.31055,
    216.53122,
    216.752,
    216.9729,
    217.1939,
    217.41502,
    217.63625,
    217.8576,
    218.07905,
    218.30062,
    218.5223,
    218.7441,
    218.966,
    219.18802,
    219.41015,
    219.6324,
    219.85475,
    220.07722,
    220.2998,
    220.5225,
    220.7453,
    220.96822,
    221.19125,
    221.4144,
    221.63765,
    221.86102,
    222.0845,
    222.3081,
    222.5318,
    222.75562,
    222.97955,
    223.2036,
    223.42775,
    223.65202,
    223.8764,
    224.1009,
    224.3255,
    224.55022,
    224.77505,
    225,
    225.22505,
    225.45022,
    225.6755,
    225.9009,
    226.1264,
    226.35202,
    226.57775,
    226.8036,
    227.02955,
    227.25562,
    227.4818,
    227.7081,
    227.9345,
    228.16102,
    228.38765,
    228.6144,
    228.84125,
    229.06822,
    229.2953,
    229.5225,
    229.7498,
    229.97722,
    230.20475,
    230.4324,
    230.66015,
    230.88802,
    231.116,
    231.3441,
    231.5723,
    231.80062,
    232.02905,
    232.2576,
    232.48625,
    232.71502,
    232.9439,
    233.1729,
    233.402,
    233.63122,
    233.86055,
    234.09,
    234.31955,
    234.54922,
    234.779,
    235.0089,
    235.2389,
    235.46902,
    235.69925,
    235.9296,
    236.16005,
    236.39062,
    236.6213,
    236.8521,
    237.083,
    237.31402,
    237.54515,
    237.7764,
    238.00775,
    238.23922,
    238.4708,
    238.7025,
    238.9343,
    239.16622,
    239.39825,
    239.6304,
    239.86265,
    240.09502,
    240.3275,
    240.5601,
    240.7928,
    241.02562,
    241.25855,
    241.4916,
    241.72475,
    241.95802,
    242.1914,
    242.4249,
    242.6585,
    242.89222,
    243.12605,
    243.36,
    243.59405,
    243.82822,
    244.0625,
    244.2969,
    244.5314,
    244.76602,
    245.00075,
    245.2356,
    245.47055,
    245.70562,
    245.9408,
    246.1761,
    246.4115,
    246.64702,
    246.88265,
    247.1184,
    247.35425,
    247.59022,
    247.8263,
    248.0625,
    248.2988,
    248.53522,
    248.77175,
    249.0084,
    249.24515,
    249.48202,
    249.719,
    249.9561,
    250.1933,
    250.43062,
    250.66805,
    250.9056,
    251.14325,
    251.38102,
    251.6189,
    251.8569,
    252.095,
    252.33322,
    252.57155,
    252.81,
    253.04855,
    253.28722,
    253.526,
    253.7649,
    254.0039,
    254.24302,
    254.48225,
    254.7216,
    254.96105,
    255.20062,
    255.4403,
    255.6801,
    255.92,
    256.16002,
    256.40015,
    256.6404,
    256.88075,
    257.12122,
    257.3618,
    257.6025,
    257.8433,
    258.08422,
    258.32525,
    258.5664,
    258.80765,
    259.04902,
    259.2905,
    259.5321,
    259.7738,
    260.01562,
    260.25755,
    260.4996,
    260.74175,
    260.98402,
    261.2264,
    261.4689,
    261.7115,
    261.95422,
    262.19705,
    262.44,
    262.68305,
    262.92622,
    263.1695,
    263.4129,
    263.6564,
    263.90002,
    264.14375,
    264.3876,
    264.63155,
    264.87562,
    265.1198,
    265.3641,
    265.6085,
    265.85302,
    266.09765,
    266.3424,
    266.58725,
    266.83222,
    267.0773,
    267.3225,
    267.5678,
    267.81322,
    268.05875,
    268.3044,
    268.55015,
    268.79602,
    269.042,
    269.2881,
    269.5343,
    269.78062,
    270.02705,
    270.2736,
    270.52025,
    270.76702,
    271.0139,
    271.2609,
    271.508,
    271.75522,
    272.00255,
    272.25,
    272.49755,
    272.74522,
    272.993,
    273.2409,
    273.4889,
    273.73702,
    273.98525,
    274.2336,
    274.48205,
    274.73062,
    274.9793,
    275.2281,
    275.477,
    275.72602,
    275.97515,
    276.2244,
    276.47375,
    276.72322,
    276.9728,
    277.2225,
    277.4723,
    277.72222,
    277.97225,
    278.2224,
    278.47265,
    278.72302,
    278.9735,
    279.2241,
    279.4748,
    279.72562,
    279.97655,
    280.2276,
    280.47875,
    280.73002,
    280.9814,
    281.2329,
    281.4845,
    281.73622,
    281.98805,
    282.24,
    282.49205,
    282.74422,
    282.9965,
    283.2489,
    283.5014,
    283.75402,
    284.00675,
    284.2596,
    284.51255,
    284.76562,
    285.0188,
    285.2721,
    285.5255,
    285.77902,
    286.03265,
    286.2864,
    286.54025,
    286.79422,
    287.0483,
    287.3025,
    287.5568,
    287.81122,
    288.06575,
    288.3204,
    288.57515,
    288.83002,
    289.085,
    289.3401,
    289.5953,
    289.85062,
    290.10605,
    290.3616,
    290.61725,
    290.87302,
    291.1289,
    291.3849,
    291.641,
    291.89722,
    292.15355,
    292.41,
    292.66655,
    292.92322,
    293.18,
    293.4369,
    293.6939,
    293.95102,
    294.20825,
    294.4656,
    294.72305,
    294.98062,
    295.2383,
    295.4961,
    295.754,
    296.01202,
    296.27015,
    296.5284,
    296.78675,
    297.04522,
    297.3038,
    297.5625,
    297.8213,
    298.08022,
    298.33925,
    298.5984,
    298.85765,
    299.11702,
    299.3765,
    299.6361,
    299.8958,
    300.15562,
    300.41555,
    300.6756,
    300.93575,
    301.19602,
    301.4564,
    301.7169,
    301.9775,
    302.23822,
    302.49905,
    302.76,
    303.02105,
    303.28222,
    303.5435,
    303.8049,
    304.0664,
    304.32802,
    304.58975,
    304.8516,
    305.11355,
    305.37562,
    305.6378,
    305.9001,
    306.1625,
    306.42502,
    306.68765,
    306.9504,
    307.21325,
    307.47622,
    307.7393,
    308.0025,
    308.2658,
    308.52922,
    308.79275,
    309.0564,
    309.32015,
    309.58402,
    309.848,
    310.1121,
    310.3763,
    310.64062,
    310.90505,
    311.1696,
    311.43425,
    311.69902,
    311.9639,
    312.2289,
    312.494,
    312.75922,
    313.02455,
    313.29,
    313.55555,
    313.82122,
    314.087,
    314.3529,
    314.6189,
    314.88502,
    315.15125,
    315.4176,
    315.68405,
    315.95062,
    316.2173,
    316.4841,
    316.751,
    317.01802,
    317.28515,
    317.5524,
    317.81975,
    318.08722,
    318.3548,
    318.6225,
    318.8903,
    319.15822,
    319.42625,
    319.6944,
    319.96265,
    320.23102,
    320.4995,
    320.7681,
    321.0368,
    321.30562,
    321.57455,
    321.8436,
    322.11275,
    322.38202,
    322.6514,
    322.9209,
    323.1905,
    323.46022,
    323.73005,
    324,
    324.27005,
    324.54022,
    324.8105,
    325.0809,
    325.3514,
    325.62202,
    325.89275,
    326.1636,
    326.43455,
    326.70562,
    326.9768,
    327.2481,
    327.5195,
    327.79102,
    328.06265,
    328.3344,
    328.60625,
    328.87822,
    329.1503,
    329.4225,
    329.6948,
    329.96722,
    330.23975,
    330.5124,
    330.78515,
    331.05802,
    331.331,
    331.6041,
    331.8773,
    332.15062,
    332.42405,
    332.6976,
    332.97125,
    333.24502,
    333.5189,
    333.7929,
    334.067,
    334.34122,
    334.61555,
    334.89,
    335.16455,
    335.43922,
    335.714,
    335.9889,
    336.2639,
    336.53902,
    336.81425,
    337.0896,
    337.36505,
    337.64062,
    337.9163,
    338.1921,
    338.468,
    338.74402,
    339.02015,
    339.2964,
    339.57275,
    339.84922,
    340.1258,
    340.4025,
    340.6793,
    340.95622,
    341.23325,
    341.5104,
    341.78765,
    342.06502,
    342.3425,
    342.6201,
    342.8978,
    343.17562,
    343.45355,
    343.7316,
    344.00975,
    344.28802,
    344.5664,
    344.8449,
    345.1235,
    345.40222,
    345.68105,
    345.96,
    346.23905,
    346.51822,
    346.7975,
    347.0769,
    347.3564,
    347.63602,
    347.91575,
    348.1956,
    348.47555,
    348.75562,
    349.0358,
    349.3161,
    349.5965,
    349.87702,
    350.15765,
    350.4384,
    350.71925,
    351.00022,
    351.2813,
    351.5625,
    351.8438,
    352.12522,
    352.40675,
    352.6884,
    352.97015,
    353.25202,
    353.534,
    353.8161,
    354.0983,
    354.38062,
    354.66305,
    354.9456,
    355.22825,
    355.51102,
    355.7939,
    356.0769,
    356.36,
    356.64322,
    356.92655,
    357.21,
    357.49355,
    357.77722,
    358.061,
    358.3449,
    358.6289,
    358.91302,
    359.19725,
    359.4816,
    359.76605,
    360.05062,
    360.3353,
    360.6201,
    360.905,
    361.19002,
    361.47515,
    361.7604,
    362.04575,
    362.33122,
    362.6168,
    362.9025,
    363.1883,
    363.47422,
    363.76025,
    364.0464,
    364.33265,
    364.61902,
    364.9055,
    365.1921,
    365.4788,
    365.76562,
    366.05255,
    366.3396,
    366.62675,
    366.91402,
    367.2014,
    367.4889,
    367.7765,
    368.06422,
    368.35205,
    368.64,
    368.92805,
    369.21622,
    369.5045,
    369.7929,
    370.0814,
    370.37002,
    370.65875,
    370.9476,
    371.23655,
    371.52562,
    371.8148,
    372.1041,
    372.3935,
    372.68302,
    372.97265,
    373.2624,
    373.55225,
    373.84222,
    374.1323,
    374.4225,
    374.7128,
    375.00322,
    375.29375,
    375.5844,
    375.87515,
    376.16602,
    376.457,
    376.7481,
    377.0393,
    377.33062,
    377.62205,
    377.9136,
    378.20525,
    378.49702,
    378.7889,
    379.0809,
    379.373,
    379.66522,
    379.95755,
    380.25,
    380.54255,
    380.83522,
    381.128,
    381.4209,
    381.7139,
    382.00702,
    382.30025,
    382.5936,
    382.88705,
    383.18062,
    383.4743,
    383.7681,
    384.062,
    384.35602,
    384.65015,
    384.9444,
    385.23875,
    385.53322,
    385.8278,
    386.1225,
    386.4173,
    386.71222,
    387.00725,
    387.3024,
    387.59765,
    387.89302,
    388.1885,
    388.4841,
    388.7798,
    389.07562,
    389.37155,
    389.6676,
    389.96375,
    390.26002,
    390.5564,
    390.8529,
    391.1495,
    391.44622,
    391.74305,
    392.04,
    392.33705,
    392.63422,
    392.9315,
    393.2289,
    393.5264,
    393.82402,
    394.12175,
    394.4196,
    394.71755,
    395.01562,
    395.3138,
    395.6121,
    395.9105,
    396.20902,
    396.50765,
    396.8064,
    397.10525,
    397.40422,
    397.7033,
    398.0025,
    398.3018,
    398.60122,
    398.90075,
    399.2004,
    399.50015,
    399.80002,
    400.1,
    400.4001,
    400.7003,
    401.00062,
    401.30105,
    401.6016,
    401.90225,
    402.20302,
    402.5039,
    402.8049,
    403.106,
    403.40722,
    403.70855,
    404.01,
    404.31155,
    404.61322,
    404.915,
    405.2169,
    405.5189,
    405.82102,
    406.12325,
    406.4256,
    406.72805,
    407.03062,
    407.3333,
    407.6361,
    407.939,
    408.24202,
    408.54515,
    408.8484,
    409.15175,
    409.45522,
    409.7588,
    410.0625,
    410.3663,
    410.67022,
    410.97425,
    411.2784,
    411.58265,
    411.88702,
    412.1915,
    412.4961,
    412.8008,
    413.10562,
    413.41055,
    413.7156,
    414.02075,
    414.32602,
    414.6314,
    414.9369,
    415.2425,
    415.54822,
    415.85405,
    416.16,
    416.46605,
    416.77222,
    417.0785,
    417.3849,
    417.6914,
    417.99802,
    418.30475,
    418.6116,
    418.91855,
    419.22562,
    419.5328,
    419.8401,
    420.1475,
    420.45502,
    420.76265,
    421.0704,
    421.37825,
    421.68622,
    421.9943,
    422.3025,
    422.6108,
    422.91922,
    423.22775,
    423.5364,
    423.84515,
    424.15402,
    424.463,
    424.7721,
    425.0813,
    425.39062,
    425.70005,
    426.0096,
    426.31925,
    426.62902,
    426.9389,
    427.2489,
    427.559,
    427.86922,
    428.17955,
    428.49,
    428.80055,
    429.11122,
    429.422,
    429.7329,
    430.0439,
    430.35502,
    430.66625,
    430.9776,
    431.28905,
    431.60062,
    431.9123,
    432.2241,
    432.536,
    432.84802,
    433.16015,
    433.4724,
    433.78475,
    434.09722,
    434.4098,
    434.7225,
    435.0353,
    435.34822,
    435.66125,
    435.9744,
    436.28765,
    436.60102,
    436.9145,
    437.2281,
    437.5418,
    437.85562,
    438.16955,
    438.4836,
    438.79775,
    439.11202,
    439.4264,
    439.7409,
    440.0555,
    440.37022,
    440.68505,
    441,
    441.31505,
    441.63022,
    441.9455,
    442.2609,
    442.5764,
    442.89202,
    443.20775,
    443.5236,
    443.83955,
    444.15562,
    444.4718,
    444.7881,
    445.1045,
    445.42102,
    445.73765,
    446.0544,
    446.37125,
    446.68822,
    447.0053,
    447.3225,
    447.6398,
    447.95722,
    448.27475,
    448.5924,
    448.91015,
    449.22802,
    449.546,
    449.8641,
    450.1823,
    450.50062,
    450.81905,
    451.1376,
    451.45625,
    451.77502,
    452.0939,
    452.4129,
    452.732,
    453.05122,
    453.37055,
    453.69,
    454.00955,
    454.32922,
    454.649,
    454.9689,
    455.2889,
    455.60902,
    455.92925,
    456.2496,
    456.57005,
    456.89062,
    457.2113,
    457.5321,
    457.853,
    458.17402,
    458.49515,
    458.8164,
    459.13775,
    459.45922,
    459.7808,
    460.1025,
    460.4243,
    460.74622,
    461.06825,
    461.3904,
    461.71265,
    462.03502,
    462.3575,
    462.6801,
    463.0028,
    463.32562,
    463.64855,
    463.9716,
    464.29475,
    464.61802,
    464.9414,
    465.2649,
    465.5885,
    465.91222,
    466.23605,
    466.56,
    466.88405,
    467.20822,
    467.5325,
    467.8569,
    468.1814,
    468.50602,
    468.83075,
    469.1556,
    469.48055,
    469.80562,
    470.1308,
    470.4561,
    470.7815,
    471.10702,
    471.43265,
    471.7584,
    472.08425,
    472.41022,
    472.7363,
    473.0625,
    473.3888,
    473.71522,
    474.04175,
    474.3684,
    474.69515,
    475.02202,
    475.349,
    475.6761,
    476.0033,
    476.33062,
    476.65805,
    476.9856,
    477.31325,
    477.64102,
    477.9689,
    478.2969,
    478.625,
    478.95322,
    479.28155,
    479.61,
    479.93855,
    480.26722,
    480.596,
    480.9249,
    481.2539,
    481.58302,
    481.91225,
    482.2416,
    482.57105,
    482.90062,
    483.2303,
    483.5601,
    483.89,
    484.22002,
    484.55015,
    484.8804,
    485.21075,
    485.54122,
    485.8718,
    486.2025,
    486.5333,
    486.86422,
    487.19525,
    487.5264,
    487.85765,
    488.18902,
    488.5205,
    488.8521,
    489.1838,
    489.51562,
    489.84755,
    490.1796,
    490.51175,
    490.84402,
    491.1764,
    491.5089,
    491.8415,
    492.17422,
    492.50705,
    492.84,
    493.17305,
    493.50622,
    493.8395,
    494.1729,
    494.5064,
    494.84002,
    495.17375,
    495.5076,
    495.84155,
    496.17562,
    496.5098,
    496.8441,
    497.1785,
    497.51302,
    497.84765,
    498.1824,
    498.51725,
    498.85222,
    499.1873,
    499.5225,
    499.8578,
    500.19322,
    500.52875,
    500.8644,
    501.20015,
    501.53602,
    501.872,
    502.2081,
    502.5443,
    502.88062,
    503.21705,
    503.5536,
    503.89025,
    504.22702,
    504.5639,
    504.9009,
    505.238,
    505.57522,
    505.91255,
    506.25,
    506.58755,
    506.92522,
    507.263,
    507.6009,
    507.9389,
    508.27702,
    508.61525,
    508.9536,
    509.29205,
    509.63062,
    509.9693,
    510.3081,
    510.647,
    510.98602,
    511.32515,
    511.6644,
    512.00375,
    512.34322,
    512.6828,
    513.0225,
    513.3623,
    513.70222,
    514.04225,
    514.3824,
    514.72265,
    515.06302,
    515.4035,
    515.7441,
    516.0848,
    516.42562,
    516.76655,
    517.1076,
    517.44875,
    517.79002,
    518.1314,
    518.4729,
    518.8145,
    519.15622,
    519.49805,
    519.84,
    520.18205,
    520.52422,
    520.8665,
    521.2089,
    521.5514,
    521.89402,
    522.23675,
    522.5796,
    522.92255,
    523.26562,
    523.6088,
    523.9521,
    524.2955,
    524.63902,
    524.98265,
    525.3264,
    525.67025,
    526.01422,
    526.3583,
    526.7025,
    527.0468,
    527.39122,
    527.73575,
    528.0804,
    528.42515,
    528.77002,
    529.115,
    529.4601,
    529.8053,
    530.15062,
    530.49605,
    530.8416,
    531.18725,
    531.53302,
    531.8789,
    532.2249,
    532.571,
    532.91722,
    533.26355,
    533.61,
    533.95655,
    534.30322,
    534.65,
    534.9969,
    535.3439,
    535.69102,
    536.03825,
    536.3856,
    536.73305,
    537.08062,
    537.4283,
    537.7761,
    538.124,
    538.47202,
    538.82015,
    539.1684,
    539.51675,
    539.86522,
    540.2138,
    540.5625,
    540.9113,
    541.26022,
    541.60925,
    541.9584,
    542.30765,
    542.65702,
    543.0065,
    543.3561,
    543.7058,
    544.05562,
    544.40555,
    544.7556,
    545.10575,
    545.45602,
    545.8064,
    546.1569,
    546.5075,
    546.85822,
    547.20905,
    547.56,
    547.91105,
    548.26222,
    548.6135,
    548.9649,
    549.3164,
    549.66802,
    550.01975,
    550.3716,
    550.72355,
    551.07562,
    551.4278,
    551.7801,
    552.1325,
    552.48502,
    552.83765,
    553.1904,
    553.54325,
    553.89622,
    554.2493,
    554.6025,
    554.9558,
    555.30922,
    555.66275,
    556.0164,
    556.37015,
    556.72402,
    557.078,
    557.4321,
    557.7863,
    558.14062,
    558.49505,
    558.8496,
    559.20425,
    559.55902,
    559.9139,
    560.2689,
    560.624,
    560.97922,
    561.33455,
    561.69,
    562.04555,
    562.40122,
    562.757,
    563.1129,
    563.4689,
    563.82502,
    564.18125,
    564.5376,
    564.89405,
    565.25062,
    565.6073,
    565.9641,
    566.321,
    566.67802,
    567.03515,
    567.3924,
    567.74975,
    568.10722,
    568.4648,
    568.8225,
    569.1803,
    569.53822,
    569.89625,
    570.2544,
    570.61265,
    570.97102,
    571.3295,
    571.6881,
    572.0468,
    572.40562,
    572.76455,
    573.1236,
    573.48275,
    573.84202,
    574.2014,
    574.5609,
    574.9205,
    575.28022,
    575.64005,
    576,
    576.36005,
    576.72022,
    577.0805,
    577.4409,
    577.8014,
    578.16202,
    578.52275,
    578.8836,
    579.24455,
    579.60562,
    579.9668,
    580.3281,
    580.6895,
    581.05102,
    581.41265,
    581.7744,
    582.13625,
    582.49822,
    582.8603,
    583.2225,
    583.5848,
    583.94722,
    584.30975,
    584.6724,
    585.03515,
    585.39802,
    585.761,
    586.1241,
    586.4873,
    586.85062,
    587.21405,
    587.5776,
    587.94125,
    588.30502,
    588.6689,
    589.0329,
    589.397,
    589.76122,
    590.12555,
    590.49,
    590.85455,
    591.21922,
    591.584,
    591.9489,
    592.3139,
    592.67902,
    593.04425,
    593.4096,
    593.77505,
    594.14062,
    594.5063,
    594.8721,
    595.238,
    595.60402,
    595.97015,
    596.3364,
    596.70275,
    597.06922,
    597.4358,
    597.8025,
    598.1693,
    598.53622,
    598.90325,
    599.2704,
    599.63765,
    600.00502,
    600.3725,
    600.7401,
    601.1078,
    601.47562,
    601.84355,
    602.2116,
    602.57975,
    602.94802,
    603.3164,
    603.6849,
    604.0535,
    604.42222,
    604.79105,
    605.16,
    605.52905,
    605.89822,
    606.2675,
    606.6369,
    607.0064,
    607.37602,
    607.74575,
    608.1156,
    608.48555,
    608.85562,
    609.2258,
    609.5961,
    609.9665,
    610.33702,
    610.70765,
    611.0784,
    611.44925,
    611.82022,
    612.1913,
    612.5625,
    612.9338,
    613.30522,
    613.67675,
    614.0484,
    614.42015,
    614.79202,
    615.164,
    615.5361,
    615.9083,
    616.28062,
    616.65305,
    617.0256,
    617.39825,
    617.77102,
    618.1439,
    618.5169,
    618.89,
    619.26322,
    619.63655,
    620.01,
    620.38355,
    620.75722,
    621.131,
    621.5049,
    621.8789,
    622.25302,
    622.62725,
    623.0016,
    623.37605,
    623.75062,
    624.1253,
    624.5001,
    624.875,
    625.25002,
    625.62515,
    626.0004,
    626.37575,
    626.75122,
    627.1268,
    627.5025,
    627.8783,
    628.25422,
    628.63025,
    629.0064,
    629.38265,
    629.75902,
    630.1355,
    630.5121,
    630.8888,
    631.26562,
    631.64255,
    632.0196,
    632.39675,
    632.77402,
    633.1514,
    633.5289,
    633.9065,
    634.28422,
    634.66205,
    635.04,
    635.41805,
    635.79622,
    636.1745,
    636.5529,
    636.9314,
    637.31002,
    637.68875,
    638.0676,
    638.44655,
    638.82562,
    639.2048,
    639.5841,
    639.9635,
    640.34302,
    640.72265,
    641.1024,
    641.48225,
    641.86222,
    642.2423,
    642.6225,
    643.0028,
    643.38322,
    643.76375,
    644.1444,
    644.52515,
    644.90602,
    645.287,
    645.6681,
    646.0493,
    646.43062,
    646.81205,
    647.1936,
    647.57525,
    647.95702,
    648.3389,
    648.7209,
    649.103,
    649.48522,
    649.86755,
    650.25,
    650.63255,
    651.01522,
    651.398,
    651.7809,
    652.1639,
    652.54702,
    652.93025,
    653.3136,
    653.69705,
    654.08062,
    654.4643,
    654.8481,
    655.232,
    655.61602,
    656.00015,
    656.3844,
    656.76875,
    657.15322,
    657.5378,
    657.9225,
    658.3073,
    658.69222,
    659.07725,
    659.4624,
    659.84765,
    660.23302,
    660.6185,
    661.0041,
    661.3898,
    661.77562,
    662.16155,
    662.5476,
    662.93375,
    663.32002,
    663.7064,
    664.0929,
    664.4795,
    664.86622,
    665.25305,
    665.64,
    666.02705,
    666.41422,
    666.8015,
    667.1889,
    667.5764,
    667.96402,
    668.35175,
    668.7396,
    669.12755,
    669.51562,
    669.9038,
    670.2921,
    670.6805,
    671.06902,
    671.45765,
    671.8464,
    672.23525,
    672.62422,
    673.0133,
    673.4025,
    673.7918,
    674.18122,
    674.57075,
    674.9604,
    675.35015,
    675.74002,
    676.13,
    676.5201,
    676.9103,
    677.30062,
    677.69105,
    678.0816,
    678.47225,
    678.86302,
    679.2539,
    679.6449,
    680.036,
    680.42722,
    680.81855,
    681.21,
    681.60155,
    681.99322,
    682.385,
    682.7769,
    683.1689,
    683.56102,
    683.95325,
    684.3456,
    684.73805,
    685.13062,
    685.5233,
    685.9161,
    686.309,
    686.70202,
    687.09515,
    687.4884,
    687.88175,
    688.27522,
    688.6688,
    689.0625,
    689.4563,
    689.85022,
    690.24425,
    690.6384,
    691.03265,
    691.42702,
    691.8215,
    692.2161,
    692.6108,
    693.00562,
    693.40055,
    693.7956,
    694.19075,
    694.58602,
    694.9814,
    695.3769,
    695.7725,
    696.16822,
    696.56405,
    696.96,
    697.35605,
    697.75222,
    698.1485,
    698.5449,
    698.9414,
    699.33802,
    699.73475,
    700.1316,
    700.52855,
    700.92562,
    701.3228,
    701.7201,
    702.1175,
    702.51502,
    702.91265,
    703.3104,
    703.70825,
    704.10622,
    704.5043,
    704.9025,
    705.3008,
    705.69922,
    706.09775,
    706.4964,
    706.89515,
    707.29402,
    707.693,
    708.0921,
    708.4913,
    708.89062,
    709.29005,
    709.6896,
    710.08925,
    710.48902,
    710.8889,
    711.2889,
    711.689,
    712.08922,
    712.48955,
    712.89,
    713.29055,
    713.69122,
    714.092,
    714.4929,
    714.8939,
    715.29502,
    715.69625,
    716.0976,
    716.49905,
    716.90062,
    717.3023,
    717.7041,
    718.106,
    718.50802,
    718.91015,
    719.3124,
    719.71475,
    720.11722,
    720.5198,
    720.9225,
    721.3253,
    721.72822,
    722.13125,
    722.5344,
    722.93765,
    723.34102,
    723.7445,
    724.1481,
    724.5518,
    724.95562,
    725.35955,
    725.7636,
    726.16775,
    726.57202,
    726.9764,
    727.3809,
    727.7855,
    728.19022,
    728.59505,
    729,
    729.40505,
    729.81022,
    730.2155,
    730.6209,
    731.0264,
    731.43202,
    731.83775,
    732.2436,
    732.64955,
    733.05562,
    733.4618,
    733.8681,
    734.2745,
    734.68102,
    735.08765,
    735.4944,
    735.90125,
    736.30822,
    736.7153,
    737.1225,
    737.5298,
    737.93722,
    738.34475,
    738.7524,
    739.16015,
    739.56802,
    739.976,
    740.3841,
    740.7923,
    741.20062,
    741.60905,
    742.0176,
    742.42625,
    742.83502,
    743.2439,
    743.6529,
    744.062,
    744.47122,
    744.88055,
    745.29,
    745.69955,
    746.10922,
    746.519,
    746.9289,
    747.3389,
    747.74902,
    748.15925,
    748.5696,
    748.98005,
    749.39062,
    749.8013,
    750.2121,
    750.623,
    751.03402,
    751.44515,
    751.8564,
    752.26775,
    752.67922,
    753.0908,
    753.5025,
    753.9143,
    754.32622,
    754.73825,
    755.1504,
    755.56265,
    755.97502,
    756.3875,
    756.8001,
    757.2128,
    757.62562,
    758.03855,
    758.4516,
    758.86475,
    759.27802,
    759.6914,
    760.1049,
    760.5185,
    760.93222,
    761.34605,
    761.76,
    762.17405,
    762.58822,
    763.0025,
    763.4169,
    763.8314,
    764.24602,
    764.66075,
    765.0756,
    765.49055,
    765.90562,
    766.3208,
    766.7361,
    767.1515,
    767.56702,
    767.98265,
    768.3984,
    768.81425,
    769.23022,
    769.6463,
    770.0625,
    770.4788,
    770.89522,
    771.31175,
    771.7284,
    772.14515,
    772.56202,
    772.979,
    773.3961,
    773.8133,
    774.23062,
    774.64805,
    775.0656,
    775.48325,
    775.90102,
    776.3189,
    776.7369,
    777.155,
    777.57322,
    777.99155,
    778.41,
    778.82855,
    779.24722,
    779.666,
    780.0849,
    780.5039,
    780.92302,
    781.34225,
    781.7616,
    782.18105,
    782.60062,
    783.0203,
    783.4401,
    783.86,
    784.28002,
    784.70015,
    785.1204,
    785.54075,
    785.96122,
    786.3818,
    786.8025,
    787.2233,
    787.64422,
    788.06525,
    788.4864,
    788.90765,
    789.32902,
    789.7505,
    790.1721,
    790.5938,
    791.01562,
    791.43755,
    791.8596,
    792.28175,
    792.70402,
    793.1264,
    793.5489,
    793.9715,
    794.39422,
    794.81705,
    795.24,
    795.66305,
    796.08622,
    796.5095,
    796.9329,
    797.3564,
    797.78002,
    798.20375,
    798.6276,
    799.05155,
    799.47562,
    799.8998,
    800.3241,
    800.7485,
    801.17302,
    801.59765,
    802.0224,
    802.44725,
    802.87222,
    803.2973,
    803.7225,
    804.1478,
    804.57322,
    804.99875,
    805.4244,
    805.85015,
    806.27602,
    806.702,
    807.1281,
    807.5543,
    807.98062,
    808.40705,
    808.8336,
    809.26025,
    809.68702,
    810.1139,
    810.5409,
    810.968,
    811.39522,
    811.82255,
    812.25,
    812.67755,
    813.10522,
    813.533,
    813.9609,
    814.3889,
    814.81702,
    815.24525,
    815.6736,
    816.10205,
    816.53062,
    816.9593,
    817.3881,
    817.817,
    818.24602,
    818.67515,
    819.1044,
    819.53375,
    819.96322,
    820.3928,
    820.8225,
    821.2523,
    821.68222,
    822.11225,
    822.5424,
    822.97265,
    823.40302,
    823.8335,
    824.2641,
    824.6948,
    825.12562,
    825.55655,
    825.9876,
    826.41875,
    826.85002,
    827.2814,
    827.7129,
    828.1445,
    828.57622,
    829.00805,
    829.44,
    829.87205,
    830.30422,
    830.7365,
    831.1689,
    831.6014,
    832.03402,
    832.46675,
    832.8996,
    833.33255,
    833.76562,
    834.1988,
    834.6321,
    835.0655,
    835.49902,
    835.93265,
    836.3664,
    836.80025,
    837.23422,
    837.6683,
    838.1025,
    838.5368,
    838.97122,
    839.40575,
    839.8404,
    840.27515,
    840.71002,
    841.145,
    841.5801,
    842.0153,
    842.45062,
    842.88605,
    843.3216,
    843.75725,
    844.19302,
    844.6289,
    845.0649,
    845.501,
    845.93722,
    846.37355,
    846.81,
    847.24655,
    847.68322,
    848.12,
    848.5569,
    848.9939,
    849.43102,
    849.86825,
    850.3056,
    850.74305,
    851.18062,
    851.6183,
    852.0561,
    852.494,
    852.93202,
    853.37015,
    853.8084,
    854.24675,
    854.68522,
    855.1238,
    855.5625,
    856.0013,
    856.44022,
    856.87925,
    857.3184,
    857.75765,
    858.19702,
    858.6365,
    859.0761,
    859.5158,
    859.95562,
    860.39555,
    860.8356,
    861.27575,
    861.71602,
    862.1564,
    862.5969,
    863.0375,
    863.47822,
    863.91905,
    864.36,
    864.80105,
    865.24222,
    865.6835,
    866.1249,
    866.5664,
    867.00802,
    867.44975,
    867.8916,
    868.33355,
    868.77562,
    869.2178,
    869.6601,
    870.1025,
    870.54502,
    870.98765,
    871.4304,
    871.87325,
    872.31622,
    872.7593,
    873.2025,
    873.6458,
    874.08922,
    874.53275,
    874.9764,
    875.42015,
    875.86402,
    876.308,
    876.7521,
    877.1963,
    877.64062,
    878.08505,
    878.5296,
    878.97425,
    879.41902,
    879.8639,
    880.3089,
    880.754,
    881.19922,
    881.64455,
    882.09,
    882.53555,
    882.98122,
    883.427,
    883.8729,
    884.3189,
    884.76502,
    885.21125,
    885.6576,
    886.10405,
    886.55062,
    886.9973,
    887.4441,
    887.891,
    888.33802,
    888.78515,
    889.2324,
    889.67975,
    890.12722,
    890.5748,
    891.0225,
    891.4703,
    891.91822,
    892.36625,
    892.8144,
    893.26265,
    893.71102,
    894.1595,
    894.6081,
    895.0568,
    895.50562,
    895.95455,
    896.4036,
    896.85275,
    897.30202,
    897.7514,
    898.2009,
    898.6505,
    899.10022,
    899.55005,
    900,
    900.45005,
    900.90022,
    901.3505,
    901.8009,
    902.2514,
    902.70202,
    903.15275,
    903.6036,
    904.05455,
    904.50562,
    904.9568,
    905.4081,
    905.8595,
    906.31102,
    906.76265,
    907.2144,
    907.66625,
    908.11822,
    908.5703,
    909.0225,
    909.4748,
    909.92722,
    910.37975,
    910.8324,
    911.28515,
    911.73802,
    912.191,
    912.6441,
    913.0973,
    913.55062,
    914.00405,
    914.4576,
    914.91125,
    915.36502,
    915.8189,
    916.2729,
    916.727,
    917.18122,
    917.63555,
    918.09,
    918.54455,
    918.99922,
    919.454,
    919.9089,
    920.3639,
    920.81902,
    921.27425,
    921.7296,
    922.18505,
    922.64062,
    923.0963,
    923.5521,
    924.008,
    924.46402,
    924.92015,
    925.3764,
    925.83275,
    926.28922,
    926.7458,
    927.2025,
    927.6593,
    928.11622,
    928.57325,
    929.0304,
    929.48765,
    929.94502,
    930.4025,
    930.8601,
    931.3178,
    931.77562,
    932.23355,
    932.6916,
    933.14975,
    933.60802,
    934.0664,
    934.5249,
    934.9835,
    935.44222,
    935.90105,
    936.36,
    936.81905,
    937.27822,
    937.7375,
    938.1969,
    938.6564,
    939.11602,
    939.57575,
    940.0356,
    940.49555,
    940.95562,
    941.4158,
    941.8761,
    942.3365,
    942.79702,
    943.25765,
    943.7184,
    944.17925,
    944.64022,
    945.1013,
    945.5625,
    946.0238,
    946.48522,
    946.94675,
    947.4084,
    947.87015,
    948.33202,
    948.794,
    949.2561,
    949.7183,
    950.18062,
    950.64305,
    951.1056,
    951.56825,
    952.03102,
    952.4939,
    952.9569,
    953.42,
    953.88322,
    954.34655,
    954.81,
    955.27355,
    955.73722,
    956.201,
    956.6649,
    957.1289,
    957.59302,
    958.05725,
    958.5216,
    958.98605,
    959.45062,
    959.9153,
    960.3801,
    960.845,
    961.31002,
    961.77515,
    962.2404,
    962.70575,
    963.17122,
    963.6368,
    964.1025,
    964.5683,
    965.03422,
    965.50025,
    965.9664,
    966.43265,
    966.89902,
    967.3655,
    967.8321,
    968.2988,
    968.76562,
    969.23255,
    969.6996,
    970.16675,
    970.63402,
    971.1014,
    971.5689,
    972.0365,
    972.50422,
    972.97205,
    973.44,
    973.90805,
    974.37622,
    974.8445,
    975.3129,
    975.7814,
    976.25002,
    976.71875,
    977.1876,
    977.65655,
    978.12562,
    978.5948,
    979.0641,
    979.5335,
    980.00302,
    980.47265,
    980.9424,
    981.41225,
    981.88222,
    982.3523,
    982.8225,
    983.2928,
    983.76322,
    984.23375,
    984.7044,
    985.17515,
    985.64602,
    986.117,
    986.5881,
    987.0593,
    987.53062,
    988.00205,
    988.4736,
    988.94525,
    989.41702,
    989.8889,
    990.3609,
    990.833,
    991.30522,
    991.77755,
    992.25,
    992.72255,
    993.19522,
    993.668,
    994.1409,
    994.6139,
    995.08702,
    995.56025,
    996.0336,
    996.50705,
    996.98062,
    997.4543,
    997.9281,
    998.402,
    998.87602,
    999.35015,
    999.8244,
    1000.29875,
    1000.77322,
    1001.2478,
    1001.7225,
    1002.1973,
    1002.67222,
    1003.14725,
    1003.6224,
    1004.09765,
    1004.57302,
    1005.0485,
    1005.5241,
    1005.9998,
    1006.47562,
    1006.95155,
    1007.4276,
    1007.90375,
    1008.38002,
    1008.8564,
    1009.3329,
    1009.8095,
    1010.28622,
    1010.76305,
    1011.24,
    1011.71705,
    1012.19422,
    1012.6715,
    1013.1489,
    1013.6264,
    1014.10402,
    1014.58175,
    1015.0596,
    1015.53755,
    1016.01562,
    1016.4938,
    1016.9721,
    1017.4505,
    1017.92902,
    1018.40765,
    1018.8864,
    1019.36525,
    1019.84422,
    1020.3233,
    1020.8025,
    1021.2818,
    1021.76122,
    1022.24075,
    1022.7204,
    1023.20015,
    1023.68002,
    1024.16,
    1024.6401,
    1025.1203,
    1025.60062,
    1026.08105,
    1026.5616,
    1027.04225,
    1027.52302,
    1028.0039,
    1028.4849,
    1028.966,
    1029.44722,
    1029.92855,
    1030.41,
    1030.89155,
    1031.37322,
    1031.855,
    1032.3369,
    1032.8189,
    1033.30102,
    1033.78325,
    1034.2656,
    1034.74805,
    1035.23062,
    1035.7133,
    1036.1961,
    1036.679,
    1037.16202,
    1037.64515,
    1038.1284,
    1038.61175,
    1039.09522,
    1039.5788,
    1040.0625,
    1040.5463,
    1041.03022,
    1041.51425,
    1041.9984,
    1042.48265,
    1042.96702,
    1043.4515,
    1043.9361,
    1044.4208,
    1044.90562,
    1045.39055,
    1045.8756,
    1046.36075,
    1046.84602,
    1047.3314,
    1047.8169,
    1048.3025,
    1048.78822,
    1049.27405,
    1049.76,
    1050.24605,
    1050.73222,
    1051.2185,
    1051.7049,
    1052.1914,
    1052.67802,
    1053.16475,
    1053.6516,
    1054.13855,
    1054.62562,
    1055.1128,
    1055.6001,
    1056.0875,
    1056.57502,
    1057.06265,
    1057.5504,
    1058.03825,
    1058.52622,
    1059.0143,
    1059.5025,
    1059.9908,
    1060.47922,
    1060.96775,
    1061.4564,
    1061.94515,
    1062.43402,
    1062.923,
    1063.4121,
    1063.9013,
    1064.39062,
    1064.88005,
    1065.3696,
    1065.85925,
    1066.34902,
    1066.8389,
    1067.3289,
    1067.819,
    1068.30922,
    1068.79955,
    1069.29,
    1069.78055,
    1070.27122,
    1070.762,
    1071.2529,
    1071.7439,
    1072.23502,
    1072.72625,
    1073.2176,
    1073.70905,
    1074.20062,
    1074.6923,
    1075.1841,
    1075.676,
    1076.16802,
    1076.66015,
    1077.1524,
    1077.64475,
    1078.13722,
    1078.6298,
    1079.1225,
    1079.6153,
    1080.10822,
    1080.60125,
    1081.0944,
    1081.58765,
    1082.08102,
    1082.5745,
    1083.0681,
    1083.5618,
    1084.05562,
    1084.54955,
    1085.0436,
    1085.53775,
    1086.03202,
    1086.5264,
    1087.0209,
    1087.5155,
    1088.01022,
    1088.50505,
    1089,
    1089.49505,
    1089.99022,
    1090.4855,
    1090.9809,
    1091.4764,
    1091.97202,
    1092.46775,
    1092.9636,
    1093.45955,
    1093.95562,
    1094.4518,
    1094.9481,
    1095.4445,
    1095.94102,
    1096.43765,
    1096.9344,
    1097.43125,
    1097.92822,
    1098.4253,
    1098.9225,
    1099.4198,
    1099.91722,
    1100.41475,
    1100.9124,
    1101.41015,
    1101.90802,
    1102.406,
    1102.9041,
    1103.4023,
    1103.90062,
    1104.39905,
    1104.8976,
    1105.39625,
    1105.89502,
    1106.3939,
    1106.8929,
    1107.392,
    1107.89122,
    1108.39055,
    1108.89,
    1109.38955,
    1109.88922,
    1110.389,
    1110.8889,
    1111.3889,
    1111.88902,
    1112.38925,
    1112.8896,
    1113.39005,
    1113.89062,
    1114.3913,
    1114.8921,
    1115.393,
    1115.89402,
    1116.39515,
    1116.8964,
    1117.39775,
    1117.89922,
    1118.4008,
    1118.9025,
    1119.4043,
    1119.90622,
    1120.40825,
    1120.9104,
    1121.41265,
    1121.91502,
    1122.4175,
    1122.9201,
    1123.4228,
    1123.92562,
    1124.42855,
    1124.9316,
    1125.43475,
    1125.93802,
    1126.4414,
    1126.9449,
    1127.4485,
    1127.95222,
    1128.45605,
    1128.96,
    1129.46405,
    1129.96822,
    1130.4725,
    1130.9769,
    1131.4814,
    1131.98602,
    1132.49075,
    1132.9956,
    1133.50055,
    1134.00562,
    1134.5108,
    1135.0161,
    1135.5215,
    1136.02702,
    1136.53265,
    1137.0384,
    1137.54425,
    1138.05022,
    1138.5563,
    1139.0625,
    1139.5688,
    1140.07522,
    1140.58175,
    1141.0884,
    1141.59515,
    1142.10202,
    1142.609,
    1143.1161,
    1143.6233,
    1144.13062,
    1144.63805,
    1145.1456,
    1145.65325,
    1146.16102,
    1146.6689,
    1147.1769,
    1147.685,
    1148.19322,
    1148.70155,
    1149.21,
    1149.71855,
    1150.22722,
    1150.736,
    1151.2449,
    1151.7539,
    1152.26302,
    1152.77225,
    1153.2816,
    1153.79105,
    1154.30062,
    1154.8103,
    1155.3201,
    1155.83,
    1156.34002,
    1156.85015,
    1157.3604,
    1157.87075,
    1158.38122,
    1158.8918,
    1159.4025,
    1159.9133,
    1160.42422,
    1160.93525,
    1161.4464,
    1161.95765,
    1162.46902,
    1162.9805,
    1163.4921,
    1164.0038,
    1164.51562,
    1165.02755,
    1165.5396,
    1166.05175,
    1166.56402,
    1167.0764,
    1167.5889,
    1168.1015,
    1168.61422,
    1169.12705,
    1169.64,
    1170.15305,
    1170.66622,
    1171.1795,
    1171.6929,
    1172.2064,
    1172.72002,
    1173.23375,
    1173.7476,
    1174.26155,
    1174.77562,
    1175.2898,
    1175.8041,
    1176.3185,
    1176.83302,
    1177.34765,
    1177.8624,
    1178.37725,
    1178.89222,
    1179.4073,
    1179.9225,
    1180.4378,
    1180.95322,
    1181.46875,
    1181.9844,
    1182.50015,
    1183.01602,
    1183.532,
    1184.0481,
    1184.5643,
    1185.08062,
    1185.59705,
    1186.1136,
    1186.63025,
    1187.14702,
    1187.6639,
    1188.1809,
    1188.698,
    1189.21522,
    1189.73255,
    1190.25,
    1190.76755,
    1191.28522,
    1191.803,
    1192.3209,
    1192.8389,
    1193.35702,
    1193.87525,
    1194.3936,
    1194.91205,
    1195.43062,
    1195.9493,
    1196.4681,
    1196.987,
    1197.50602,
    1198.02515,
    1198.5444,
    1199.06375,
    1199.58322,
    1200.1028,
    1200.6225,
    1201.1423,
    1201.66222,
    1202.18225,
    1202.7024,
    1203.22265,
    1203.74302,
    1204.2635,
    1204.7841,
    1205.3048,
    1205.82562,
    1206.34655,
    1206.8676,
    1207.38875,
    1207.91002,
    1208.4314,
    1208.9529,
    1209.4745,
    1209.99622,
    1210.51805,
    1211.04,
    1211.56205,
    1212.08422,
    1212.6065,
    1213.1289,
    1213.6514,
    1214.17402,
    1214.69675,
    1215.2196,
    1215.74255,
    1216.26562,
    1216.7888,
    1217.3121,
    1217.8355,
    1218.35902,
    1218.88265,
    1219.4064,
    1219.93025,
    1220.45422,
    1220.9783,
    1221.5025,
    1222.0268,
    1222.55122,
    1223.07575,
    1223.6004,
    1224.12515,
    1224.65002,
    1225.175,
    1225.7001,
    1226.2253,
    1226.75062,
    1227.27605,
    1227.8016,
    1228.32725,
    1228.85302,
    1229.3789,
    1229.9049,
    1230.431,
    1230.95722,
    1231.48355,
    1232.01,
    1232.53655,
    1233.06322,
    1233.59,
    1234.1169,
    1234.6439,
    1235.17102,
    1235.69825,
    1236.2256,
    1236.75305,
    1237.28062,
    1237.8083,
    1238.3361,
    1238.864,
    1239.39202,
    1239.92015,
    1240.4484,
    1240.97675,
    1241.50522,
    1242.0338,
    1242.5625,
    1243.0913,
    1243.62022,
    1244.14925,
    1244.6784,
    1245.20765,
    1245.73702,
    1246.2665,
    1246.7961,
    1247.3258,
    1247.85562,
    1248.38555,
    1248.9156,
    1249.44575,
    1249.97602,
    1250.5064,
    1251.0369,
    1251.5675,
    1252.09822,
    1252.62905,
    1253.16,
    1253.69105,
    1254.22222,
    1254.7535,
    1255.2849,
    1255.8164,
    1256.34802,
    1256.87975,
    1257.4116,
    1257.94355,
    1258.47562,
    1259.0078,
    1259.5401,
    1260.0725,
    1260.60502,
    1261.13765,
    1261.6704,
    1262.20325,
    1262.73622,
    1263.2693,
    1263.8025,
    1264.3358,
    1264.86922,
    1265.40275,
    1265.9364,
    1266.47015,
    1267.00402,
    1267.538,
    1268.0721,
    1268.6063,
    1269.14062,
    1269.67505,
    1270.2096,
    1270.74425,
    1271.27902,
    1271.8139,
    1272.3489,
    1272.884,
    1273.41922,
    1273.95455,
    1274.49,
    1275.02555,
    1275.56122,
    1276.097,
    1276.6329,
    1277.1689,
    1277.70502,
    1278.24125,
    1278.7776,
    1279.31405,
    1279.85062,
    1280.3873,
    1280.9241,
    1281.461,
    1281.99802,
    1282.53515,
    1283.0724,
    1283.60975,
    1284.14722,
    1284.6848,
    1285.2225,
    1285.7603,
    1286.29822,
    1286.83625,
    1287.3744,
    1287.91265,
    1288.45102,
    1288.9895,
    1289.5281,
    1290.0668,
    1290.60562,
    1291.14455,
    1291.6836,
    1292.22275,
    1292.76202,
    1293.3014,
    1293.8409,
    1294.3805,
    1294.92022,
    1295.46005,
    1296,
    1296.54005,
    1297.08022,
    1297.6205,
    1298.1609,
    1298.7014,
    1299.24202,
    1299.78275,
    1300.3236,
    1300.86455,
    1301.40562,
    1301.9468,
    1302.4881,
    1303.0295,
    1303.57102,
    1304.11265,
    1304.6544,
    1305.19625,
    1305.73822,
    1306.2803,
    1306.8225,
    1307.3648,
    1307.90722,
    1308.44975,
    1308.9924,
    1309.53515,
    1310.07802,
    1310.621,
    1311.1641,
    1311.7073,
    1312.25062,
    1312.79405,
    1313.3376,
    1313.88125,
    1314.42502,
    1314.9689,
    1315.5129,
    1316.057,
    1316.60122,
    1317.14555,
    1317.69,
    1318.23455,
    1318.77922,
    1319.324,
    1319.8689,
    1320.4139,
    1320.95902,
    1321.50425,
    1322.0496,
    1322.59505,
    1323.14062,
    1323.6863,
    1324.2321,
    1324.778,
    1325.32402,
    1325.87015,
    1326.4164,
    1326.96275,
    1327.50922,
    1328.0558,
    1328.6025,
    1329.1493,
    1329.69622,
    1330.24325,
    1330.7904,
    1331.33765,
    1331.88502,
    1332.4325,
    1332.9801,
    1333.5278,
    1334.07562,
    1334.62355,
    1335.1716,
    1335.71975,
    1336.26802,
    1336.8164,
    1337.3649,
    1337.9135,
    1338.46222,
    1339.01105,
    1339.56,
    1340.10905,
    1340.65822,
    1341.2075,
    1341.7569,
    1342.3064,
    1342.85602,
    1343.40575,
    1343.9556,
    1344.50555,
    1345.05562,
    1345.6058,
    1346.1561,
    1346.7065,
    1347.25702,
    1347.80765,
    1348.3584,
    1348.90925,
    1349.46022,
    1350.0113,
    1350.5625,
    1351.1138,
    1351.66522,
    1352.21675,
    1352.7684,
    1353.32015,
    1353.87202,
    1354.424,
    1354.9761,
    1355.5283,
    1356.08062,
    1356.63305,
    1357.1856,
    1357.73825,
    1358.29102,
    1358.8439,
    1359.3969,
    1359.95,
    1360.50322,
    1361.05655,
    1361.61,
    1362.16355,
    1362.71722,
    1363.271,
    1363.8249,
    1364.3789,
    1364.93302,
    1365.48725,
    1366.0416,
    1366.59605,
    1367.15062,
    1367.7053,
    1368.2601,
    1368.815,
    1369.37002,
    1369.92515,
    1370.4804,
    1371.03575,
    1371.59122,
    1372.1468,
    1372.7025,
    1373.2583,
    1373.81422,
    1374.37025,
    1374.9264,
    1375.48265,
    1376.03902,
    1376.5955,
    1377.1521,
    1377.7088,
    1378.26562,
    1378.82255,
    1379.3796,
    1379.93675,
    1380.49402,
    1381.0514,
    1381.6089,
    1382.1665,
    1382.72422,
    1383.28205,
    1383.84,
    1384.39805,
    1384.95622,
    1385.5145,
    1386.0729,
    1386.6314,
    1387.19002,
    1387.74875,
    1388.3076,
    1388.86655,
    1389.42562,
    1389.9848,
    1390.5441,
    1391.1035,
    1391.66302,
    1392.22265,
    1392.7824,
    1393.34225,
    1393.90222,
    1394.4623,
    1395.0225,
    1395.5828,
    1396.14322,
    1396.70375,
    1397.2644,
    1397.82515,
    1398.38602,
    1398.947,
    1399.5081,
    1400.0693,
    1400.63062,
    1401.19205,
    1401.7536,
    1402.31525,
    1402.87702,
    1403.4389,
    1404.0009,
    1404.563,
    1405.12522,
    1405.68755,
    1406.25,
    1406.81255,
    1407.37522,
    1407.938,
    1408.5009,
    1409.0639,
    1409.62702,
    1410.19025,
    1410.7536,
    1411.31705,
    1411.88062,
    1412.4443,
    1413.0081,
    1413.572,
    1414.13602,
    1414.70015,
    1415.2644,
    1415.82875,
    1416.39322,
    1416.9578,
    1417.5225,
    1418.0873,
    1418.65222,
    1419.21725,
    1419.7824,
    1420.34765,
    1420.91302,
    1421.4785,
    1422.0441,
    1422.6098,
    1423.17562,
    1423.74155,
    1424.3076,
    1424.87375,
    1425.44002,
    1426.0064,
    1426.5729,
    1427.1395,
    1427.70622,
    1428.27305,
    1428.84,
    1429.40705,
    1429.97422,
    1430.5415,
    1431.1089,
    1431.6764,
    1432.24402,
    1432.81175,
    1433.3796,
    1433.94755,
    1434.51562,
    1435.0838,
    1435.6521,
    1436.2205,
    1436.78902,
    1437.35765,
    1437.9264,
    1438.49525,
    1439.06422,
    1439.6333,
    1440.2025,
    1440.7718,
    1441.34122,
    1441.91075,
    1442.4804,
    1443.05015,
    1443.62002,
    1444.19,
    1444.7601,
    1445.3303,
    1445.90062,
    1446.47105,
    1447.0416,
    1447.61225,
    1448.18302,
    1448.7539,
    1449.3249,
    1449.896,
    1450.46722,
    1451.03855,
    1451.61,
    1452.18155,
    1452.75322,
    1453.325,
    1453.8969,
    1454.4689,
    1455.04102,
    1455.61325,
    1456.1856,
    1456.75805,
    1457.33062,
    1457.9033,
    1458.4761,
    1459.049,
    1459.62202,
    1460.19515,
    1460.7684,
    1461.34175,
    1461.91522,
    1462.4888,
    1463.0625,
    1463.6363,
    1464.21022,
    1464.78425,
    1465.3584,
    1465.93265,
    1466.50702,
    1467.0815,
    1467.6561,
    1468.2308,
    1468.80562,
    1469.38055,
    1469.9556,
    1470.53075,
    1471.10602,
    1471.6814,
    1472.2569,
    1472.8325,
    1473.40822,
    1473.98405,
    1474.56,
    1475.13605,
    1475.71222,
    1476.2885,
    1476.8649,
    1477.4414,
    1478.01802,
    1478.59475,
    1479.1716,
    1479.74855,
    1480.32562,
    1480.9028,
    1481.4801,
    1482.0575,
    1482.63502,
    1483.21265,
    1483.7904,
    1484.36825,
    1484.94622,
    1485.5243,
    1486.1025,
    1486.6808,
    1487.25922,
    1487.83775,
    1488.4164,
    1488.99515,
    1489.57402,
    1490.153,
    1490.7321,
    1491.3113,
    1491.89062,
    1492.47005,
    1493.0496,
    1493.62925,
    1494.20902,
    1494.7889,
    1495.3689,
    1495.949,
    1496.52922,
    1497.10955,
    1497.69,
    1498.27055,
    1498.85122,
    1499.432,
    1500.0129,
    1500.5939,
    1501.17502,
    1501.75625,
    1502.3376,
    1502.91905,
    1503.50062,
    1504.0823,
    1504.6641,
    1505.246,
    1505.82802,
    1506.41015,
    1506.9924,
    1507.57475,
    1508.15722,
    1508.7398,
    1509.3225,
    1509.9053,
    1510.48822,
    1511.07125,
    1511.6544,
    1512.23765,
    1512.82102,
    1513.4045,
    1513.9881,
    1514.5718,
    1515.15562,
    1515.73955,
    1516.3236,
    1516.90775,
    1517.49202,
    1518.0764,
    1518.6609,
    1519.2455,
    1519.83022,
    1520.41505,
    1521,
    1521.58505,
    1522.17022,
    1522.7555,
    1523.3409,
    1523.9264,
    1524.51202,
    1525.09775,
    1525.6836,
    1526.26955,
    1526.85562,
    1527.4418,
    1528.0281,
    1528.6145,
    1529.20102,
    1529.78765,
    1530.3744,
    1530.96125,
    1531.54822,
    1532.1353,
    1532.7225,
    1533.3098,
    1533.89722,
    1534.48475,
    1535.0724,
    1535.66015,
    1536.24802,
    1536.836,
    1537.4241,
    1538.0123,
    1538.60062,
    1539.18905,
    1539.7776,
    1540.36625,
    1540.95502,
    1541.5439,
    1542.1329,
    1542.722,
    1543.31122,
    1543.90055,
    1544.49,
    1545.07955,
    1545.66922,
    1546.259,
    1546.8489,
    1547.4389,
    1548.02902,
    1548.61925,
    1549.2096,
    1549.80005,
    1550.39062,
    1550.9813,
    1551.5721,
    1552.163,
    1552.75402,
    1553.34515,
    1553.9364,
    1554.52775,
    1555.11922,
    1555.7108,
    1556.3025,
    1556.8943,
    1557.48622,
    1558.07825,
    1558.6704,
    1559.26265,
    1559.85502,
    1560.4475,
    1561.0401,
    1561.6328,
    1562.22562,
    1562.81855,
    1563.4116,
    1564.00475,
    1564.59802,
    1565.1914,
    1565.7849,
    1566.3785,
    1566.97222,
    1567.56605,
    1568.16,
    1568.75405,
    1569.34822,
    1569.9425,
    1570.5369,
    1571.1314,
    1571.72602,
    1572.32075,
    1572.9156,
    1573.51055,
    1574.10562,
    1574.7008,
    1575.2961,
    1575.8915,
    1576.48702,
    1577.08265,
    1577.6784,
    1578.27425,
    1578.87022,
    1579.4663,
    1580.0625,
    1580.6588,
    1581.25522,
    1581.85175,
    1582.4484,
    1583.04515,
    1583.64202,
    1584.239,
    1584.8361,
    1585.4333,
    1586.03062,
    1586.62805,
    1587.2256,
    1587.82325,
    1588.42102,
    1589.0189,
    1589.6169,
    1590.215,
    1590.81322,
    1591.41155,
    1592.01,
    1592.60855,
    1593.20722,
    1593.806,
    1594.4049,
    1595.0039,
    1595.60302,
    1596.20225,
    1596.8016,
    1597.40105,
    1598.00062,
    1598.6003,
    1599.2001,
    1599.8,
    1600.40002,
    1601.00015,
    1601.6004,
    1602.20075,
    1602.80122,
    1603.4018,
    1604.0025,
    1604.6033,
    1605.20422,
    1605.80525,
    1606.4064,
    1607.00765,
    1607.60902,
    1608.2105,
    1608.8121,
    1609.4138,
    1610.01562,
    1610.61755,
    1611.2196,
    1611.82175,
    1612.42402,
    1613.0264,
    1613.6289,
    1614.2315,
    1614.83422,
    1615.43705,
    1616.04,
    1616.64305,
    1617.24622,
    1617.8495,
    1618.4529,
    1619.0564,
    1619.66002,
    1620.26375,
    1620.8676,
    1621.47155,
    1622.07562,
    1622.6798,
    1623.2841,
    1623.8885,
    1624.49302,
    1625.09765,
    1625.7024,
    1626.30725,
    1626.91222,
    1627.5173,
    1628.1225,
    1628.7278,
    1629.33322,
    1629.93875,
    1630.5444,
    1631.15015,
    1631.75602,
    1632.362,
    1632.9681,
    1633.5743,
    1634.18062,
    1634.78705,
    1635.3936,
    1636.00025,
    1636.60702,
    1637.2139,
    1637.8209,
    1638.428,
    1639.03522,
    1639.64255,
    1640.25,
    1640.85755,
    1641.46522,
    1642.073,
    1642.6809,
    1643.2889,
    1643.89702,
    1644.50525,
    1645.1136,
    1645.72205,
    1646.33062,
    1646.9393,
    1647.5481,
    1648.157,
    1648.76602,
    1649.37515,
    1649.9844,
    1650.59375,
    1651.20322,
    1651.8128,
    1652.4225,
    1653.0323,
    1653.64222,
    1654.25225,
    1654.8624,
    1655.47265,
    1656.08302,
    1656.6935,
    1657.3041,
    1657.9148,
    1658.52562,
    1659.13655,
    1659.7476,
    1660.35875,
    1660.97002,
    1661.5814,
    1662.1929,
    1662.8045,
    1663.41622,
    1664.02805,
    1664.64,
    1665.25205,
    1665.86422,
    1666.4765,
    1667.0889,
    1667.7014,
    1668.31402,
    1668.92675,
    1669.5396,
    1670.15255,
    1670.76562,
    1671.3788,
    1671.9921,
    1672.6055,
    1673.21902,
    1673.83265,
    1674.4464,
    1675.06025,
    1675.67422,
    1676.2883,
    1676.9025,
    1677.5168,
    1678.13122,
    1678.74575,
    1679.3604,
    1679.97515,
    1680.59002,
    1681.205,
    1681.8201,
    1682.4353,
    1683.05062,
    1683.66605,
    1684.2816,
    1684.89725,
    1685.51302,
    1686.1289,
    1686.7449,
    1687.361,
    1687.97722,
    1688.59355,
    1689.21,
    1689.82655,
    1690.44322,
    1691.06,
    1691.6769,
    1692.2939,
    1692.91102,
    1693.52825,
    1694.1456,
    1694.76305,
    1695.38062,
    1695.9983,
    1696.6161,
    1697.234,
    1697.85202,
    1698.47015,
    1699.0884,
    1699.70675,
    1700.32522,
    1700.9438,
    1701.5625,
    1702.1813,
    1702.80022,
    1703.41925,
    1704.0384,
    1704.65765,
    1705.27702,
    1705.8965,
    1706.5161,
    1707.1358,
    1707.75562,
    1708.37555,
    1708.9956,
    1709.61575,
    1710.23602,
    1710.8564,
    1711.4769,
    1712.0975,
    1712.71822,
    1713.33905,
    1713.96,
    1714.58105,
    1715.20222,
    1715.8235,
    1716.4449,
    1717.0664,
    1717.68802,
    1718.30975,
    1718.9316,
    1719.55355,
    1720.17562,
    1720.7978,
    1721.4201,
    1722.0425,
    1722.66502,
    1723.28765,
    1723.9104,
    1724.53325,
    1725.15622,
    1725.7793,
    1726.4025,
    1727.0258,
    1727.64922,
    1728.27275,
    1728.8964,
    1729.52015,
    1730.14402,
    1730.768,
    1731.3921,
    1732.0163,
    1732.64062,
    1733.26505,
    1733.8896,
    1734.51425,
    1735.13902,
    1735.7639,
    1736.3889,
    1737.014,
    1737.63922,
    1738.26455,
    1738.89,
    1739.51555,
    1740.14122,
    1740.767,
    1741.3929,
    1742.0189,
    1742.64502,
    1743.27125,
    1743.8976,
    1744.52405,
    1745.15062,
    1745.7773,
    1746.4041,
    1747.031,
    1747.65802,
    1748.28515,
    1748.9124,
    1749.53975,
    1750.16722,
    1750.7948,
    1751.4225,
    1752.0503,
    1752.67822,
    1753.30625,
    1753.9344,
    1754.56265,
    1755.19102,
    1755.8195,
    1756.4481,
    1757.0768,
    1757.70562,
    1758.33455,
    1758.9636,
    1759.59275,
    1760.22202,
    1760.8514,
    1761.4809,
    1762.1105,
    1762.74022,
    1763.37005,
    1764,
    1764.63005,
    1765.26022,
    1765.8905,
    1766.5209,
    1767.1514,
    1767.78202,
    1768.41275,
    1769.0436,
    1769.67455,
    1770.30562,
    1770.9368,
    1771.5681,
    1772.1995,
    1772.83102,
    1773.46265,
    1774.0944,
    1774.72625,
    1775.35822,
    1775.9903,
    1776.6225,
    1777.2548,
    1777.88722,
    1778.51975,
    1779.1524,
    1779.78515,
    1780.41802,
    1781.051,
    1781.6841,
    1782.3173,
    1782.95062,
    1783.58405,
    1784.2176,
    1784.85125,
    1785.48502,
    1786.1189,
    1786.7529,
    1787.387,
    1788.02122,
    1788.65555,
    1789.29,
    1789.92455,
    1790.55922,
    1791.194,
    1791.8289,
    1792.4639,
    1793.09902,
    1793.73425,
    1794.3696,
    1795.00505,
    1795.64062,
    1796.2763,
    1796.9121,
    1797.548,
    1798.18402,
    1798.82015,
    1799.4564,
    1800.09275,
    1800.72922,
    1801.3658,
    1802.0025,
    1802.6393,
    1803.27622,
    1803.91325,
    1804.5504,
    1805.18765,
    1805.82502,
    1806.4625,
    1807.1001,
    1807.7378,
    1808.37562,
    1809.01355,
    1809.6516,
    1810.28975,
    1810.92802,
    1811.5664,
    1812.2049,
    1812.8435,
    1813.48222,
    1814.12105,
    1814.76,
    1815.39905,
    1816.03822,
    1816.6775,
    1817.3169,
    1817.9564,
    1818.59602,
    1819.23575,
    1819.8756,
    1820.51555,
    1821.15562,
    1821.7958,
    1822.4361,
    1823.0765,
    1823.71702,
    1824.35765,
    1824.9984,
    1825.63925,
    1826.28022,
    1826.9213,
    1827.5625,
    1828.2038,
    1828.84522,
    1829.48675,
    1830.1284,
    1830.77015,
    1831.41202,
    1832.054,
    1832.6961,
    1833.3383,
    1833.98062,
    1834.62305,
    1835.2656,
    1835.90825,
    1836.55102,
    1837.1939,
    1837.8369,
    1838.48,
    1839.12322,
    1839.76655,
    1840.41,
    1841.05355,
    1841.69722,
    1842.341,
    1842.9849,
    1843.6289,
    1844.27302,
    1844.91725,
    1845.5616,
    1846.20605,
    1846.85062,
    1847.4953,
    1848.1401,
    1848.785,
    1849.43002,
    1850.07515,
    1850.7204,
    1851.36575,
    1852.01122,
    1852.6568,
    1853.3025,
    1853.9483,
    1854.59422,
    1855.24025,
    1855.8864,
    1856.53265,
    1857.17902,
    1857.8255,
    1858.4721,
    1859.1188,
    1859.76562,
    1860.41255,
    1861.0596,
    1861.70675,
    1862.35402,
    1863.0014,
    1863.6489,
    1864.2965,
    1864.94422,
    1865.59205,
    1866.24,
    1866.88805,
    1867.53622,
    1868.1845,
    1868.8329,
    1869.4814,
    1870.13002,
    1870.77875,
    1871.4276,
    1872.07655,
    1872.72562,
    1873.3748,
    1874.0241,
    1874.6735,
    1875.32302,
    1875.97265,
    1876.6224,
    1877.27225,
    1877.92222,
    1878.5723,
    1879.2225,
    1879.8728,
    1880.52322,
    1881.17375,
    1881.8244,
    1882.47515,
    1883.12602,
    1883.777,
    1884.4281,
    1885.0793,
    1885.73062,
    1886.38205,
    1887.0336,
    1887.68525,
    1888.33702,
    1888.9889,
    1889.6409,
    1890.293,
    1890.94522,
    1891.59755,
    1892.25,
    1892.90255,
    1893.55522,
    1894.208,
    1894.8609,
    1895.5139,
    1896.16702,
    1896.82025,
    1897.4736,
    1898.12705,
    1898.78062,
    1899.4343,
    1900.0881,
    1900.742,
    1901.39602,
    1902.05015,
    1902.7044,
    1903.35875,
    1904.01322,
    1904.6678,
    1905.3225,
    1905.9773,
    1906.63222,
    1907.28725,
    1907.9424,
    1908.59765,
    1909.25302,
    1909.9085,
    1910.5641,
    1911.2198,
    1911.87562,
    1912.53155,
    1913.1876,
    1913.84375,
    1914.50002,
    1915.1564,
    1915.8129,
    1916.4695,
    1917.12622,
    1917.78305,
    1918.44,
    1919.09705,
    1919.75422,
    1920.4115,
    1921.0689,
    1921.7264,
    1922.38402,
    1923.04175,
    1923.6996,
    1924.35755,
    1925.01562,
    1925.6738,
    1926.3321,
    1926.9905,
    1927.64902,
    1928.30765,
    1928.9664,
    1929.62525,
    1930.28422,
    1930.9433,
    1931.6025,
    1932.2618,
    1932.92122,
    1933.58075,
    1934.2404,
    1934.90015,
    1935.56002,
    1936.22,
    1936.8801,
    1937.5403,
    1938.20062,
    1938.86105,
    1939.5216,
    1940.18225,
    1940.84302,
    1941.5039,
    1942.1649,
    1942.826,
    1943.48722,
    1944.14855,
    1944.81,
    1945.47155,
    1946.13322,
    1946.795,
    1947.4569,
    1948.1189,
    1948.78102,
    1949.44325,
    1950.1056,
    1950.76805,
    1951.43062,
    1952.0933,
    1952.7561,
    1953.419,
    1954.08202,
    1954.74515,
    1955.4084,
    1956.07175,
    1956.73522,
    1957.3988,
    1958.0625,
    1958.7263,
    1959.39022,
    1960.05425,
    1960.7184,
    1961.38265,
    1962.04702,
    1962.7115,
    1963.3761,
    1964.0408,
    1964.70562,
    1965.37055,
    1966.0356,
    1966.70075,
    1967.36602,
    1968.0314,
    1968.6969,
    1969.3625,
    1970.02822,
    1970.69405,
    1971.36,
    1972.02605,
    1972.69222,
    1973.3585,
    1974.0249,
    1974.6914,
    1975.35802,
    1976.02475,
    1976.6916,
    1977.35855,
    1978.02562,
    1978.6928,
    1979.3601,
    1980.0275,
    1980.69502,
    1981.36265,
    1982.0304,
    1982.69825,
    1983.36622,
    1984.0343,
    1984.7025,
    1985.3708,
    1986.03922,
    1986.70775,
    1987.3764,
    1988.04515,
    1988.71402,
    1989.383,
    1990.0521,
    1990.7213,
    1991.39062,
    1992.06005,
    1992.7296,
    1993.39925,
    1994.06902,
    1994.7389,
    1995.4089,
    1996.079,
    1996.74922,
    1997.41955,
    1998.09,
    1998.76055,
    1999.43122,
    2000.102,
    2000.7729,
    2001.4439,
    2002.11502,
    2002.78625,
    2003.4576,
    2004.12905,
    2004.80062,
    2005.4723,
    2006.1441,
    2006.816,
    2007.48802,
    2008.16015,
    2008.8324,
    2009.50475,
    2010.17722,
    2010.8498,
    2011.5225,
    2012.1953,
    2012.86822,
    2013.54125,
    2014.2144,
    2014.88765,
    2015.56102,
    2016.2345,
    2016.9081,
    2017.5818,
    2018.25562,
    2018.92955,
    2019.6036,
    2020.27775,
    2020.95202,
    2021.6264,
    2022.3009,
    2022.9755,
    2023.65022,
    2024.32505,
    2025,
    2025.67505,
    2026.35022,
    2027.0255,
    2027.7009,
    2028.3764,
    2029.05202,
    2029.72775,
    2030.4036,
    2031.07955,
    2031.75562,
    2032.4318,
    2033.1081,
    2033.7845,
    2034.46102,
    2035.13765,
    2035.8144,
    2036.49125,
    2037.16822,
    2037.8453,
    2038.5225,
    2039.1998,
    2039.87722,
    2040.55475,
    2041.2324,
    2041.91015,
    2042.58802,
    2043.266,
    2043.9441,
    2044.6223,
    2045.30062,
    2045.97905,
    2046.6576,
    2047.33625,
    2048.01502,
    2048.6939,
    2049.3729,
    2050.052,
    2050.73122,
    2051.41055,
    2052.09,
    2052.76955,
    2053.44922,
    2054.129,
    2054.8089,
    2055.4889,
    2056.16902,
    2056.84925,
    2057.5296,
    2058.21005,
    2058.89062,
    2059.5713,
    2060.2521,
    2060.933,
    2061.61402,
    2062.29515,
    2062.9764,
    2063.65775,
    2064.33922,
    2065.0208,
    2065.7025,
    2066.3843,
    2067.06622,
    2067.74825,
    2068.4304,
    2069.11265,
    2069.79502,
    2070.4775,
    2071.1601,
    2071.8428,
    2072.52562,
    2073.20855,
    2073.8916,
    2074.57475,
    2075.25802,
    2075.9414,
    2076.6249,
    2077.3085,
    2077.99222,
    2078.67605,
    2079.36,
    2080.04405,
    2080.72822,
    2081.4125,
    2082.0969,
    2082.7814,
    2083.46602,
    2084.15075,
    2084.8356,
    2085.52055,
    2086.20562,
    2086.8908,
    2087.5761,
    2088.2615,
    2088.94702,
    2089.63265,
    2090.3184,
    2091.00425,
    2091.69022,
    2092.3763,
    2093.0625,
    2093.7488,
    2094.43522,
    2095.12175,
    2095.8084,
    2096.49515,
    2097.18202,
    2097.869,
    2098.5561,
    2099.2433,
    2099.93062,
    2100.61805,
    2101.3056,
    2101.99325,
    2102.68102,
    2103.3689,
    2104.0569,
    2104.745,
    2105.43322,
    2106.12155,
    2106.81,
    2107.49855,
    2108.18722,
    2108.876,
    2109.5649,
    2110.2539,
    2110.94302,
    2111.63225,
    2112.3216,
    2113.01105,
    2113.70062,
    2114.3903,
    2115.0801,
    2115.77,
    2116.46002,
    2117.15015,
    2117.8404,
    2118.53075,
    2119.22122,
    2119.9118,
    2120.6025,
    2121.2933,
    2121.98422,
    2122.67525,
    2123.3664,
    2124.05765,
    2124.74902,
    2125.4405,
    2126.1321,
    2126.8238,
    2127.51562,
    2128.20755,
    2128.8996,
    2129.59175,
    2130.28402,
    2130.9764,
    2131.6689,
    2132.3615,
    2133.05422,
    2133.74705,
    2134.44,
    2135.13305,
    2135.82622,
    2136.5195,
    2137.2129,
    2137.9064,
    2138.60002,
    2139.29375,
    2139.9876,
    2140.68155,
    2141.37562,
    2142.0698,
    2142.7641,
    2143.4585,
    2144.15302,
    2144.84765,
    2145.5424,
    2146.23725,
    2146.93222,
    2147.6273,
    2148.3225,
    2149.0178,
    2149.71322,
    2150.40875,
    2151.1044,
    2151.80015,
    2152.49602,
    2153.192,
    2153.8881,
    2154.5843,
    2155.28062,
    2155.97705,
    2156.6736,
    2157.37025,
    2158.06702,
    2158.7639,
    2159.4609,
    2160.158,
    2160.85522,
    2161.55255,
    2162.25,
    2162.94755,
    2163.64522,
    2164.343,
    2165.0409,
    2165.7389,
    2166.43702,
    2167.13525,
    2167.8336,
    2168.53205,
    2169.23062,
    2169.9293,
    2170.6281,
    2171.327,
    2172.02602,
    2172.72515,
    2173.4244,
    2174.12375,
    2174.82322,
    2175.5228,
    2176.2225,
    2176.9223,
    2177.62222,
    2178.32225,
    2179.0224,
    2179.72265,
    2180.42302,
    2181.1235,
    2181.8241,
    2182.5248,
    2183.22562,
    2183.92655,
    2184.6276,
    2185.32875,
    2186.03002,
    2186.7314,
    2187.4329,
    2188.1345,
    2188.83622,
    2189.53805,
    2190.24,
    2190.94205,
    2191.64422,
    2192.3465,
    2193.0489,
    2193.7514,
    2194.45402,
    2195.15675,
    2195.8596,
    2196.56255,
    2197.26562,
    2197.9688,
    2198.6721,
    2199.3755,
    2200.07902,
    2200.78265,
    2201.4864,
    2202.19025,
    2202.89422,
    2203.5983,
    2204.3025,
    2205.0068,
    2205.71122,
    2206.41575,
    2207.1204,
    2207.82515,
    2208.53002,
    2209.235,
    2209.9401,
    2210.6453,
    2211.35062,
    2212.05605,
    2212.7616,
    2213.46725,
    2214.17302,
    2214.8789,
    2215.5849,
    2216.291,
    2216.99722,
    2217.70355,
    2218.41,
    2219.11655,
    2219.82322,
    2220.53,
    2221.2369,
    2221.9439,
    2222.65102,
    2223.35825,
    2224.0656,
    2224.77305,
    2225.48062,
    2226.1883,
    2226.8961,
    2227.604,
    2228.31202,
    2229.02015,
    2229.7284,
    2230.43675,
    2231.14522,
    2231.8538,
    2232.5625,
    2233.2713,
    2233.98022,
    2234.68925,
    2235.3984,
    2236.10765,
    2236.81702,
    2237.5265,
    2238.2361,
    2238.9458,
    2239.65562,
    2240.36555,
    2241.0756,
    2241.78575,
    2242.49602,
    2243.2064,
    2243.9169,
    2244.6275,
    2245.33822,
    2246.04905,
    2246.76,
    2247.47105,
    2248.18222,
    2248.8935,
    2249.6049,
    2250.3164,
    2251.02802,
    2251.73975,
    2252.4516,
    2253.16355,
    2253.87562,
    2254.5878,
    2255.3001,
    2256.0125,
    2256.72502,
    2257.43765,
    2258.1504,
    2258.86325,
    2259.57622,
    2260.2893,
    2261.0025,
    2261.7158,
    2262.42922,
    2263.14275,
    2263.8564,
    2264.57015,
    2265.28402,
    2265.998,
    2266.7121,
    2267.4263,
    2268.14062,
    2268.85505,
    2269.5696,
    2270.28425,
    2270.99902,
    2271.7139,
    2272.4289,
    2273.144,
    2273.85922,
    2274.57455,
    2275.29,
    2276.00555,
    2276.72122,
    2277.437,
    2278.1529,
    2278.8689,
    2279.58502,
    2280.30125,
    2281.0176,
    2281.73405,
    2282.45062,
    2283.1673,
    2283.8841,
    2284.601,
    2285.31802,
    2286.03515,
    2286.7524,
    2287.46975,
    2288.18722,
    2288.9048,
    2289.6225,
    2290.3403,
    2291.05822,
    2291.77625,
    2292.4944,
    2293.21265,
    2293.93102,
    2294.6495,
    2295.3681,
    2296.0868,
    2296.80562,
    2297.52455,
    2298.2436,
    2298.96275,
    2299.68202,
    2300.4014,
    2301.1209,
    2301.8405,
    2302.56022,
    2303.28005,
    2304,
    2304.72005,
    2305.44022,
    2306.1605,
    2306.8809,
    2307.6014,
    2308.32202,
    2309.04275,
    2309.7636,
    2310.48455,
    2311.20562,
    2311.9268,
    2312.6481,
    2313.3695,
    2314.09102,
    2314.81265,
    2315.5344,
    2316.25625,
    2316.97822,
    2317.7003,
    2318.4225,
    2319.1448,
    2319.86722,
    2320.58975,
    2321.3124,
    2322.03515,
    2322.75802,
    2323.481,
    2324.2041,
    2324.9273,
    2325.65062,
    2326.37405,
    2327.0976,
    2327.82125,
    2328.54502,
    2329.2689,
    2329.9929,
    2330.717,
    2331.44122,
    2332.16555,
    2332.89,
    2333.61455,
    2334.33922,
    2335.064,
    2335.7889,
    2336.5139,
    2337.23902,
    2337.96425,
    2338.6896,
    2339.41505,
    2340.14062,
    2340.8663,
    2341.5921,
    2342.318,
    2343.04402,
    2343.77015,
    2344.4964,
    2345.22275,
    2345.94922,
    2346.6758,
    2347.4025,
    2348.1293,
    2348.85622,
    2349.58325,
    2350.3104,
    2351.03765,
    2351.76502,
    2352.4925,
    2353.2201,
    2353.9478,
    2354.67562,
    2355.40355,
    2356.1316,
    2356.85975,
    2357.58802,
    2358.3164,
    2359.0449,
    2359.7735,
    2360.50222,
    2361.23105,
    2361.96,
    2362.68905,
    2363.41822,
    2364.1475,
    2364.8769,
    2365.6064,
    2366.33602,
    2367.06575,
    2367.7956,
    2368.52555,
    2369.25562,
    2369.9858,
    2370.7161,
    2371.4465,
    2372.17702,
    2372.90765,
    2373.6384,
    2374.36925,
    2375.10022,
    2375.8313,
    2376.5625,
    2377.2938,
    2378.02522,
    2378.75675,
    2379.4884,
    2380.22015,
    2380.95202,
    2381.684,
    2382.4161,
    2383.1483,
    2383.88062,
    2384.61305,
    2385.3456,
    2386.07825,
    2386.81102,
    2387.5439,
    2388.2769,
    2389.01,
    2389.74322,
    2390.47655,
    2391.21,
    2391.94355,
    2392.67722,
    2393.411,
    2394.1449,
    2394.8789,
    2395.61302,
    2396.34725,
    2397.0816,
    2397.81605,
    2398.55062,
    2399.2853,
    2400.0201,
    2400.755,
    2401.49002,
    2402.22515,
    2402.9604,
    2403.69575,
    2404.43122,
    2405.1668,
    2405.9025,
    2406.6383,
    2407.37422,
    2408.11025,
    2408.8464,
    2409.58265,
    2410.31902,
    2411.0555,
    2411.7921,
    2412.5288,
    2413.26562,
    2414.00255,
    2414.7396,
    2415.47675,
    2416.21402,
    2416.9514,
    2417.6889,
    2418.4265,
    2419.16422,
    2419.90205,
    2420.64,
    2421.37805,
    2422.11622,
    2422.8545,
    2423.5929,
    2424.3314,
    2425.07002,
    2425.80875,
    2426.5476,
    2427.28655,
    2428.02562,
    2428.7648,
    2429.5041,
    2430.2435,
    2430.98302,
    2431.72265,
    2432.4624,
    2433.20225,
    2433.94222,
    2434.6823,
    2435.4225,
    2436.1628,
    2436.90322,
    2437.64375,
    2438.3844,
    2439.12515,
    2439.86602,
    2440.607,
    2441.3481,
    2442.0893,
    2442.83062,
    2443.57205,
    2444.3136,
    2445.05525,
    2445.79702,
    2446.5389,
    2447.2809,
    2448.023,
    2448.76522,
    2449.50755,
    2450.25,
    2450.99255,
    2451.73522,
    2452.478,
    2453.2209,
    2453.9639,
    2454.70702,
    2455.45025,
    2456.1936,
    2456.93705,
    2457.68062,
    2458.4243,
    2459.1681,
    2459.912,
    2460.65602,
    2461.40015,
    2462.1444,
    2462.88875,
    2463.63322,
    2464.3778,
    2465.1225,
    2465.8673,
    2466.61222,
    2467.35725,
    2468.1024,
    2468.84765,
    2469.59302,
    2470.3385,
    2471.0841,
    2471.8298,
    2472.57562,
    2473.32155,
    2474.0676,
    2474.81375,
    2475.56002,
    2476.3064,
    2477.0529,
    2477.7995,
    2478.54622,
    2479.29305,
    2480.04,
    2480.78705,
    2481.53422,
    2482.2815,
    2483.0289,
    2483.7764,
    2484.52402,
    2485.27175,
    2486.0196,
    2486.76755,
    2487.51562,
    2488.2638,
    2489.0121,
    2489.7605,
    2490.50902,
    2491.25765,
    2492.0064,
    2492.75525,
    2493.50422,
    2494.2533,
    2495.0025,
    2495.7518,
    2496.50122,
    2497.25075,
    2498.0004,
    2498.75015,
    2499.50002,
    2500.25,
    2501.0001,
    2501.7503,
    2502.50062,
    2503.25105,
    2504.0016,
    2504.75225,
    2505.50302,
    2506.2539,
    2507.0049,
    2507.756,
    2508.50722,
    2509.25855,
    2510.01,
    2510.76155,
    2511.51322,
    2512.265,
    2513.0169,
    2513.7689,
    2514.52102,
    2515.27325,
    2516.0256,
    2516.77805,
    2517.53062,
    2518.2833,
    2519.0361,
    2519.789,
    2520.54202,
    2521.29515,
    2522.0484,
    2522.80175,
    2523.55522,
    2524.3088,
    2525.0625,
    2525.8163,
    2526.57022,
    2527.32425,
    2528.0784,
    2528.83265,
    2529.58702,
    2530.3415,
    2531.0961,
    2531.8508,
    2532.60562,
    2533.36055,
    2534.1156,
    2534.87075,
    2535.62602,
    2536.3814,
    2537.1369,
    2537.8925,
    2538.64822,
    2539.40405,
    2540.16,
    2540.91605,
    2541.67222,
    2542.4285,
    2543.1849,
    2543.9414,
    2544.69802,
    2545.45475,
    2546.2116,
    2546.96855,
    2547.72562,
    2548.4828,
    2549.2401,
    2549.9975,
    2550.75502,
    2551.51265,
    2552.2704,
    2553.02825,
    2553.78622,
    2554.5443,
    2555.3025,
    2556.0608,
    2556.81922,
    2557.57775,
    2558.3364,
    2559.09515,
    2559.85402,
    2560.613,
    2561.3721,
    2562.1313,
    2562.89062,
    2563.65005,
    2564.4096,
    2565.16925,
    2565.92902,
    2566.6889,
    2567.4489,
    2568.209,
    2568.96922,
    2569.72955,
    2570.49,
    2571.25055,
    2572.01122,
    2572.772,
    2573.5329,
    2574.2939,
    2575.05502,
    2575.81625,
    2576.5776,
    2577.33905,
    2578.10062,
    2578.8623,
    2579.6241,
    2580.386,
    2581.14802,
    2581.91015,
    2582.6724,
    2583.43475,
    2584.19722,
    2584.9598,
    2585.7225,
    2586.4853,
    2587.24822,
    2588.01125,
    2588.7744,
    2589.53765,
    2590.30102,
    2591.0645,
    2591.8281,
    2592.5918,
    2593.35562,
    2594.11955,
    2594.8836,
    2595.64775,
    2596.41202,
    2597.1764,
    2597.9409,
    2598.7055,
    2599.47022,
    2600.23505,
    2601,
    2601.76505,
    2602.53022,
    2603.2955,
    2604.0609,
    2604.8264,
    2605.59202,
    2606.35775,
    2607.1236,
    2607.88955,
    2608.65562,
    2609.4218,
    2610.1881,
    2610.9545,
    2611.72102,
    2612.48765,
    2613.2544,
    2614.02125,
    2614.78822,
    2615.5553,
    2616.3225,
    2617.0898,
    2617.85722,
    2618.62475,
    2619.3924,
    2620.16015,
    2620.92802,
    2621.696,
    2622.4641,
    2623.2323,
    2624.00062,
    2624.76905,
    2625.5376,
    2626.30625,
    2627.07502,
    2627.8439,
    2628.6129,
    2629.382,
    2630.15122,
    2630.92055,
    2631.69,
    2632.45955,
    2633.22922,
    2633.999,
    2634.7689,
    2635.5389,
    2636.30902,
    2637.07925,
    2637.8496,
    2638.62005,
    2639.39062,
    2640.1613,
    2640.9321,
    2641.703,
    2642.47402,
    2643.24515,
    2644.0164,
    2644.78775,
    2645.55922,
    2646.3308,
    2647.1025,
    2647.8743,
    2648.64622,
    2649.41825,
    2650.1904,
    2650.96265,
    2651.73502,
    2652.5075,
    2653.2801,
    2654.0528,
    2654.82562,
    2655.59855,
    2656.3716,
    2657.14475,
    2657.91802,
    2658.6914,
    2659.4649,
    2660.2385,
    2661.01222,
    2661.78605,
    2662.56,
    2663.33405,
    2664.10822,
    2664.8825,
    2665.6569,
    2666.4314,
    2667.20602,
    2667.98075,
    2668.7556,
    2669.53055,
    2670.30562,
    2671.0808,
    2671.8561,
    2672.6315,
    2673.40702,
    2674.18265,
    2674.9584,
    2675.73425,
    2676.51022,
    2677.2863,
    2678.0625,
    2678.8388,
    2679.61522,
    2680.39175,
    2681.1684,
    2681.94515,
    2682.72202,
    2683.499,
    2684.2761,
    2685.0533,
    2685.83062,
    2686.60805,
    2687.3856,
    2688.16325,
    2688.94102,
    2689.7189,
    2690.4969,
    2691.275,
    2692.05322,
    2692.83155,
    2693.61,
    2694.38855,
    2695.16722,
    2695.946,
    2696.7249,
    2697.5039,
    2698.28302,
    2699.06225,
    2699.8416,
    2700.62105,
    2701.40062,
    2702.1803,
    2702.9601,
    2703.74,
    2704.52002,
    2705.30015,
    2706.0804,
    2706.86075,
    2707.64122,
    2708.4218,
    2709.2025,
    2709.9833,
    2710.76422,
    2711.54525,
    2712.3264,
    2713.10765,
    2713.88902,
    2714.6705,
    2715.4521,
    2716.2338,
    2717.01562,
    2717.79755,
    2718.5796,
    2719.36175,
    2720.14402,
    2720.9264,
    2721.7089,
    2722.4915,
    2723.27422,
    2724.05705,
    2724.84,
    2725.62305,
    2726.40622,
    2727.1895,
    2727.9729,
    2728.7564,
    2729.54002,
    2730.32375,
    2731.1076,
    2731.89155,
    2732.67562,
    2733.4598,
    2734.2441,
    2735.0285,
    2735.81302,
    2736.59765,
    2737.3824,
    2738.16725,
    2738.95222,
    2739.7373,
    2740.5225,
    2741.3078,
    2742.09322,
    2742.87875,
    2743.6644,
    2744.45015,
    2745.23602,
    2746.022,
    2746.8081,
    2747.5943,
    2748.38062,
    2749.16705,
    2749.9536,
    2750.74025,
    2751.52702,
    2752.3139,
    2753.1009,
    2753.888,
    2754.67522,
    2755.46255,
    2756.25,
    2757.03755,
    2757.82522,
    2758.613,
    2759.4009,
    2760.1889,
    2760.97702,
    2761.76525,
    2762.5536,
    2763.34205,
    2764.13062,
    2764.9193,
    2765.7081,
    2766.497,
    2767.28602,
    2768.07515,
    2768.8644,
    2769.65375,
    2770.44322,
    2771.2328,
    2772.0225,
    2772.8123,
    2773.60222,
    2774.39225,
    2775.1824,
    2775.97265,
    2776.76302,
    2777.5535,
    2778.3441,
    2779.1348,
    2779.92562,
    2780.71655,
    2781.5076,
    2782.29875,
    2783.09002,
    2783.8814,
    2784.6729,
    2785.4645,
    2786.25622,
    2787.04805,
    2787.84,
    2788.63205,
    2789.42422,
    2790.2165,
    2791.0089,
    2791.8014,
    2792.59402,
    2793.38675,
    2794.1796,
    2794.97255,
    2795.76562,
    2796.5588,
    2797.3521,
    2798.1455,
    2798.93902,
    2799.73265,
    2800.5264,
    2801.32025,
    2802.11422,
    2802.9083,
    2803.7025,
    2804.4968,
    2805.29122,
    2806.08575,
    2806.8804,
    2807.67515,
    2808.47002,
    2809.265,
    2810.0601,
    2810.8553,
    2811.65062,
    2812.44605,
    2813.2416,
    2814.03725,
    2814.83302,
    2815.6289,
    2816.4249,
    2817.221,
    2818.01722,
    2818.81355,
    2819.61,
    2820.40655,
    2821.20322,
    2822,
    2822.7969,
    2823.5939,
    2824.39102,
    2825.18825,
    2825.9856,
    2826.78305,
    2827.58062,
    2828.3783,
    2829.1761,
    2829.974,
    2830.77202,
    2831.57015,
    2832.3684,
    2833.16675,
    2833.96522,
    2834.7638,
    2835.5625,
    2836.3613,
    2837.16022,
    2837.95925,
    2838.7584,
    2839.55765,
    2840.35702,
    2841.1565,
    2841.9561,
    2842.7558,
    2843.55562,
    2844.35555,
    2845.1556,
    2845.95575,
    2846.75602,
    2847.5564,
    2848.3569,
    2849.1575,
    2849.95822,
    2850.75905,
    2851.56,
    2852.36105,
    2853.16222,
    2853.9635,
    2854.7649,
    2855.5664,
    2856.36802,
    2857.16975,
    2857.9716,
    2858.77355,
    2859.57562,
    2860.3778,
    2861.1801,
    2861.9825,
    2862.78502,
    2863.58765,
    2864.3904,
    2865.19325,
    2865.99622,
    2866.7993,
    2867.6025,
    2868.4058,
    2869.20922,
    2870.01275,
    2870.8164,
    2871.62015,
    2872.42402,
    2873.228,
    2874.0321,
    2874.8363,
    2875.64062,
    2876.44505,
    2877.2496,
    2878.05425,
    2878.85902,
    2879.6639,
    2880.4689,
    2881.274,
    2882.07922,
    2882.88455,
    2883.69,
    2884.49555,
    2885.30122,
    2886.107,
    2886.9129,
    2887.7189,
    2888.52502,
    2889.33125,
    2890.1376,
    2890.94405,
    2891.75062,
    2892.5573,
    2893.3641,
    2894.171,
    2894.97802,
    2895.78515,
    2896.5924,
    2897.39975,
    2898.20722,
    2899.0148,
    2899.8225,
    2900.6303,
    2901.43822,
    2902.24625,
    2903.0544,
    2903.86265,
    2904.67102,
    2905.4795,
    2906.2881,
    2907.0968,
    2907.90562,
    2908.71455,
    2909.5236,
    2910.33275,
    2911.14202,
    2911.9514,
    2912.7609,
    2913.5705,
    2914.38022,
    2915.19005,
    2916,
    2916.81005,
    2917.62022,
    2918.4305,
    2919.2409,
    2920.0514,
    2920.86202,
    2921.67275,
    2922.4836,
    2923.29455,
    2924.10562,
    2924.9168,
    2925.7281,
    2926.5395,
    2927.35102,
    2928.16265,
    2928.9744,
    2929.78625,
    2930.59822,
    2931.4103,
    2932.2225,
    2933.0348,
    2933.84722,
    2934.65975,
    2935.4724,
    2936.28515,
    2937.09802,
    2937.911,
    2938.7241,
    2939.5373,
    2940.35062,
    2941.16405,
    2941.9776,
    2942.79125,
    2943.60502,
    2944.4189,
    2945.2329,
    2946.047,
    2946.86122,
    2947.67555,
    2948.49,
    2949.30455,
    2950.11922,
    2950.934,
    2951.7489,
    2952.5639,
    2953.37902,
    2954.19425,
    2955.0096,
    2955.82505,
    2956.64062,
    2957.4563,
    2958.2721,
    2959.088,
    2959.90402,
    2960.72015,
    2961.5364,
    2962.35275,
    2963.16922,
    2963.9858,
    2964.8025,
    2965.6193,
    2966.43622,
    2967.25325,
    2968.0704,
    2968.88765,
    2969.70502,
    2970.5225,
    2971.3401,
    2972.1578,
    2972.97562,
    2973.79355,
    2974.6116,
    2975.42975,
    2976.24802,
    2977.0664,
    2977.8849,
    2978.7035,
    2979.52222,
    2980.34105,
    2981.16,
    2981.97905,
    2982.79822,
    2983.6175,
    2984.4369,
    2985.2564,
    2986.07602,
    2986.89575,
    2987.7156,
    2988.53555,
    2989.35562,
    2990.1758,
    2990.9961,
    2991.8165,
    2992.63702,
    2993.45765,
    2994.2784,
    2995.09925,
    2995.92022,
    2996.7413,
    2997.5625,
    2998.3838,
    2999.20522,
    3000.02675,
    3000.8484,
    3001.67015,
    3002.49202,
    3003.314,
    3004.1361,
    3004.9583,
    3005.78062,
    3006.60305,
    3007.4256,
    3008.24825,
    3009.07102,
    3009.8939,
    3010.7169,
    3011.54,
    3012.36322,
    3013.18655,
    3014.01,
    3014.83355,
    3015.65722,
    3016.481,
    3017.3049,
    3018.1289,
    3018.95302,
    3019.77725,
    3020.6016,
    3021.42605,
    3022.25062,
    3023.0753,
    3023.9001,
    3024.725,
    3025.55002,
    3026.37515,
    3027.2004,
    3028.02575,
    3028.85122,
    3029.6768,
    3030.5025,
    3031.3283,
    3032.15422,
    3032.98025,
    3033.8064,
    3034.63265,
    3035.45902,
    3036.2855,
    3037.1121,
    3037.9388,
    3038.76562,
    3039.59255,
    3040.4196,
    3041.24675,
    3042.07402,
    3042.9014,
    3043.7289,
    3044.5565,
    3045.38422,
    3046.21205,
    3047.04,
    3047.86805,
    3048.69622,
    3049.5245,
    3050.3529,
    3051.1814,
    3052.01002,
    3052.83875,
    3053.6676,
    3054.49655,
    3055.32562,
    3056.1548,
    3056.9841,
    3057.8135,
    3058.64302,
    3059.47265,
    3060.3024,
    3061.13225,
    3061.96222,
    3062.7923,
    3063.6225,
    3064.4528,
    3065.28322,
    3066.11375,
    3066.9444,
    3067.77515,
    3068.60602,
    3069.437,
    3070.2681,
    3071.0993,
    3071.93062,
    3072.76205,
    3073.5936,
    3074.42525,
    3075.25702,
    3076.0889,
    3076.9209,
    3077.753,
    3078.58522,
    3079.41755,
    3080.25,
    3081.08255,
    3081.91522,
    3082.748,
    3083.5809,
    3084.4139,
    3085.24702,
    3086.08025,
    3086.9136,
    3087.74705,
    3088.58062,
    3089.4143,
    3090.2481,
    3091.082,
    3091.91602,
    3092.75015,
    3093.5844,
    3094.41875,
    3095.25322,
    3096.0878,
    3096.9225,
    3097.7573,
    3098.59222,
    3099.42725,
    3100.2624,
    3101.09765,
    3101.93302,
    3102.7685,
    3103.6041,
    3104.4398,
    3105.27562,
    3106.11155,
    3106.9476,
    3107.78375,
    3108.62002,
    3109.4564,
    3110.2929,
    3111.1295,
    3111.96622,
    3112.80305,
    3113.64,
    3114.47705,
    3115.31422,
    3116.1515,
    3116.9889,
    3117.8264,
    3118.66402,
    3119.50175,
    3120.3396,
    3121.17755,
    3122.01562,
    3122.8538,
    3123.6921,
    3124.5305,
    3125.36902,
    3126.20765,
    3127.0464,
    3127.88525,
    3128.72422,
    3129.5633,
    3130.4025,
    3131.2418,
    3132.08122,
    3132.92075,
    3133.7604,
    3134.60015,
    3135.44002,
    3136.28,
    3137.1201,
    3137.9603,
    3138.80062,
    3139.64105,
    3140.4816,
    3141.32225,
    3142.16302,
    3143.0039,
    3143.8449,
    3144.686,
    3145.52722,
    3146.36855,
    3147.21,
    3148.05155,
    3148.89322,
    3149.735,
    3150.5769,
    3151.4189,
    3152.26102,
    3153.10325,
    3153.9456,
    3154.78805,
    3155.63062,
    3156.4733,
    3157.3161,
    3158.159,
    3159.00202,
    3159.84515,
    3160.6884,
    3161.53175,
    3162.37522,
    3163.2188,
    3164.0625,
    3164.9063,
    3165.75022,
    3166.59425,
    3167.4384,
    3168.28265,
    3169.12702,
    3169.9715,
    3170.8161,
    3171.6608,
    3172.50562,
    3173.35055,
    3174.1956,
    3175.04075,
    3175.88602,
    3176.7314,
    3177.5769,
    3178.4225,
    3179.26822,
    3180.11405,
    3180.96,
    3181.80605,
    3182.65222,
    3183.4985,
    3184.3449,
    3185.1914,
    3186.03802,
    3186.88475,
    3187.7316,
    3188.57855,
    3189.42562,
    3190.2728,
    3191.1201,
    3191.9675,
    3192.81502,
    3193.66265,
    3194.5104,
    3195.35825,
    3196.20622,
    3197.0543,
    3197.9025,
    3198.7508,
    3199.59922,
    3200.44775,
    3201.2964,
    3202.14515,
    3202.99402,
    3203.843,
    3204.6921,
    3205.5413,
    3206.39062,
    3207.24005,
    3208.0896,
    3208.93925,
    3209.78902,
    3210.6389,
    3211.4889,
    3212.339,
    3213.18922,
    3214.03955,
    3214.89,
    3215.74055,
    3216.59122,
    3217.442,
    3218.2929,
    3219.1439,
    3219.99502,
    3220.84625,
    3221.6976,
    3222.54905,
    3223.40062,
    3224.2523,
    3225.1041,
    3225.956,
    3226.80802,
    3227.66015,
    3228.5124,
    3229.36475,
    3230.21722,
    3231.0698,
    3231.9225,
    3232.7753,
    3233.62822,
    3234.48125,
    3235.3344,
    3236.18765,
    3237.04102,
    3237.8945,
    3238.7481,
    3239.6018,
    3240.45562,
    3241.30955,
    3242.1636,
    3243.01775,
    3243.87202,
    3244.7264,
    3245.5809,
    3246.4355,
    3247.29022,
    3248.14505,
    3249,
    3249.85505,
    3250.71022,
    3251.5655,
    3252.4209,
    3253.2764,
    3254.13202,
    3254.98775,
    3255.8436,
    3256.69955,
    3257.55562,
    3258.4118,
    3259.2681,
    3260.1245,
    3260.98102,
    3261.83765,
    3262.6944,
    3263.55125,
    3264.40822,
    3265.2653,
    3266.1225,
    3266.9798,
    3267.83722,
    3268.69475,
    3269.5524,
    3270.41015,
    3271.26802,
    3272.126,
    3272.9841,
    3273.8423,
    3274.70062,
    3275.55905,
    3276.4176,
    3277.27625,
    3278.13502,
    3278.9939,
    3279.8529,
    3280.712,
    3281.57122,
    3282.43055,
    3283.29,
    3284.14955,
    3285.00922,
    3285.869,
    3286.7289,
    3287.5889,
    3288.44902,
    3289.30925,
    3290.1696,
    3291.03005,
    3291.89062,
    3292.7513,
    3293.6121,
    3294.473,
    3295.33402,
    3296.19515,
    3297.0564,
    3297.91775,
    3298.77922,
    3299.6408,
    3300.5025,
    3301.3643,
    3302.22622,
    3303.08825,
    3303.9504,
    3304.81265,
    3305.67502,
    3306.5375,
    3307.4001,
    3308.2628,
    3309.12562,
    3309.98855,
    3310.8516,
    3311.71475,
    3312.57802,
    3313.4414,
    3314.3049,
    3315.1685,
    3316.03222,
    3316.89605,
    3317.76,
    3318.62405,
    3319.48822,
    3320.3525,
    3321.2169,
    3322.0814,
    3322.94602,
    3323.81075,
    3324.6756,
    3325.54055,
    3326.40562,
    3327.2708,
    3328.1361,
    3329.0015,
    3329.86702,
    3330.73265,
    3331.5984,
    3332.46425,
    3333.33022,
    3334.1963,
    3335.0625,
    3335.9288,
    3336.79522,
    3337.66175,
    3338.5284,
    3339.39515,
    3340.26202,
    3341.129,
    3341.9961,
    3342.8633,
    3343.73062,
    3344.59805,
    3345.4656,
    3346.33325,
    3347.20102,
    3348.0689,
    3348.9369,
    3349.805,
    3350.67322,
    3351.54155,
    3352.41,
    3353.27855,
    3354.14722,
    3355.016,
    3355.8849,
    3356.7539,
    3357.62302,
    3358.49225,
    3359.3616,
    3360.23105,
    3361.10062,
    3361.9703,
    3362.8401,
    3363.71,
    3364.58002,
    3365.45015,
    3366.3204,
    3367.19075,
    3368.06122,
    3368.9318,
    3369.8025,
    3370.6733,
    3371.54422,
    3372.41525,
    3373.2864,
    3374.15765,
    3375.02902,
    3375.9005,
    3376.7721,
    3377.6438,
    3378.51562,
    3379.38755,
    3380.2596,
    3381.13175,
    3382.00402,
    3382.8764,
    3383.7489,
    3384.6215,
    3385.49422,
    3386.36705,
    3387.24,
    3388.11305,
    3388.98622,
    3389.8595,
    3390.7329,
    3391.6064,
    3392.48002,
    3393.35375,
    3394.2276,
    3395.10155,
    3395.97562,
    3396.8498,
    3397.7241,
    3398.5985,
    3399.47302,
    3400.34765,
    3401.2224,
    3402.09725,
    3402.97222,
    3403.8473,
    3404.7225,
    3405.5978,
    3406.47322,
    3407.34875,
    3408.2244,
    3409.10015,
    3409.97602,
    3410.852,
    3411.7281,
    3412.6043,
    3413.48062,
    3414.35705,
    3415.2336,
    3416.11025,
    3416.98702,
    3417.8639,
    3418.7409,
    3419.618,
    3420.49522,
    3421.37255,
    3422.25,
    3423.12755,
    3424.00522,
    3424.883,
    3425.7609,
    3426.6389,
    3427.51702,
    3428.39525,
    3429.2736,
    3430.15205,
    3431.03062,
    3431.9093,
    3432.7881,
    3433.667,
    3434.54602,
    3435.42515,
    3436.3044,
    3437.18375,
    3438.06322,
    3438.9428,
    3439.8225,
    3440.7023,
    3441.58222,
    3442.46225,
    3443.3424,
    3444.22265,
    3445.10302,
    3445.9835,
    3446.8641,
    3447.7448,
    3448.62562,
    3449.50655,
    3450.3876,
    3451.26875,
    3452.15002,
    3453.0314,
    3453.9129,
    3454.7945,
    3455.67622,
    3456.55805,
    3457.44,
    3458.32205,
    3459.20422,
    3460.0865,
    3460.9689,
    3461.8514,
    3462.73402,
    3463.61675,
    3464.4996,
    3465.38255,
    3466.26562,
    3467.1488,
    3468.0321,
    3468.9155,
    3469.79902,
    3470.68265,
    3471.5664,
    3472.45025,
    3473.33422,
    3474.2183,
    3475.1025,
    3475.9868,
    3476.87122,
    3477.75575,
    3478.6404,
    3479.52515,
    3480.41002,
    3481.295,
    3482.1801,
    3483.0653,
    3483.95062,
    3484.83605,
    3485.7216,
    3486.60725,
    3487.49302,
    3488.3789,
    3489.2649,
    3490.151,
    3491.03722,
    3491.92355,
    3492.81,
    3493.69655,
    3494.58322,
    3495.47,
    3496.3569,
    3497.2439,
    3498.13102,
    3499.01825,
    3499.9056,
    3500.79305,
    3501.68062,
    3502.5683,
    3503.4561,
    3504.344,
    3505.23202,
    3506.12015,
    3507.0084,
    3507.89675,
    3508.78522,
    3509.6738,
    3510.5625,
    3511.4513,
    3512.34022,
    3513.22925,
    3514.1184,
    3515.00765,
    3515.89702,
    3516.7865,
    3517.6761,
    3518.5658,
    3519.45562,
    3520.34555,
    3521.2356,
    3522.12575,
    3523.01602,
    3523.9064,
    3524.7969,
    3525.6875,
    3526.57822,
    3527.46905,
    3528.36,
    3529.25105,
    3530.14222,
    3531.0335,
    3531.9249,
    3532.8164,
    3533.70802,
    3534.59975,
    3535.4916,
    3536.38355,
    3537.27562,
    3538.1678,
    3539.0601,
    3539.9525,
    3540.84502,
    3541.73765,
    3542.6304,
    3543.52325,
    3544.41622,
    3545.3093,
    3546.2025,
    3547.0958,
    3547.98922,
    3548.88275,
    3549.7764,
    3550.67015,
    3551.56402,
    3552.458,
    3553.3521,
    3554.2463,
    3555.14062,
    3556.03505,
    3556.9296,
    3557.82425,
    3558.71902,
    3559.6139,
    3560.5089,
    3561.404,
    3562.29922,
    3563.19455,
    3564.09,
    3564.98555,
    3565.88122,
    3566.777,
    3567.6729,
    3568.5689,
    3569.46502,
    3570.36125,
    3571.2576,
    3572.15405,
    3573.05062,
    3573.9473,
    3574.8441,
    3575.741,
    3576.63802,
    3577.53515,
    3578.4324,
    3579.32975,
    3580.22722,
    3581.1248,
    3582.0225,
    3582.9203,
    3583.81822,
    3584.71625,
    3585.6144,
    3586.51265,
    3587.41102,
    3588.3095,
    3589.2081,
    3590.1068,
    3591.00562,
    3591.90455,
    3592.8036,
    3593.70275,
    3594.60202,
    3595.5014,
    3596.4009,
    3597.3005,
    3598.20022,
    3599.10005,
    3600,
    3600.90005,
    3601.80022,
    3602.7005,
    3603.6009,
    3604.5014,
    3605.40202,
    3606.30275,
    3607.2036,
    3608.10455,
    3609.00562,
    3609.9068,
    3610.8081,
    3611.7095,
    3612.61102,
    3613.51265,
    3614.4144,
    3615.31625,
    3616.21822,
    3617.1203,
    3618.0225,
    3618.9248,
    3619.82722,
    3620.72975,
    3621.6324,
    3622.53515,
    3623.43802,
    3624.341,
    3625.2441,
    3626.1473,
    3627.05062,
    3627.95405,
    3628.8576,
    3629.76125,
    3630.66502,
    3631.5689,
    3632.4729,
    3633.377,
    3634.28122,
    3635.18555,
    3636.09,
    3636.99455,
    3637.89922,
    3638.804,
    3639.7089,
    3640.6139,
    3641.51902,
    3642.42425,
    3643.3296,
    3644.23505,
    3645.14062,
    3646.0463,
    3646.9521,
    3647.858,
    3648.76402,
    3649.67015,
    3650.5764,
    3651.48275,
    3652.38922,
    3653.2958,
    3654.2025,
    3655.1093,
    3656.01622,
    3656.92325,
    3657.8304,
    3658.73765,
    3659.64502,
    3660.5525,
    3661.4601,
    3662.3678,
    3663.27562,
    3664.18355,
    3665.0916,
    3665.99975,
    3666.90802,
    3667.8164,
    3668.7249,
    3669.6335,
    3670.54222,
    3671.45105,
    3672.36,
    3673.26905,
    3674.17822,
    3675.0875,
    3675.9969,
    3676.9064,
    3677.81602,
    3678.72575,
    3679.6356,
    3680.54555,
    3681.45562,
    3682.3658,
    3683.2761,
    3684.1865,
    3685.09702,
    3686.00765,
    3686.9184,
    3687.82925,
    3688.74022,
    3689.6513,
    3690.5625,
    3691.4738,
    3692.38522,
    3693.29675,
    3694.2084,
    3695.12015,
    3696.03202,
    3696.944,
    3697.8561,
    3698.7683,
    3699.68062,
    3700.59305,
    3701.5056,
    3702.41825,
    3703.33102,
    3704.2439,
    3705.1569,
    3706.07,
    3706.98322,
    3707.89655,
    3708.81,
    3709.72355,
    3710.63722,
    3711.551,
    3712.4649,
    3713.3789,
    3714.29302,
    3715.20725,
    3716.1216,
    3717.03605,
    3717.95062,
    3718.8653,
    3719.7801,
    3720.695,
    3721.61002,
    3722.52515,
    3723.4404,
    3724.35575,
    3725.27122,
    3726.1868,
    3727.1025,
    3728.0183,
    3728.93422,
    3729.85025,
    3730.7664,
    3731.68265,
    3732.59902,
    3733.5155,
    3734.4321,
    3735.3488,
    3736.26562,
    3737.18255,
    3738.0996,
    3739.01675,
    3739.93402,
    3740.8514,
    3741.7689,
    3742.6865,
    3743.60422,
    3744.52205,
    3745.44,
    3746.35805,
    3747.27622,
    3748.1945,
    3749.1129,
    3750.0314,
    3750.95002,
    3751.86875,
    3752.7876,
    3753.70655,
    3754.62562,
    3755.5448,
    3756.4641,
    3757.3835,
    3758.30302,
    3759.22265,
    3760.1424,
    3761.06225,
    3761.98222,
    3762.9023,
    3763.8225,
    3764.7428,
    3765.66322,
    3766.58375,
    3767.5044,
    3768.42515,
    3769.34602,
    3770.267,
    3771.1881,
    3772.1093,
    3773.03062,
    3773.95205,
    3774.8736,
    3775.79525,
    3776.71702,
    3777.6389,
    3778.5609,
    3779.483,
    3780.40522,
    3781.32755,
    3782.25,
    3783.17255,
    3784.09522,
    3785.018,
    3785.9409,
    3786.8639,
    3787.78702,
    3788.71025,
    3789.6336,
    3790.55705,
    3791.48062,
    3792.4043,
    3793.3281,
    3794.252,
    3795.17602,
    3796.10015,
    3797.0244,
    3797.94875,
    3798.87322,
    3799.7978,
    3800.7225,
    3801.6473,
    3802.57222,
    3803.49725,
    3804.4224,
    3805.34765,
    3806.27302,
    3807.1985,
    3808.1241,
    3809.0498,
    3809.97562,
    3810.90155,
    3811.8276,
    3812.75375,
    3813.68002,
    3814.6064,
    3815.5329,
    3816.4595,
    3817.38622,
    3818.31305,
    3819.24,
    3820.16705,
    3821.09422,
    3822.0215,
    3822.9489,
    3823.8764,
    3824.80402,
    3825.73175,
    3826.6596,
    3827.58755,
    3828.51562,
    3829.4438,
    3830.3721,
    3831.3005,
    3832.22902,
    3833.15765,
    3834.0864,
    3835.01525,
    3835.94422,
    3836.8733,
    3837.8025,
    3838.7318,
    3839.66122,
    3840.59075,
    3841.5204,
    3842.45015,
    3843.38002,
    3844.31,
    3845.2401,
    3846.1703,
    3847.10062,
    3848.03105,
    3848.9616,
    3849.89225,
    3850.82302,
    3851.7539,
    3852.6849,
    3853.616,
    3854.54722,
    3855.47855,
    3856.41,
    3857.34155,
    3858.27322,
    3859.205,
    3860.1369,
    3861.0689,
    3862.00102,
    3862.93325,
    3863.8656,
    3864.79805,
    3865.73062,
    3866.6633,
    3867.5961,
    3868.529,
    3869.46202,
    3870.39515,
    3871.3284,
    3872.26175,
    3873.19522,
    3874.1288,
    3875.0625,
    3875.9963,
    3876.93022,
    3877.86425,
    3878.7984,
    3879.73265,
    3880.66702,
    3881.6015,
    3882.5361,
    3883.4708,
    3884.40562,
    3885.34055,
    3886.2756,
    3887.21075,
    3888.14602,
    3889.0814,
    3890.0169,
    3890.9525,
    3891.88822,
    3892.82405,
    3893.76,
    3894.69605,
    3895.63222,
    3896.5685,
    3897.5049,
    3898.4414,
    3899.37802,
    3900.31475,
    3901.2516,
    3902.18855,
    3903.12562,
    3904.0628,
    3905.0001,
    3905.9375,
    3906.87502,
    3907.81265,
    3908.7504,
    3909.68825,
    3910.62622,
    3911.5643,
    3912.5025,
    3913.4408,
    3914.37922,
    3915.31775,
    3916.2564,
    3917.19515,
    3918.13402,
    3919.073,
    3920.0121,
    3920.9513,
    3921.89062,
    3922.83005,
    3923.7696,
    3924.70925,
    3925.64902,
    3926.5889,
    3927.5289,
    3928.469,
    3929.40922,
    3930.34955,
    3931.29,
    3932.23055,
    3933.17122,
    3934.112,
    3935.0529,
    3935.9939,
    3936.93502,
    3937.87625,
    3938.8176,
    3939.75905,
    3940.70062,
    3941.6423,
    3942.5841,
    3943.526,
    3944.46802,
    3945.41015,
    3946.3524,
    3947.29475,
    3948.23722,
    3949.1798,
    3950.1225,
    3951.0653,
    3952.00822,
    3952.95125,
    3953.8944,
    3954.83765,
    3955.78102,
    3956.7245,
    3957.6681,
    3958.6118,
    3959.55562,
    3960.49955,
    3961.4436,
    3962.38775,
    3963.33202,
    3964.2764,
    3965.2209,
    3966.1655,
    3967.11022,
    3968.05505,
    3969,
    3969.94505,
    3970.89022,
    3971.8355,
    3972.7809,
    3973.7264,
    3974.67202,
    3975.61775,
    3976.5636,
    3977.50955,
    3978.45562,
    3979.4018,
    3980.3481,
    3981.2945,
    3982.24102,
    3983.18765,
    3984.1344,
    3985.08125,
    3986.02822,
    3986.9753,
    3987.9225,
    3988.8698,
    3989.81722,
    3990.76475,
    3991.7124,
    3992.66015,
    3993.60802,
    3994.556,
    3995.5041,
    3996.4523,
    3997.40062,
    3998.34905,
    3999.2976,
    4000.24625,
    4001.19502,
    4002.1439,
    4003.0929,
    4004.042,
    4004.99122,
    4005.94055,
    4006.89,
    4007.83955,
    4008.78922,
    4009.739,
    4010.6889,
    4011.6389,
    4012.58902,
    4013.53925,
    4014.4896,
    4015.44005,
    4016.39062,
    4017.3413,
    4018.2921,
    4019.243,
    4020.19402,
    4021.14515,
    4022.0964,
    4023.04775,
    4023.99922,
    4024.9508,
    4025.9025,
    4026.8543,
    4027.80622,
    4028.75825,
    4029.7104,
    4030.66265,
    4031.61502,
    4032.5675,
    4033.5201,
    4034.4728,
    4035.42562,
    4036.37855,
    4037.3316,
    4038.28475,
    4039.23802,
    4040.1914,
    4041.1449,
    4042.0985,
    4043.05222,
    4044.00605,
    4044.96,
    4045.91405,
    4046.86822,
    4047.8225,
    4048.7769,
    4049.7314,
    4050.68602,
    4051.64075,
    4052.5956,
    4053.55055,
    4054.50562,
    4055.4608,
    4056.4161,
    4057.3715,
    4058.32702,
    4059.28265,
    4060.2384,
    4061.19425,
    4062.15022,
    4063.1063,
    4064.0625,
    4065.0188,
    4065.97522,
    4066.93175,
    4067.8884,
    4068.84515,
    4069.80202,
    4070.759,
    4071.7161,
    4072.6733,
    4073.63062,
    4074.58805,
    4075.5456,
    4076.50325,
    4077.46102,
    4078.4189,
    4079.3769,
    4080.335,
    4081.29322,
    4082.25155,
    4083.21,
    4084.16855,
    4085.12722,
    4086.086,
    4087.0449,
    4088.0039,
    4088.96302,
    4089.92225,
    4090.8816,
    4091.84105,
    4092.80062,
    4093.7603,
    4094.7201,
    4095.68,
    4096.64002,
    4097.60015,
    4098.5604,
    4099.52075,
    4100.48122,
    4101.4418,
    4102.4025,
    4103.3633,
    4104.32422,
    4105.28525,
    4106.2464,
    4107.20765,
    4108.16902,
    4109.1305,
    4110.0921,
    4111.0538,
    4112.01562,
    4112.97755,
    4113.9396,
    4114.90175,
    4115.86402,
    4116.8264,
    4117.7889,
    4118.7515,
    4119.71422,
    4120.67705,
    4121.64,
    4122.60305,
    4123.56622,
    4124.5295,
    4125.4929,
    4126.4564,
    4127.42002,
    4128.38375,
    4129.3476,
    4130.31155,
    4131.27562,
    4132.2398,
    4133.2041,
    4134.1685,
    4135.13302,
    4136.09765,
    4137.0624,
    4138.02725,
    4138.99222,
    4139.9573,
    4140.9225,
    4141.8878,
    4142.85322,
    4143.81875,
    4144.7844,
    4145.75015,
    4146.71602,
    4147.682,
    4148.6481,
    4149.6143,
    4150.58062,
    4151.54705,
    4152.5136,
    4153.48025,
    4154.44702,
    4155.4139,
    4156.3809,
    4157.348,
    4158.31522,
    4159.28255,
    4160.25,
    4161.21755,
    4162.18522,
    4163.153,
    4164.1209,
    4165.0889,
    4166.05702,
    4167.02525,
    4167.9936,
    4168.96205,
    4169.93062,
    4170.8993,
    4171.8681,
    4172.837,
    4173.80602,
    4174.77515,
    4175.7444,
    4176.71375,
    4177.68322,
    4178.6528,
    4179.6225,
    4180.5923,
    4181.56222,
    4182.53225,
    4183.5024,
    4184.47265,
    4185.44302,
    4186.4135,
    4187.3841,
    4188.3548,
    4189.32562,
    4190.29655,
    4191.2676,
    4192.23875,
    4193.21002,
    4194.1814,
    4195.1529,
    4196.1245,
    4197.09622,
    4198.06805,
    4199.04,
    4200.01205,
    4200.98422,
    4201.9565,
    4202.9289,
    4203.9014,
    4204.87402,
    4205.84675,
    4206.8196,
    4207.79255,
    4208.76562,
    4209.7388,
    4210.7121,
    4211.6855,
    4212.65902,
    4213.63265,
    4214.6064,
    4215.58025,
    4216.55422,
    4217.5283,
    4218.5025,
    4219.4768,
    4220.45122,
    4221.42575,
    4222.4004,
    4223.37515,
    4224.35002,
    4225.325,
    4226.3001,
    4227.2753,
    4228.25062,
    4229.22605,
    4230.2016,
    4231.17725,
    4232.15302,
    4233.1289,
    4234.1049,
    4235.081,
    4236.05722,
    4237.03355,
    4238.01,
    4238.98655,
    4239.96322,
    4240.94,
    4241.9169,
    4242.8939,
    4243.87102,
    4244.84825,
    4245.8256,
    4246.80305,
    4247.78062,
    4248.7583,
    4249.7361,
    4250.714,
    4251.69202,
    4252.67015,
    4253.6484,
    4254.62675,
    4255.60522,
    4256.5838,
    4257.5625,
    4258.5413,
    4259.52022,
    4260.49925,
    4261.4784,
    4262.45765,
    4263.43702,
    4264.4165,
    4265.3961,
    4266.3758,
    4267.35562,
    4268.33555,
    4269.3156,
    4270.29575,
    4271.27602,
    4272.2564,
    4273.2369,
    4274.2175,
    4275.19822,
    4276.17905,
    4277.16,
    4278.14105,
    4279.12222,
    4280.1035,
    4281.0849,
    4282.0664,
    4283.04802,
    4284.02975,
    4285.0116,
    4285.99355,
    4286.97562,
    4287.9578,
    4288.9401,
    4289.9225,
    4290.90502,
    4291.88765,
    4292.8704,
    4293.85325,
    4294.83622,
    4295.8193,
    4296.8025,
    4297.7858,
    4298.76922,
    4299.75275,
    4300.7364,
    4301.72015,
    4302.70402,
    4303.688,
    4304.6721,
    4305.6563,
    4306.64062,
    4307.62505,
    4308.6096,
    4309.59425,
    4310.57902,
    4311.5639,
    4312.5489,
    4313.534,
    4314.51922,
    4315.50455,
    4316.49,
    4317.47555,
    4318.46122,
    4319.447,
    4320.4329,
    4321.4189,
    4322.40502,
    4323.39125,
    4324.3776,
    4325.36405,
    4326.35062,
    4327.3373,
    4328.3241,
    4329.311,
    4330.29802,
    4331.28515,
    4332.2724,
    4333.25975,
    4334.24722,
    4335.2348,
    4336.2225,
    4337.2103,
    4338.19822,
    4339.18625,
    4340.1744,
    4341.16265,
    4342.15102,
    4343.1395,
    4344.1281,
    4345.1168,
    4346.10562,
    4347.09455,
    4348.0836,
    4349.07275,
    4350.06202,
    4351.0514,
    4352.0409,
    4353.0305,
    4354.02022,
    4355.01005,
    4356,
    4356.99005,
    4357.98022,
    4358.9705,
    4359.9609,
    4360.9514,
    4361.94202,
    4362.93275,
    4363.9236,
    4364.91455,
    4365.90562,
    4366.8968,
    4367.8881,
    4368.8795,
    4369.87102,
    4370.86265,
    4371.8544,
    4372.84625,
    4373.83822,
    4374.8303,
    4375.8225,
    4376.8148,
    4377.80722,
    4378.79975,
    4379.7924,
    4380.78515,
    4381.77802,
    4382.771,
    4383.7641,
    4384.7573,
    4385.75062,
    4386.74405,
    4387.7376,
    4388.73125,
    4389.72502,
    4390.7189,
    4391.7129,
    4392.707,
    4393.70122,
    4394.69555,
    4395.69,
    4396.68455,
    4397.67922,
    4398.674,
    4399.6689,
    4400.6639,
    4401.65902,
    4402.65425,
    4403.6496,
    4404.64505,
    4405.64062,
    4406.6363,
    4407.6321,
    4408.628,
    4409.62402,
    4410.62015,
    4411.6164,
    4412.61275,
    4413.60922,
    4414.6058,
    4415.6025,
    4416.5993,
    4417.59622,
    4418.59325,
    4419.5904,
    4420.58765,
    4421.58502,
    4422.5825,
    4423.5801,
    4424.5778,
    4425.57562,
    4426.57355,
    4427.5716,
    4428.56975,
    4429.56802,
    4430.5664,
    4431.5649,
    4432.5635,
    4433.56222,
    4434.56105,
    4435.56,
    4436.55905,
    4437.55822,
    4438.5575,
    4439.5569,
    4440.5564,
    4441.55602,
    4442.55575,
    4443.5556,
    4444.55555,
    4445.55562,
    4446.5558,
    4447.5561,
    4448.5565,
    4449.55702,
    4450.55765,
    4451.5584,
    4452.55925,
    4453.56022,
    4454.5613,
    4455.5625,
    4456.5638,
    4457.56522,
    4458.56675,
    4459.5684,
    4460.57015,
    4461.57202,
    4462.574,
    4463.5761,
    4464.5783,
    4465.58062,
    4466.58305,
    4467.5856,
    4468.58825,
    4469.59102,
    4470.5939,
    4471.5969,
    4472.6,
    4473.60322,
    4474.60655,
    4475.61,
    4476.61355,
    4477.61722,
    4478.621,
    4479.6249,
    4480.6289,
    4481.63302,
    4482.63725,
    4483.6416,
    4484.64605,
    4485.65062,
    4486.6553,
    4487.6601,
    4488.665,
    4489.67002,
    4490.67515,
    4491.6804,
    4492.68575,
    4493.69122,
    4494.6968,
    4495.7025,
    4496.7083,
    4497.71422,
    4498.72025,
    4499.7264,
    4500.73265,
    4501.73902,
    4502.7455,
    4503.7521,
    4504.7588,
    4505.76562,
    4506.77255,
    4507.7796,
    4508.78675,
    4509.79402,
    4510.8014,
    4511.8089,
    4512.8165,
    4513.82422,
    4514.83205,
    4515.84,
    4516.84805,
    4517.85622,
    4518.8645,
    4519.8729,
    4520.8814,
    4521.89002,
    4522.89875,
    4523.9076,
    4524.91655,
    4525.92562,
    4526.9348,
    4527.9441,
    4528.9535,
    4529.96302,
    4530.97265,
    4531.9824,
    4532.99225,
    4534.00222,
    4535.0123,
    4536.0225,
    4537.0328,
    4538.04322,
    4539.05375,
    4540.0644,
    4541.07515,
    4542.08602,
    4543.097,
    4544.1081,
    4545.1193,
    4546.13062,
    4547.14205,
    4548.1536,
    4549.16525,
    4550.17702,
    4551.1889,
    4552.2009,
    4553.213,
    4554.22522,
    4555.23755,
    4556.25,
    4557.26255,
    4558.27522,
    4559.288,
    4560.3009,
    4561.3139,
    4562.32702,
    4563.34025,
    4564.3536,
    4565.36705,
    4566.38062,
    4567.3943,
    4568.4081,
    4569.422,
    4570.43602,
    4571.45015,
    4572.4644,
    4573.47875,
    4574.49322,
    4575.5078,
    4576.5225,
    4577.5373,
    4578.55222,
    4579.56725,
    4580.5824,
    4581.59765,
    4582.61302,
    4583.6285,
    4584.6441,
    4585.6598,
    4586.67562,
    4587.69155,
    4588.7076,
    4589.72375,
    4590.74002,
    4591.7564,
    4592.7729,
    4593.7895,
    4594.80622,
    4595.82305,
    4596.84,
    4597.85705,
    4598.87422,
    4599.8915,
    4600.9089,
    4601.9264,
    4602.94402,
    4603.96175,
    4604.9796,
    4605.99755,
    4607.01562,
    4608.0338,
    4609.0521,
    4610.0705,
    4611.08902,
    4612.10765,
    4613.1264,
    4614.14525,
    4615.16422,
    4616.1833,
    4617.2025,
    4618.2218,
    4619.24122,
    4620.26075,
    4621.2804,
    4622.30015,
    4623.32002,
    4624.34,
    4625.3601,
    4626.3803,
    4627.40062,
    4628.42105,
    4629.4416,
    4630.46225,
    4631.48302,
    4632.5039,
    4633.5249,
    4634.546,
    4635.56722,
    4636.58855,
    4637.61,
    4638.63155,
    4639.65322,
    4640.675,
    4641.6969,
    4642.7189,
    4643.74102,
    4644.76325,
    4645.7856,
    4646.80805,
    4647.83062,
    4648.8533,
    4649.8761,
    4650.899,
    4651.92202,
    4652.94515,
    4653.9684,
    4654.99175,
    4656.01522,
    4657.0388,
    4658.0625,
    4659.0863,
    4660.11022,
    4661.13425,
    4662.1584,
    4663.18265,
    4664.20702,
    4665.2315,
    4666.2561,
    4667.2808,
    4668.30562,
    4669.33055,
    4670.3556,
    4671.38075,
    4672.40602,
    4673.4314,
    4674.4569,
    4675.4825,
    4676.50822,
    4677.53405,
    4678.56,
    4679.58605,
    4680.61222,
    4681.6385,
    4682.6649,
    4683.6914,
    4684.71802,
    4685.74475,
    4686.7716,
    4687.79855,
    4688.82562,
    4689.8528,
    4690.8801,
    4691.9075,
    4692.93502,
    4693.96265,
    4694.9904,
    4696.01825,
    4697.04622,
    4698.0743,
    4699.1025,
    4700.1308,
    4701.15922,
    4702.18775,
    4703.2164,
    4704.24515,
    4705.27402,
    4706.303,
    4707.3321,
    4708.3613,
    4709.39062,
    4710.42005,
    4711.4496,
    4712.47925,
    4713.50902,
    4714.5389,
    4715.5689,
    4716.599,
    4717.62922,
    4718.65955,
    4719.69,
    4720.72055,
    4721.75122,
    4722.782,
    4723.8129,
    4724.8439,
    4725.87502,
    4726.90625,
    4727.9376,
    4728.96905,
    4730.00062,
    4731.0323,
    4732.0641,
    4733.096,
    4734.12802,
    4735.16015,
    4736.1924,
    4737.22475,
    4738.25722,
    4739.2898,
    4740.3225,
    4741.3553,
    4742.38822,
    4743.42125,
    4744.4544,
    4745.48765,
    4746.52102,
    4747.5545,
    4748.5881,
    4749.6218,
    4750.65562,
    4751.68955,
    4752.7236,
    4753.75775,
    4754.79202,
    4755.8264,
    4756.8609,
    4757.8955,
    4758.93022,
    4759.96505,
    4761,
    4762.03505,
    4763.07022,
    4764.1055,
    4765.1409,
    4766.1764,
    4767.21202,
    4768.24775,
    4769.2836,
    4770.31955,
    4771.35562,
    4772.3918,
    4773.4281,
    4774.4645,
    4775.50102,
    4776.53765,
    4777.5744,
    4778.61125,
    4779.64822,
    4780.6853,
    4781.7225,
    4782.7598,
    4783.79722,
    4784.83475,
    4785.8724,
    4786.91015,
    4787.94802,
    4788.986,
    4790.0241,
    4791.0623,
    4792.10062,
    4793.13905,
    4794.1776,
    4795.21625,
    4796.25502,
    4797.2939,
    4798.3329,
    4799.372,
    4800.41122,
    4801.45055,
    4802.49,
    4803.52955,
    4804.56922,
    4805.609,
    4806.6489,
    4807.6889,
    4808.72902,
    4809.76925,
    4810.8096,
    4811.85005,
    4812.89062,
    4813.9313,
    4814.9721,
    4816.013,
    4817.05402,
    4818.09515,
    4819.1364,
    4820.17775,
    4821.21922,
    4822.2608,
    4823.3025,
    4824.3443,
    4825.38622,
    4826.42825,
    4827.4704,
    4828.51265,
    4829.55502,
    4830.5975,
    4831.6401,
    4832.6828,
    4833.72562,
    4834.76855,
    4835.8116,
    4836.85475,
    4837.89802,
    4838.9414,
    4839.9849,
    4841.0285,
    4842.07222,
    4843.11605,
    4844.16,
    4845.20405,
    4846.24822,
    4847.2925,
    4848.3369,
    4849.3814,
    4850.42602,
    4851.47075,
    4852.5156,
    4853.56055,
    4854.60562,
    4855.6508,
    4856.6961,
    4857.7415,
    4858.78702,
    4859.83265,
    4860.8784,
    4861.92425,
    4862.97022,
    4864.0163,
    4865.0625,
    4866.1088,
    4867.15522,
    4868.20175,
    4869.2484,
    4870.29515,
    4871.34202,
    4872.389,
    4873.4361,
    4874.4833,
    4875.53062,
    4876.57805,
    4877.6256,
    4878.67325,
    4879.72102,
    4880.7689,
    4881.8169,
    4882.865,
    4883.91322,
    4884.96155,
    4886.01,
    4887.05855,
    4888.10722,
    4889.156,
    4890.2049,
    4891.2539,
    4892.30302,
    4893.35225,
    4894.4016,
    4895.45105,
    4896.50062,
    4897.5503,
    4898.6001,
    4899.65,
    4900.70002,
    4901.75015,
    4902.8004,
    4903.85075,
    4904.90122,
    4905.9518,
    4907.0025,
    4908.0533,
    4909.10422,
    4910.15525,
    4911.2064,
    4912.25765,
    4913.30902,
    4914.3605,
    4915.4121,
    4916.4638,
    4917.51562,
    4918.56755,
    4919.6196,
    4920.67175,
    4921.72402,
    4922.7764,
    4923.8289,
    4924.8815,
    4925.93422,
    4926.98705,
    4928.04,
    4929.09305,
    4930.14622,
    4931.1995,
    4932.2529,
    4933.3064,
    4934.36002,
    4935.41375,
    4936.4676,
    4937.52155,
    4938.57562,
    4939.6298,
    4940.6841,
    4941.7385,
    4942.79302,
    4943.84765,
    4944.9024,
    4945.95725,
    4947.01222,
    4948.0673,
    4949.1225,
    4950.1778,
    4951.23322,
    4952.28875,
    4953.3444,
    4954.40015,
    4955.45602,
    4956.512,
    4957.5681,
    4958.6243,
    4959.68062,
    4960.73705,
    4961.7936,
    4962.85025,
    4963.90702,
    4964.9639,
    4966.0209,
    4967.078,
    4968.13522,
    4969.19255,
    4970.25,
    4971.30755,
    4972.36522,
    4973.423,
    4974.4809,
    4975.5389,
    4976.59702,
    4977.65525,
    4978.7136,
    4979.77205,
    4980.83062,
    4981.8893,
    4982.9481,
    4984.007,
    4985.06602,
    4986.12515,
    4987.1844,
    4988.24375,
    4989.30322,
    4990.3628,
    4991.4225,
    4992.4823,
    4993.54222,
    4994.60225,
    4995.6624,
    4996.72265,
    4997.78302,
    4998.8435,
    4999.9041,
    5000.9648,
    5002.02562,
    5003.08655,
    5004.1476,
    5005.20875,
    5006.27002,
    5007.3314,
    5008.3929,
    5009.4545,
    5010.51622,
    5011.57805,
    5012.64,
    5013.70205,
    5014.76422,
    5015.8265,
    5016.8889,
    5017.9514,
    5019.01402,
    5020.07675,
    5021.1396,
    5022.20255,
    5023.26562,
    5024.3288,
    5025.3921,
    5026.4555,
    5027.51902,
    5028.58265,
    5029.6464,
    5030.71025,
    5031.77422,
    5032.8383,
    5033.9025,
    5034.9668,
    5036.03122,
    5037.09575,
    5038.1604,
    5039.22515,
    5040.29002,
    5041.355,
    5042.4201,
    5043.4853,
    5044.55062,
    5045.61605,
    5046.6816,
    5047.74725,
    5048.81302,
    5049.8789,
    5050.9449,
    5052.011,
    5053.07722,
    5054.14355,
    5055.21,
    5056.27655,
    5057.34322,
    5058.41,
    5059.4769,
    5060.5439,
    5061.61102,
    5062.67825,
    5063.7456,
    5064.81305,
    5065.88062,
    5066.9483,
    5068.0161,
    5069.084,
    5070.15202,
    5071.22015,
    5072.2884,
    5073.35675,
    5074.42522,
    5075.4938,
    5076.5625,
    5077.6313,
    5078.70022,
    5079.76925,
    5080.8384,
    5081.90765,
    5082.97702,
    5084.0465,
    5085.1161,
    5086.1858,
    5087.25562,
    5088.32555,
    5089.3956,
    5090.46575,
    5091.53602,
    5092.6064,
    5093.6769,
    5094.7475,
    5095.81822,
    5096.88905,
    5097.96,
    5099.03105,
    5100.10222,
    5101.1735,
    5102.2449,
    5103.3164,
    5104.38802,
    5105.45975,
    5106.5316,
    5107.60355,
    5108.67562,
    5109.7478,
    5110.8201,
    5111.8925,
    5112.96502,
    5114.03765,
    5115.1104,
    5116.18325,
    5117.25622,
    5118.3293,
    5119.4025,
    5120.4758,
    5121.54922,
    5122.62275,
    5123.6964,
    5124.77015,
    5125.84402,
    5126.918,
    5127.9921,
    5129.0663,
    5130.14062,
    5131.21505,
    5132.2896,
    5133.36425,
    5134.43902,
    5135.5139,
    5136.5889,
    5137.664,
    5138.73922,
    5139.81455,
    5140.89,
    5141.96555,
    5143.04122,
    5144.117,
    5145.1929,
    5146.2689,
    5147.34502,
    5148.42125,
    5149.4976,
    5150.57405,
    5151.65062,
    5152.7273,
    5153.8041,
    5154.881,
    5155.95802,
    5157.03515,
    5158.1124,
    5159.18975,
    5160.26722,
    5161.3448,
    5162.4225,
    5163.5003,
    5164.57822,
    5165.65625,
    5166.7344,
    5167.81265,
    5168.89102,
    5169.9695,
    5171.0481,
    5172.1268,
    5173.20562,
    5174.28455,
    5175.3636,
    5176.44275,
    5177.52202,
    5178.6014,
    5179.6809,
    5180.7605,
    5181.84022,
    5182.92005,
    5184,
    5185.08005,
    5186.16022,
    5187.2405,
    5188.3209,
    5189.4014,
    5190.48202,
    5191.56275,
    5192.6436,
    5193.72455,
    5194.80562,
    5195.8868,
    5196.9681,
    5198.0495,
    5199.13102,
    5200.21265,
    5201.2944,
    5202.37625,
    5203.45822,
    5204.5403,
    5205.6225,
    5206.7048,
    5207.78722,
    5208.86975,
    5209.9524,
    5211.03515,
    5212.11802,
    5213.201,
    5214.2841,
    5215.3673,
    5216.45062,
    5217.53405,
    5218.6176,
    5219.70125,
    5220.78502,
    5221.8689,
    5222.9529,
    5224.037,
    5225.12122,
    5226.20555,
    5227.29,
    5228.37455,
    5229.45922,
    5230.544,
    5231.6289,
    5232.7139,
    5233.79902,
    5234.88425,
    5235.9696,
    5237.05505,
    5238.14062,
    5239.2263,
    5240.3121,
    5241.398,
    5242.48402,
    5243.57015,
    5244.6564,
    5245.74275,
    5246.82922,
    5247.9158,
    5249.0025,
    5250.0893,
    5251.17622,
    5252.26325,
    5253.3504,
    5254.43765,
    5255.52502,
    5256.6125,
    5257.7001,
    5258.7878,
    5259.87562,
    5260.96355,
    5262.0516,
    5263.13975,
    5264.22802,
    5265.3164,
    5266.4049,
    5267.4935,
    5268.58222,
    5269.67105,
    5270.76,
    5271.84905,
    5272.93822,
    5274.0275,
    5275.1169,
    5276.2064,
    5277.29602,
    5278.38575,
    5279.4756,
    5280.56555,
    5281.65562,
    5282.7458,
    5283.8361,
    5284.9265,
    5286.01702,
    5287.10765,
    5288.1984,
    5289.28925,
    5290.38022,
    5291.4713,
    5292.5625,
    5293.6538,
    5294.74522,
    5295.83675,
    5296.9284,
    5298.02015,
    5299.11202,
    5300.204,
    5301.2961,
    5302.3883,
    5303.48062,
    5304.57305,
    5305.6656,
    5306.75825,
    5307.85102,
    5308.9439,
    5310.0369,
    5311.13,
    5312.22322,
    5313.31655,
    5314.41,
    5315.50355,
    5316.59722,
    5317.691,
    5318.7849,
    5319.8789,
    5320.97302,
    5322.06725,
    5323.1616,
    5324.25605,
    5325.35062,
    5326.4453,
    5327.5401,
    5328.635,
    5329.73002,
    5330.82515,
    5331.9204,
    5333.01575,
    5334.11122,
    5335.2068,
    5336.3025,
    5337.3983,
    5338.49422,
    5339.59025,
    5340.6864,
    5341.78265,
    5342.87902,
    5343.9755,
    5345.0721,
    5346.1688,
    5347.26562,
    5348.36255,
    5349.4596,
    5350.55675,
    5351.65402,
    5352.7514,
    5353.8489,
    5354.9465,
    5356.04422,
    5357.14205,
    5358.24,
    5359.33805,
    5360.43622,
    5361.5345,
    5362.6329,
    5363.7314,
    5364.83002,
    5365.92875,
    5367.0276,
    5368.12655,
    5369.22562,
    5370.3248,
    5371.4241,
    5372.5235,
    5373.62302,
    5374.72265,
    5375.8224,
    5376.92225,
    5378.02222,
    5379.1223,
    5380.2225,
    5381.3228,
    5382.42322,
    5383.52375,
    5384.6244,
    5385.72515,
    5386.82602,
    5387.927,
    5389.0281,
    5390.1293,
    5391.23062,
    5392.33205,
    5393.4336,
    5394.53525,
    5395.63702,
    5396.7389,
    5397.8409,
    5398.943,
    5400.04522,
    5401.14755,
    5402.25,
    5403.35255,
    5404.45522,
    5405.558,
    5406.6609,
    5407.7639,
    5408.86702,
    5409.97025,
    5411.0736,
    5412.17705,
    5413.28062,
    5414.3843,
    5415.4881,
    5416.592,
    5417.69602,
    5418.80015,
    5419.9044,
    5421.00875,
    5422.11322,
    5423.2178,
    5424.3225,
    5425.4273,
    5426.53222,
    5427.63725,
    5428.7424,
    5429.84765,
    5430.95302,
    5432.0585,
    5433.1641,
    5434.2698,
    5435.37562,
    5436.48155,
    5437.5876,
    5438.69375,
    5439.80002,
    5440.9064,
    5442.0129,
    5443.1195,
    5444.22622,
    5445.33305,
    5446.44,
    5447.54705,
    5448.65422,
    5449.7615,
    5450.8689,
    5451.9764,
    5453.08402,
    5454.19175,
    5455.2996,
    5456.40755,
    5457.51562,
    5458.6238,
    5459.7321,
    5460.8405,
    5461.94902,
    5463.05765,
    5464.1664,
    5465.27525,
    5466.38422,
    5467.4933,
    5468.6025,
    5469.7118,
    5470.82122,
    5471.93075,
    5473.0404,
    5474.15015,
    5475.26002,
    5476.37,
    5477.4801,
    5478.5903,
    5479.70062,
    5480.81105,
    5481.9216,
    5483.03225,
    5484.14302,
    5485.2539,
    5486.3649,
    5487.476,
    5488.58722,
    5489.69855,
    5490.81,
    5491.92155,
    5493.03322,
    5494.145,
    5495.2569,
    5496.3689,
    5497.48102,
    5498.59325,
    5499.7056,
    5500.81805,
    5501.93062,
    5503.0433,
    5504.1561,
    5505.269,
    5506.38202,
    5507.49515,
    5508.6084,
    5509.72175,
    5510.83522,
    5511.9488,
    5513.0625,
    5514.1763,
    5515.29022,
    5516.40425,
    5517.5184,
    5518.63265,
    5519.74702,
    5520.8615,
    5521.9761,
    5523.0908,
    5524.20562,
    5525.32055,
    5526.4356,
    5527.55075,
    5528.66602,
    5529.7814,
    5530.8969,
    5532.0125,
    5533.12822,
    5534.24405,
    5535.36,
    5536.47605,
    5537.59222,
    5538.7085,
    5539.8249,
    5540.9414,
    5542.05802,
    5543.17475,
    5544.2916,
    5545.40855,
    5546.52562,
    5547.6428,
    5548.7601,
    5549.8775,
    5550.99502,
    5552.11265,
    5553.2304,
    5554.34825,
    5555.46622,
    5556.5843,
    5557.7025,
    5558.8208,
    5559.93922,
    5561.05775,
    5562.1764,
    5563.29515,
    5564.41402,
    5565.533,
    5566.6521,
    5567.7713,
    5568.89062,
    5570.01005,
    5571.1296,
    5572.24925,
    5573.36902,
    5574.4889,
    5575.6089,
    5576.729,
    5577.84922,
    5578.96955,
    5580.09,
    5581.21055,
    5582.33122,
    5583.452,
    5584.5729,
    5585.6939,
    5586.81502,
    5587.93625,
    5589.0576,
    5590.17905,
    5591.30062,
    5592.4223,
    5593.5441,
    5594.666,
    5595.78802,
    5596.91015,
    5598.0324,
    5599.15475,
    5600.27722,
    5601.3998,
    5602.5225,
    5603.6453,
    5604.76822,
    5605.89125,
    5607.0144,
    5608.13765,
    5609.26102,
    5610.3845,
    5611.5081,
    5612.6318,
    5613.75562,
    5614.87955,
    5616.0036,
    5617.12775,
    5618.25202,
    5619.3764,
    5620.5009,
    5621.6255,
    5622.75022,
    5623.87505
];
export const getBuyPrice = (supply: number) => data[supply];
export const getSellPrice = (supply: number) => data1[supply];
